@if $page-header-image == false {
  .page-header {
    @include grid-row();

    .title-wrapper {
      @include grid-column(12);

      @media #{$small} {
        padding-top: 30px;
      }

      body.sidebar-first & {
        @media #{$small-medium} {
          width: 65%;
          float: right;
        }
        @media #{$medium} {
          @include grid-column(9);
          float: right;
        }
      }
    }

    .favorites-cart & {
      max-width: none;
      @media #{$small} {
        background: $favorites-form-bg-desktop;
      }
    }
  }
}

@if $page-header-image == true {
  .page-wrapper {
    width: 100%;

    @media #{$small} {
      @include clearfix();
      //padding-bottom:30px;

      .front & {
        padding: 0;
      }
    }
  }

  .page-header {
    padding-left: 15px;
    padding-right: 15px;

    @media #{$small-down} {
      padding-top: 20px;
    }

    .front & {
      display: none;
    }
    .show-search & {
      //display:none;
    }
    @media #{$small} {
      body:not(.has-banner) &:after {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 433px;
        background: linear-gradient(180deg, rgba(16, 48, 64, 0.5625) 0%, #10304000 100%) 0% 0% no-repeat padding-box;
        content: '';
        display: block;
        z-index: 1;
      }

      body.checkout-page &:after {
        height: 180px;
      }

      @include grid-column(12);
      @include min(both);
      //border-bottom:solid 1px $muted-color;
      background: url("../images/page-header-bg-small-medium.jpg") left top no-repeat;
      background-attachment: fixed;

      .expanded & {
        background-attachment: initial;
      }

      #page-title {
        text-align: center;
        margin-top: 80px;
        margin-bottom: 80px;
        color: $white;
        text-shadow: 0 0 5px $black;
      }
      .title-wrapper {
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        position: relative;
        z-index: 3;

        &:after {
          content: '';
          display: block;
          @include absolute-center($top: auto);
          height: 10px;
          background: linear-gradient($transparent, rgba($tertiary-color, .1));
        }
      }
    }
    @media #{$medium} {
      background-image: url("../images/page-header-bg-medium-large.jpg");
      padding-top: $header-height-desktop;

      #page-title {
        @include grid-column(12);
        text-align: center;
        margin-top: 80px;
        margin-bottom: 80px;
        color: $white;
        text-shadow: 0 0 5px $black;
      }
    }
    @media #{$large} {
      background-image: url("../images/page-header-bg-large-xlarge.jpg");

    }
    @media only screen and (min-width: emCalc(1600)) {
      background-image: url("../images/page-header-bg-xlarge-xwide.jpg");
    }
    @media only screen and (min-width: emCalc(2000)) {
      background-image: url("../images/page-header-bg-full.jpg");
      //background-size:100% auto;
      background-position: left top;
    }

    .has-banner & {
      display: inline;
      background: none;
      padding: 0;
      border: 0;
      height: auto;
      min-height: 0;
      border: 0;

      .title-wrapper {
        display: inline;
        margin: 0;
        padding: 0;
        border: 0;

        &:after {
          display: none;
        }
      }

      #page-title {
        margin: 10px 0 0 0;
        padding-left: 15px;
        padding-right: 15px;
        color: $black;
        text-shadow: none;
      }
    }
  }
  // BLog TTD Events Map in header only
  .blog-ttd-events-details-page {
    .has-loc + #page-content {
      .page-header {
        display: inline;
        background: none;
        padding: 0;
        border: 0;
        height: auto;
        min-height: 0;
        border: 0;

        .title-wrapper {
          display: inline;
          margin: 0;
          padding: 0;
          border: 0;

          &:after {
            display: none;
          }
        }

        #page-title {
          margin: 10px 0 0 0;
          padding-left: 15px;
          padding-right: 15px;

          @media #{$small-down} {
            margin-bottom: 10px !important;
          }
        }
      }
    }
    .has-loc {
      @media #{$medium} {
        padding-top: $header-height-desktop;
      }
    }
  }
  .checkout-page {
    .page-header {
      @include normalize-page-header();
      @media #{$medium} {
        padding-top: 120px;
      }
    }
  }
}
