/**
 * Default Subnav Snippet theme variables
 * These will be used on Subnav Snippets on all interior pages
 */
.field-name-field-page-snippet-title {
  background: $muted-color;
  font-family: $head-gf;
  font-size: $h3-font-size;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .1em;
  line-height: $header-line-height;
  text-align: center;
  padding: 40px 10px 30px;
  @media #{$medium-down} {
    padding: 30px 10px 20px;
  }
}

.field-name-field-page-snippet-content {
  float: left;
  width: 100%;
  ul {
    @include grid-row();
    list-style: none;
    @media only screen and (min-width: emCalc(640)) {
      display: flex;
      justify-content: left;
      flex-wrap: wrap;
    }

    li {
      @include grid-column(12);
      background: $secondary-color;
      margin: 7px;
      padding-left: 0;
      padding-right: 0;
      @media only screen and (min-width: emCalc(640)) {
        // flex-basis: calc(25% - 14px);
        flex-basis: 32%;
      }
      @media #{$small-down} {
        margin-bottom: 15px;
      }
      .field-name-field-snippet-image {
        background: #000;
        img {
          display: block;
          width: 100%;
          height: auto;
          opacity: .9;
        }
      }

      .field-name-title {
        position: relative;
        z-index: 9;
        display: block;
        text-align: center;
        margin: 10px;
        left: 0;
        right: 0;
        bottom: auto;
        @media #{$medium} {
          bottom: auto;
          margin: 10px 20px;
        }
        h3 {
          color: $white;
          font-size: 24px;
          margin: 0;
          @media #{$small-medium} {
            font-size: 17px;
          }
        }
      }
      .field-name-field-snippet-text {
        color: $white;
        text-align: center;
        padding: 0 10px 20px;
        line-height: 1.2;
        a, a:link, a:visited {
          color: $white;
        }
      }
    }
  }
}

.front .field-name-field-page-snippet-content {
  float: left;
  width: 100%;
  ul {
    @include grid-row();
    list-style: none;
    @media only screen and (min-width: emCalc(640)) {
      display: flex;
      justify-content: left;
      flex-wrap: wrap;
    }

    li {
      @include grid-column(12);
      background: $secondary-color;
      margin: 7px;
      padding-left: 0;
      padding-right: 0;
      @media only screen and (min-width: emCalc(640)) {
        flex-basis: calc(25% - 14px);
      }
      @media #{$small-down} {
        margin-bottom: 15px;
      }
      .field-name-field-snippet-image {
        background: #000;
        img {
          display: block;
          width: 100%;
          height: auto;
          opacity: .9;
        }
      }

      .field-name-title {
        position: relative;
        z-index: 9;
        display: block;
        text-align: center;
        margin: 10px;
        left: 0;
        right: 0;
        bottom: auto;
        @media #{$medium} {
          bottom: auto;
          margin: 10px 20px;
        }
        h3 {
          color: $white;
          font-size: 24px;
          margin: 0;
          @media #{$small-medium} {
            font-size: 17px;
          }
        }
      }
      .field-name-field-snippet-text {
        color: $white;
        text-align: center;
        padding: 0 10px 20px;
        line-height: 1.2;
        a, a:link, a:visited {
          color: $white;
        }
      }
    }
  }
}