riot-solr-current-search,
[riot-tag="riot-solr-current-search"],
[data-is="riot-solr-current-search"] {

  //Global
  .reveal-modal &,
  body .main.columns & {
    ul {
      li {
        a {
          background: $current-search-item-bg;
          color: $current-search-item-color;
          border-color: $current-search-item-border-color;
          font-size: 14px;

          i * {
            fill: $current-search-icon-color;
          }

          @media #{$small} {
            background: $current-search-item-bg-desktop;
            color: $current-search-item-color-desktop;
            border-color: $current-search-item-border-color-desktop;
            font-size: 14px;

            i * {
              fill: $current-search-icon-color-desktop;
            }
          }
        }

        &:last-child {
          color: $current-search-count-color;

          @media #{$small} {
            color: $current-search-count-color-desktop;
          }
        }
      }
      li + li:last-child {
        padding-top: 8px;
      }
    }
  }

  //Search List
  #vrweb-search & {
    clear: both;
    padding: 15px;

    ul {
      margin-bottom: 0;

      @media only screen and (min-width: emCalc(375)) and (max-width: emCalc(524)) {
        padding-right: 110px;
      }

      li + li:last-child {

        @media only screen and (min-width: emCalc(375)) and (max-width: emCalc(524)) {
          position: absolute;
          right: 15px;
          top: 10px;
          width: auto;
        }
      }
    }

    @media only screen and (min-width: emCalc(525)) and (max-width: #{$small-screen-down}) {
      width: 50%;
      float: left;
      padding-right: 8px;
      clear: none;
    }

    @media #{$small} {
      padding-left: 0px;
      padding-top: 15px;
      padding-bottom: 0;

      ul {
        margin-top: 0;

        li:last-child {
          padding-top: 0;
        }

        li + li:last-child {
          padding-top: 5px;
        }
      }
    }

    @media only screen and (min-width: emCalc(825)) {
      border-bottom: solid 1px $muted-color;
      padding-bottom: 5px;
      padding-right: 0;

      ul {
        width: 100%;
        padding-right: 110px;
        min-height: 27px;

        li:last-child {
          position: absolute;
          top: auto;
          bottom: 10px;
          margin-bottom: 0;
          right: 0;
          left: auto;
          width: auto;
        }
      }
    }
  }

  //Main Search
  body .main.columns riot-solr-search &,
  body .main.columns [riot-tag="riot-solr-search"] &,
  body .main.columns [data-is="riot-solr-search"] & {
    background: $search-list-widget-bg;
    @media #{$small} {
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 15px;

      &:not(.has-filters) {
        ul {
          margin-top: 0;
        }
      }
      ul {
        width: 100%;
        margin-bottom: 0;

        li {
          margin-right: 0;
          width: 100%;
        }
      }

      li:last-of-type {
        padding-top: 10px;

        @media #{$medium} {
          text-align: center;
          font-size: 16px;
          padding-top: 12px;
        }
      }
      li + li:last-of-type {
        padding-top: 0px;
      }
    }

    @media #{$small-medium} {
      li {
        width: 100%;
        font-size: 12px;

        a {
          font-size: 11px;
          padding: 3px 5px;
          line-height: 1.2;

          i {
            display: none;
          }
        }
      }
    }

    @media #{$medium-large} {
      padding-top: 18px;

      li {
        &, a {
          font-size: 13px;
        }

        a {
          padding-right: 18px;

          i {
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }

  //Modal
  .reveal-modal & {
    background: $current-search-modal-bg;

    ul {
      margin-bottom: 0;
    }
  }
}
.search-filters-container  {
  riot-solr-facet-checkbox,
  [riot-tag="riot-solr-facet-checkbox"],
  [data-is="riot-solr-facet-checkbox"] {
    ul {
      list-style: none;
      margin: 0;
      column-count: 3;
      column-gap: 10px;
      @media #{$small-down} {
        column-count: 1;      
      }
      @media #{$small-medium} {
        column-count: 2;      
      }
      li {
        label {
          text-transform: capitalize;
        }
      }
    }
    input[type="checkbox"] {
      float: left;
      margin-right: 5px;
      position: relative;
      top: 3px;
    }
  }
}