body,
html {
  position: relative;
  width: 100%;
  height: auto !important;
  min-height: 100%;
  padding: 0;
  margin: 0;

  * {
    line-height: $document-line-height;
    box-sizing: border-box;
  }

  p {
    line-height: $paragraph-line-height;
  }
}

.off-canvas-trigger {
  display: none;
}
ul {
  list-style: disc;
}

ol, ul {
  margin-left: 0;
  list-style-position: inside;
}

ul.tabs {
  list-style-type: none;
}

blockquote {
  blockquote {
    padding: 0;
    border: none;
  }
}

#document, #page-content {
  min-height: 100%;
  height: auto;
}
.l-main {
  width: 100%;
  position: relative;

  @media #{$small} {
    padding-bottom: 30px;
    clear: both;
  }
}

.clearfix {
  clear: both;
}

.main.columns {
  @media #{$small} {
    padding-top: 0px;

    @if $page-header-image == true {
      padding-top: 20px;

      .unit-details-page &,
      .favorites-cart & {
        padding-top: 0;
      }
    }
  }
}

/**
* 	Adjust grid as browser collapses so the
* 	sidebar doesnt get too narrow
**/
body.sidebar-first,
body.sidebar-second {
  @media #{$small} {
    .sidebar-first {
      @include min(right);
    }
    .sidebar-second {
      @include min(left);
    }
  }
  @media #{$small-medium} {
    .sidebar-first,
    .sidebar-second {
      width: 35%;
    }
    .main.columns {
      width: 65%;
    }
  }
}

body.sidebar-first {
  @media #{$small} {
    .main.columns {
      padding-left: 25px;
    }
  }
}

body.sidebar-second {
  @media #{$small} {
    .main.columns {
      padding-right: 25px;
    }
  }
}

/**
 * Maintenance page.
 */
body.maintenance-page {
  #page-wrapper {
    text-align: center;
  }
  .l-header {
    padding-bottom: em-calc(15px);
  }
  #main-wrapper {
    padding: em-calc(30px 0);
  }
}

//Basic Page Styles
.node-page.view-mode-full {
  .group-header,
  .group-footer,
  .group-content {
    @include min(both);

    .front & {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
