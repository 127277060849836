riot-solr-search,
[riot-tag="riot-solr-search"],
[data-is="riot-solr-search"] {
  body & {

    .search-header {

      riot-tags-class-toggle {
        ul.riot-tags-class-toggle {
          li {
            padding-top: 0;
          }
        }
      }

      background: $search-list-widget-bg;

      @media #{$small} {
        background: $search-list-widget-bg-desktop;
      }
      > .search-filters-container {

        a {
          background: $trigger-button-color;
          text-transform: uppercase;
          font-weight: 700;
          letter-spacing: .075em;
          padding-top: 12px;
          text-decoration: none;
          width: 100%;

          &:hover, &:active {
            background: $trigger-button-color-hover;
          }

          &:before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 8px;
            background: url("../images/icon-filters-white.svg") center no-repeat;
            margin-bottom: -2px;
          }

          @media #{$small} {
            padding-left: 5px;
            padding-right: 5px;
            font-size: 12px;
            line-height: 1.2;
            letter-spacing: .075em;
            padding-top: 0;

            &:before {
              display: block;
              margin: 8px auto 7px;
            }
          }

          @media #{$medium} {
            padding-top: 5px;
            @include min(both);
            letter-spacing: .05em;
            font-weight: 600;

            &:before {
              margin-top: 10px;
              margin-bottom: 10px;
              width: 24px;
              height: 24px;
            }
          }
          @media only screen and (min-width: emCalc(1150)) {
            font-size: 14px;
            padding-top: 19px;

            &:before {
              display: inline-block;
              width: 21px;
              height: 21px;
              margin-right: 8px;
              margin-bottom: -3px;
            }
          }

          @media only screen and (min-width: emCalc(1350)) {
            font-size: 17px
          }
        }
        @media only screen and (min-width: emCalc(1150)) {
          width: 14%;
        }
      }

      @media #{$medium} {
        &,
        > .search-filters-container a {
          height: 80px;
        }
      }

    }

    &.top-hidden {
      @media #{$small-down} {
        .search-header {
          position: relative;
        }
      }
    }
  }
}

// THis container gets pulled in a modal - so it's parent is different.
.search-filter-utils {
  background-color:$muted-color;
}