riot-solr-result-list,
[riot-tag="riot-solr-result-list"],
[data-is="riot-solr-result-list"] {
  #vrweb-search.map-active & {
    display: none;
    overflow: hidden;
  }

  rc-riot-urgency-marketing,
  [riot-tag="rc-riot-urgency-marketing"],
  [data-is="rc-riot-urgency-marketing"] {
    .tag-content {
      padding: 0 20px;
      color: $body-font-color;
      &:before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url('../images/icon-time.svg') center no-repeat;
        background-size: 18px auto;
        position: relative;
        top: 4px;
      }
    }
  }
}