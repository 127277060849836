/*=====================================================
 Foundation Sections Variables
=====================================================*/

//$include-html-section-classes: $include-html-classes; /* Use if needed */

/* We use these to set padding and hover factor */
$section-title-padding: emCalc(15);
$section-content-padding: emCalc(15);
$section-function-factor: 10%;

/* These style the titles */
$section-title-color: $primary-color;
$section-title-color-active: $primary-color;
$section-title-bg: #c6d7d8;
$section-title-bg-active: darken($section-title-bg, $section-function-factor);
$section-title-bg-active-tabs: #fff;
$section-title-bg-hover: darken($section-title-bg, $section-function-factor / 2);

/* Want to control border size, here ya go! */
$section-border-size: 1px;
$section-border-style: none;
$section-border-color: #ccc;

/* Font controls */
$section-font-size: 1em;
$section-text-transform: uppercase;

/* Control the color of the background and some size options */
$section-content-bg: #fff;
$section-vertical-nav-min-width: emCalc(200);
$section-vertical-tabs-title-width: emCalc(200);
$section-bottom-margin: emCalc(20);

$title-selector: ".title";
$content-selector: ".content";
$active-region-selector: ".active";
