riot-solr-result-map,
[riot-tag="riot-solr-result-map"],
[data-is="riot-solr-result-map"] {

  riot-solr-search &,
  [riot-tag="riot-solr-search"] &,
  [data-is="riot-solr-search"] & {

    body & {
      @media #{$small-medium} {
        //height:inherit !important;
      }
    }
  }
}