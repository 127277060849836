//
// Custom Form Variables
//
$include-html-custom-form-classes: $include-html-classes !default;

// We use these to control the basic form styles input styles
$custom-form-border-color: #ccc !default;
$custom-form-border-size: 1px !default;
$custom-form-bg: #fff !default;
$custom-form-bg-disabled: #ddd !default;
$custom-form-input-size: 16px !default;
$custom-form-check-color: #222 !default;
$custom-form-check-size: 16px !default;
$custom-form-radio-size: 8px !default;
$custom-form-checkbox-radius: 0 !default;

// We use these to style the custom select form element.
$custom-select-bg: #fff !default;
$custom-select-fade-to-color: #f3f3f3 !default;
$custom-select-border-color: #ddd !default;
$custom-select-triangle-color: #aaa !default;
$custom-select-triangle-color-open: #222 !default;
$custom-select-height: em-calc(13) + ($form-spacing * 1.5) !default;
$custom-select-margin-bottom: em-calc(20) !default;
$custom-select-font-color-selected: #141414 !default;
$custom-select-disabled-color: #888 !default;

// We use these to control the style of the custom select dropdown element.
$custom-dropdown-height: 200px !default;
$custom-dropdown-bg: #fff !default;
$custom-dropdown-border-color: darken(#fff, 20%) !default;
$custom-dropdown-border-width: 1px !default;
$custom-dropdown-border-style: solid !default;
$custom-dropdown-font-color: #555 !default;
$custom-dropdown-font-size: em-calc(14) !default;
$custom-dropdown-color-selected: #eeeeee !default;
$custom-dropdown-font-color-selected: #000 !default;
$custom-dropdown-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1) !default;
$custom-dropdown-offset-top: auto !default;
$custom-dropdown-list-padding: em-calc(4) !default;
$custom-dropdown-default-float-padding: em-calc(6) !default;
$custom-dropdown-opposite-padding: em-calc(38) !default;
$custom-dropdown-list-item-min-height: em-calc(24) !default;
$custom-dropdown-width-small: 134px !default;
$custom-dropdown-width-medium: 254px !default;
$custom-dropdown-width-large: 434px !default;

// We decided not to make a mixin for the custom forms because
// they rely on a very specific class naming structure.
// We may look at updating this in the future.
@mixin custom-form-input($radius:0px, $mark-size:0px) {
  @include radius($radius);
  $custom-form-input-padding: (($custom-form-input-size - $mark-size) / 2) - $custom-form-border-size;
  @if $custom-form-input-padding >= 0 {
    padding: $custom-form-input-padding;
  } @else {
    padding: 0;
  }
}

// Only include these classes if the variable is true, otherwise they'll be left out.
@if $include-html-custom-form-classes != false {

  /* Custom Checkbox and Radio Inputs */
  form.custom {

    .hidden-field {
      margin-#{$default-float}: -99999px;
      position: absolute;
      visibility: hidden;
    }

    .custom {
      display: inline-block;
      width: $custom-form-input-size;
      height: $custom-form-input-size;
      position: relative;
      top: -1px; /* fix centering issue */
      vertical-align: middle;
      border: solid $custom-form-border-size $custom-form-border-color;
      background: $custom-form-bg;

      &.checkbox {
        @include custom-form-input($radius: $custom-form-checkbox-radius, $mark-size: $custom-form-check-size);
      }

      &.radio {
        @include custom-form-input($radius: 1000px, $mark-size: $custom-form-radio-size);
      }

      &.checkbox {
        &:before {
          content: "";
          display: block;
          font-size: $custom-form-check-size;
          color: $custom-form-bg;
        }
      }

      &.radio.checked {
        &:before {
          content: "";
          display: block;
          width: $custom-form-radio-size;
          height: $custom-form-radio-size;
          @include radius(1000px);
          background: $custom-form-check-color;
          position: relative;
        }
      }

      &.checkbox.checked {
        &:before {
          content: "\00d7";
          color: $custom-form-check-color;
          position: absolute;
          top: -50%;
          left: 50%;
          margin-top: 4px;
          margin-left: -5px;
        }
      }
    }
  }

  /* Custom Select Options and Dropdowns */
  form.custom {
    .custom.dropdown {
      display: block;
      position: relative;
      top: 0;
      height: $custom-select-height;
      margin-bottom: $custom-select-margin-bottom;
      margin-top: 0;
      padding: 0;
      width: 100%;
      background: $custom-dropdown-bg;
      @if $experimental {
        background: -moz-linear-gradient(top, $custom-dropdown-bg 0%, $custom-select-fade-to-color 100%);
        background: -webkit-linear-gradient(top, $custom-dropdown-bg 0%, $custom-select-fade-to-color 100%);
        -webkit-box-shadow: none;
      }
      background: linear-gradient(to bottom, $custom-dropdown-bg 0%, $custom-select-fade-to-color 100%);
      box-shadow: none;
      font-size: $custom-dropdown-font-size;
      vertical-align: top;

      ul {
        overflow-y: auto;
        max-height: $custom-dropdown-height;
      }

      .current {
        cursor: default;
        white-space: nowrap;
        line-height: $custom-select-height - em-calc(1);
        color: $input-font-color;
        text-decoration: none;
        overflow: hidden;
        display: block;
        margin-#{$default-float}: $form-spacing / 2;
        margin-#{$opposite-direction}: $custom-select-height;
      }

      .selector {
        cursor: default;
        position: absolute;
        width: $form-spacing * 2.5;
        height: $custom-select-height;
        display: block;
        #{$opposite-direction}: 0;
        top: 0;
        &:after {
          content: "";
          display: block;
          @include css-triangle(5px, $custom-select-triangle-color, top);
          position: absolute;
          #{$default-float}: ($form-spacing * 2.5) / 2 - em-calc(5);
          top: 50%;
          margin-top: -3px;
        }
      }

      &:hover, &.open {
        a.selector {
          &:after {
            @include css-triangle(5px, $custom-select-triangle-color-open, top);
          }
        }
      }

      .disabled {
        color: $custom-select-disabled-color;
        &:hover {
          background: transparent;
          color: $custom-select-disabled-color;
          &:after {
            display: none;
          }
        }
      }

      &.open ul {
        display: block;
        z-index: 10;
        min-width: 100%;
        @include box-sizing(content-box);
      }

      &.small {
        max-width: $custom-dropdown-width-small;
      }
      &.medium {
        max-width: $custom-dropdown-width-medium;
      }
      &.large {
        max-width: $custom-dropdown-width-large;
      }
      &.expand {
        width: 100% !important;
      }

      &.open.small ul {
        min-width: $custom-dropdown-width-small;
        @include box-sizing(border-box);
      }
      &.open.medium ul {
        min-width: $custom-dropdown-width-medium;
        @include box-sizing(border-box);
      }
      &.open.large ul {
        min-width: $custom-dropdown-width-large;
        @include box-sizing(border-box);
      }
    }

    .error .custom.dropdown {
      @include form-error-color;
      background: rgba($alert-color, 0.1);
      margin-bottom: 0;
    }

    .error .custom.dropdown + small.error {
      margin-top: 0;
    }

    .custom.dropdown ul {
      position: absolute;
      width: auto;
      display: none;
      margin: 0;
      #{$default-float}: -$input-border-width;
      top: $custom-dropdown-offset-top;
      @if $experimental {
        -webkit-box-shadow: $custom-dropdown-shadow;
      }
      box-shadow: $custom-dropdown-shadow;
      margin: 0;
      padding: 0;
      background: $custom-dropdown-bg;
      border: $custom-dropdown-border-style $custom-dropdown-border-width $custom-dropdown-border-color;
      font-size: $em-base;

      li {
        color: $custom-dropdown-font-color;
        font-size: $custom-dropdown-font-size;
        cursor: $cursor-default-value;
        padding-top: $custom-dropdown-list-padding;
        padding-bottom: $custom-dropdown-list-padding;
        padding-#{$default-float}: $custom-dropdown-default-float-padding;
        padding-#{$opposite-direction}: $custom-dropdown-opposite-padding;
        min-height: $custom-dropdown-list-item-min-height;
        line-height: $custom-dropdown-list-item-min-height;
        margin: 0;
        white-space: nowrap;
        list-style: none;

        &.selected {
          background: $custom-dropdown-color-selected;
          color: $custom-dropdown-font-color-selected;
        }
        &:hover {
          background-color: darken($custom-dropdown-color-selected, 4%);
          color: $custom-dropdown-font-color-selected;
        }
        &.selected:hover {
          background: $custom-dropdown-color-selected;
          cursor: $cursor-default-value;
          color: $custom-dropdown-font-color-selected;
        }
      }

      &.show {
        display: block;
      }
    }

    /* Custom input, disabled */
    .custom.disabled {
      background: $custom-form-bg-disabled;
    }
  }
}
