riot-solr-facet-group,
[riot-tag="riot-solr-facet-group"],
[data-is="riot-solr-facet-group"] {

  //Global
  body & {

    .form-actions {
      button {
        @include vrweb-button($color:$success-color);
      }
    }
  }

  .sidebar-second & {
    background: $sidebar-bg;

    form {
      margin-bottom: 0;
    }

    @media #{$small} {
      background: $sidebar-bg-desktop;
      border-radius: $global-radius;
      @include box-shadow();
    }

    .form-actions button{
      margin-top:15px;
      margin-bottom:0;
      &:hover{
        background-color:$muted-color-alt;
        color:$primary-color;
        border:4px solid $muted-color-alt;
        &:after{
          border:1px solid $primary-color;
        }
      }

    }

  }

  body.front & {
    @include min(both);
    background-color: transparent;
    @media #{$small}{
      display:flex;
      @include riottag(rc-riot-avail-filter){
        width: 60% !important;
        form.rc-riot-avail-filter-form > div.input-wrap{
          width: calc(100%/3);
        }
        float:none;
      }
      @include riottag(riot-solr-range-select){
        width:20%;
        float:none;
        padding-right: 10px;
      }
      .form-actions{
        width:20%;
        float:none;
      }
    }
    @media #{$small-medium}{
      .form-actions, select, input{
        font-size:14px !important;
      }
    }

    @media #{$small-down}{
      background-color: $muted-color;
      padding: 15px;

      .form-actions{
        margin-top:15px;
      }
    }
    .form-actions {
      button {
        margin-bottom: 0;
        padding: 5px;
      }
    }
  }

  //Search List
  .reveal-modal &,
  #vrweb-search & {
    background: none;
  }

  .reveal-modal & {
    padding-top: 20px;
  }
}
