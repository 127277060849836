riot-tags-class-toggle,
[riot-tag="riot-tags-class-toggle"],
[data-is="riot-tags-class-toggle"] {

  //Globals
  body & {
    li {
      // Use Default variables
      a {
        color: $toggle-link-color;
        background: $toggle-link-bg;
        border-color: $toggle-border-color;

        &:hover,
        &:active {
          background: $toggle-link-bg-hover;
          color: $toggle-link-color-hover;
        }
      }

      &:hover,
      &:active {
        a {
          background: $toggle-link-bg-hover;
          color: $toggle-link-color-hover;
        }
      }

      //Use Active Variables
      &.active {
        a {
          color: $toggle-active-link-color;
          background: $toggle-active-link-bg;

          &:hover,
          &:active {
            color: $toggle-active-link-color;
            background: $toggle-active-link-bg;
          }
        }

        &:hover,
        &:active {
          a {
            color: $toggle-active-link-color;
            background: $toggle-active-link-bg;
          }
        }
      }

      @media #{$small} {
        // Use Default variables
        a {
          color: $toggle-link-color-desktop;
          background: $toggle-link-bg-desktop;
          border-color: $toggle-border-color-desktop;

          &:hover,
          &:active {
            background: $toggle-link-bg-hover-desktop;
            color: $toggle-link-color-hover-desktop;
          }
        }

        &:hover,
        &:active {
          a {
            background: $toggle-link-bg-hover-desktop;
            color: $toggle-link-color-hover-desktop;
          }
        }

        //Use Active Variables
        &.active {
          a {
            color: $toggle-active-link-color-desktop;
            background: $toggle-active-link-bg-desktop;

            &:hover,
            &:active {
              color: $toggle-active-link-color-desktop;
              background: $toggle-active-link-bg-desktop;
            }
          }

          &:hover,
          &:active {
            a {
              color: $toggle-active-link-color-desktop;
              background: $toggle-active-link-bg-desktop;
            }
          }
        }
      }
    }
  }

  //Search List
  #vrweb-search & {
    @include grid-column(12);
    @include min(both);

    li {
      width: 50%;

      a {
        border: solid 1px $toggle-border-color;
        border-left: none;
      }

      &:last-of-type {
        a {
          border-right: none;
        }
      }
    }

    @media #{$small} {
      li {
        a {
          height: $form-component-height;
          box-sizing: border-box;
          padding-top: 0;
          line-height: $form-component-height;

          i {
            display: inline-block;
            margin-bottom: -6px;
            margin-right: 8px;
          }
        }

        &:first-of-type {

          a {
            border-left: solid 1px $toggle-border-color;
            border-top-left-radius: $global-radius;
            border-bottom-left-radius: $global-radius;
          }
        }

        &:last-of-type {
          a {
            border-right: solid 1px $toggle-border-color;
            border-top-right-radius: $global-radius;
            border-bottom-right-radius: $global-radius;
          }
        }
      }
    }

    @media only screen and (min-width: emCalc(825)) {
      width: 60%;
      float: right;
      max-width: 250px;
    }
  }

  //Set Default active state to list-view for small-down when split view does not exist
  riot-solr-search:not(.map-active) &,
  [riot-tag="riot-solr-search"]:not(.map-active) &,
  [data-is="riot-solr-search"]:not(.map-active) &,
  #vrweb-search:not(.map-active) & {
    li.toggle-list-active {
      @media #{$small-down} {
        a {
          color: $toggle-active-link-color;
          background: $toggle-active-link-bg;

          &:hover,
          &:active {
            color: $toggle-active-link-color;
            background: $toggle-active-link-bg;
          }
        }

        &:hover,
        &:active {
          a {
            color: $toggle-active-link-color;
            background: $toggle-active-link-bg;
          }
        }
      }

      // @media #{$small} {
      // 	a {
      // 		color:$toggle-active-link-color-desktop;
      // 		background:$toggle-active-link-bg-desktop;

      // 		&:hover,
      // 		&:active {
      // 			color:$toggle-active-link-color-desktop;
      // 			background:$toggle-active-link-bg-desktop;
      // 		}
      // 	}

      // 	&:hover,
      // 	&:active {
      // 		a {
      // 			color:$toggle-active-link-color-desktop;
      // 			background:$toggle-active-link-bg-desktop;
      // 		}
      // 	}
      // }
    }
  }

  //Main Search UI
  .search-header & {

    riot-solr-search &,
    [riot-tag="riot-solr-search"] &,
    [data-is="riot-solr-search"] & {

      body & {
        li.toggle-map-active {
          border-left: none;
        }
        li {
          a {
            @media #{$small-down} {
              border: solid 1px $toggle-border-color;
              border-left: none;
            }

            @media #{$small-medium} {
              padding-top: 13px;
            }

            @media #{$medium} {
              height: 79px;
              padding-top: 9px;

              span {
                display: block;
                line-height: 1.2;
                font-size: 14px;
                padding: 0px 8px;
              }
              i {
                margin-bottom: 7px;
                margin-top: 5px;
                width: 20px;
                height: 25px;
              }
            }
          }
          &:last-of-type {
            a {
              @media #{$small-down} {
                border-right: none;
              }
            }
          }

          @media only screen and (min-width: emCalc(1360)) {
            padding-top: 12px;

            span {
              @include min(both);
              letter-spacing: normal;
            }
          }

          @media only screen and (min-width: emCalc(1600)) {
            span {
              font-size: 14px;
            }
          }
        }

        @media only screen and (min-width: emCalc(1150)) {
          width: 18%;
        }
      }
    }

    riot-solr-search.top-hidden &,
    [riot-tag="riot-solr-search"].top-hidden &,
    [data-is="riot-solr-search"].top-hidden & {

      body & {

        li, a {
          @media #{$medium} {
            height: 80px;
          }
        }
      }
    }
  }
  @media #{$small}{
    body.page-riottags-vrw-favorites &{
      float:right !important;
    }
  }
}