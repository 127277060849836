//================================================
//  Toggle Font styles
//  component inactive by default
//================================================
.toggle-font {
  float: right;
  display: inline-block;
  width: auto;
  position: relative;

  @media #{$medium-down}{
    display: none;
  }

  a {
    background-color: rgb(245, 245, 245);
    background-image: linear-gradient(rgb(255, 255, 255), rgb(230, 230, 230));
    box-shadow: rgba(255, 255, 255, 0.2) 0px 1px 0px 0px inset, rgba(0, 0, 0, 0.0470588) 0px 1px 2px 0px;
    display: inline-block;
    border-radius: $global-radius;
    height: 35px;
  }

  span {
    display: inline-block;
    width: 34px;
    height: 35px;
  }

  .smaller-txt {
    border-top-left-radius: $global-radius;
    border-bottom-left-radius: $global-radius;
    background-image: url("../images/icon-smaller-txt.png");
    background-position: center;
    background-repeat: no-repeat;
  }

  .larger-txt {
    border-top-right-radius: $global-radius;
    border-bottom-right-radius: $global-radius;
    background-image: url("../images/icon-larger-txt.png");
    background-position: center;
    background-repeat: no-repeat;
  }
  .active {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25);
  }
  .inactive {
    opacity: .5;
    background-color: rgb(230, 230, 230);
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25);
  }

  .larger-txt.inactive,
  .smaller-txt.active {
    display: none;
  }

  .large-text & {
    .larger-txt.inactive,
    .smaller-txt.active {
      display: inline-block;
    }
    .smaller-txt.inactive,
    .larger-txt.active {
      display: none;
    }
  }
}