//=============================================
//    VACATION RENTAL LISTINGS
//    Style and Variables
//    See _vrweb-settings.scss for global search variables.
//=============================================

//=============================================
//
//  Rental Listing styles.
//  These styles are used only if body has .search-results-page.
//-----------------------------------------------------------------//
body.search-results-page {
  #page-title {
    text-align: center;
  }

  .block-riot-tags-vrw-share-results {
    z-index: 30;
  }

  bt-leads-share-url {
    text-align: center;
    padding: 5px;
    line-height: 1.0;

    a {
      @include vrweb-button($primary-color);
      line-height: 2;
      float: none;

      * {
        line-height: 1.0;
      }
    }
  }
  @if $page-header-image == false {
    @media #{$medium}{
      text-align: left;

      bt-leads-share-url {
        padding: 0;
        float: right;
        display: inline-block;
        width: auto;

        a {
          @include vrweb-button($primary-color, $border-style: true);
          line-height: 2;
        }
      }
    }
  }
}

.vr-search-header {
  width:75%;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:10px;
  font-weight:600;
  color:$white;
  text-shadow: 0 0 5px #000;
}

/**
 * View Mode: Teaser
 */
.node-vr-listing.view-mode-teaser {
  @include grid-column(12);
  padding: 20px 0px;
  border-bottom: solid 1px $muted-color;

  .group-left {
    @include grid-column(12);
    @include min(both);

    @media #{$tiny} {
      @include grid-column(5);
      @include min(left);
    }
  }

  .group-right {
    @include grid-column(12);
    @include min(both);

    @media #{$tiny} {
      @include grid-column(7);
      @include min(both);
    }
  }

  .rc-core-item-name {
    @include grid-column(12);
    padding-left: 10px;
    padding-right: 10px;
    @include teaser-titles($alt-color: true);
  }

  .rc-core-cat {
    font-weight: 700;
    @include grid-column(12);
    padding-left: 10px;
    padding-right: 10px;
  }

  .rc-lodging-detail {
    font-weight: 700;
    display: inline-block;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    float: left;
    line-height: 1.0;
    margin-top: 5px;
  }

  .rc-lodging-detail + .rc-lodging-detail {
    border-left: solid 1px;
  }

  riot-solr-flag {
    top: -8px;
    right: -10px;
    z-index: +1;

    @media #{$tiny} {
      right: auto;
      left: -10px;
      top: -9px
    }

    @if $favorites-style == 'icon' {
      top: 0;
      right: 0;

      @media #{$tiny} {
        left: 0;
        top: 0;
      }
    }
  }

  .rc-core-images {
    @include absolute-images(64%);
  }

  .field-name-body,
  .field-name-field-vr-description-override,
  .rc-core-item-description {
    @include grid-column(12);
    padding: 10px;

    p {
      margin-bottom: 0px;
    }
  }
  .rc-item-pricing {
    display: inline-block;
    width: auto;
    float: left;
    clear: both;
    margin-bottom: 0px;
    padding-left: 10px;

    .rc-item-price {
      margin-bottom: 0px;
    }
    span.rc-price {
      display: block;
      padding-top: 5px;
      color: $pricing-color;
      font-weight: 400;
      font-size: 18px;

      @media #{$tiny} {
        font-size: 21px;
      }
    }
    .rc-item-price-buy {
      @include clearfix;
      margin-bottom: 0px;

      > div {
        @include clearfix;
      }

      button {
        @include vrweb-button($color: $tertiary-color);
        margin-bottom: 0px;
      }
    }
  }
  .field-name-node-link {
    clear: both;
    padding-left: 10px;
    padding-right: 10px;

    a {
      @include vrweb-button($display: block);

      @media #{$tiny} {
        display: inline-block;
      }
    }
  }
  .rc-item-pricing + .field-name-node-link {
    position: absolute;
    bottom: 0px;
    right: 0px;

    a {
      display: inline;
      min-height: 0px;
      height: auto;
      background: none;
      color: $pricing-color;
      text-decoration: underline;
      padding: 0px;
      letter-spacing: .03em;
      box-shadow: none !important;

      &:hover, &:active {
        color: $success-color;
        background: none;
        text-decoration: none;
      }
    }
  }
  @media #{$tiny} {
    .rc-core-item-name,
    .rc-core-cat,
    .field-name-body,
    .field-name-field-vr-description-override,
    .rc-core-item-description,
    .rc-lodging-detail,
    .rc-item-pricing,
    .field-name-node-link {
      @include min(both);
    }

    .rc-lodging-detail + .rc-lodging-detail {
      padding-left: 7px;
      margin-left: 7px;
    }
  }
}

//========================================
//  View Mode: Full
//  Unit Details Page
//========================================
.node-vr-listing.view-mode-full,
.unit-details-page {
  #page-title {
    text-align: center;
  }
  .group-beds-baths-wrapper {
    @extend %no-label;
    text-align: center;

    &, * {
      line-height: 1.0;
    }

    .rc-lodging-detail {
      font-size: 20px;
      display: inline-block;
      width: auto;
    }
    .rc-lodging-detail + .rc-lodging-detail {
      padding-left: 5px;
      border-left: solid 1px $h1-font-color;
      text-transform: none;
    }

    @media #{$small} {
      font-size: 100%;
      margin-bottom: 20px;
      .rc-lodging-detail {
        font-size: 25px;
        line-height: 1.45;
      }

      .rc-lodging-detail + .rc-lodging-detail {
        border-left: none;
        margin-left: 0px;
        padding-left: 0px;

        &:before {
          content: '/';
        }
      }
    }
  }

  .ui-dialog.ui-widget {
    .rc-item-quote {
      tr.even {
        th, td {
          background: lighten($muted-color, 1%);
        }
      }
    }
    .ui-widget-header button {
      box-shadow: none;
      background: none;
      border: none;

      .ui-icon-closethick {
        background: url('../images/icon-x-black.svg') center no-repeat;
      }
    }
  }

  .reveal-modal {
    @media #{$small} {
      max-width: 60%;
      margin-left: -30%;
    }
  }
}

.node-vr-listing.view-mode-full {
  > .group-left {
    @include min(both);

    @media #{$small} {
      padding-right: 20px;
      border-top: solid 1px $muted-color-alt;

      @if $page-header-image == true {
        border-top: none;
      }
    }
  }
  > .group-right {

    h2 {
      text-align: center;
      padding-top: 15px;
      text-transform: uppercase;
      color: $secondary-color;
      letter-spacing: .05em;
      font-size: 1em;
      font-family: $body-font-family;
      font-weight: $body-font-weight;

      @media #{$small-down} {
        @include box-shadow;
        @include content-box(15px);
        padding-top: 10px;
        padding-bottom: 8px;
        background: $white;
      }
    }

    @media #{$small-down} {
      @include content-box(15px);
      background: $muted-color;
    }

    @media #{$small} {
      border-top: solid 1px $muted-color-alt;
      @include min(both);

      @if $page-header-image == true {
        border-top: none;
      }
    }
  }
  > .group-footer {
    clear: both;

    @media #{$small} {
      padding-top: 25px;
    }
  }
  .group-action-links {
    @extend %no-label;
    padding-top: 0px;

    .field-group-format-wrapper {
      padding-top: 5px;
      @include clearfix();
      @include content-box(15px);

    }

    @media only screen and (min-width: emCalc(550)) {
      padding-top: 10px;

      .field-group-format-wrapper {
        padding-top: 0px;
        border-top: solid 1px $tertiary-color;
      }
    }
    @media #{$small} {
      padding-top: 0px;

      .field-group-format-wrapper {
        box-sizing: border-box;
        margin: auto;
        background: $muted-color;
        border: solid 1px $muted-color-alt;

        @if $page-header-image == false {
          border-top: none;
        }
      }
    }

    riot-solr-flag,
    a {
      display: inline-block;
      text-align: center;
    }

    .print-page {
      display: none;
    }
    riot-solr-flag {
      width: 100%;
      position: relative;
      height: auto;
      box-shadow: none;
      overflow: visible;
      padding: 10px;
      background: $tertiary-color;
      @include content-box(15px);
      color: $white;
      text-decoration: none !important;
      letter-spacing: .05em;

      &:hover,
      &:active {
        background-color: $primary-color;
      }

      i {
        border-radius: 0px;
        box-shadow: none;
        display: inline-block;
        width: 15px;
        height: 15px;
        background-size: 15px;
        background-position: center;
        margin-bottom: -1px;
        margin-right: 4px;
      }

      span {
        display: inline-block;
      }
      @media only screen and (min-width: emCalc(850)) {
        width: 50%;
        height: 26px;
        padding-right: 0;
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 4px;
      }
    }
    .vrweb-inquiry-link {
      width: 50%;
      padding: 10px 0px 4px;

      @media only screen and (min-width: emCalc(850)) {
        width: 25%;
        float: right;
        height: 26px;
        box-sizing: content-box;
        padding-right: 15px;
        margin-right: -15px;
      }
      .fi-telephone:before {
        content: "\f194";
      }
    }
    bt-leads-share-url {
      display: inline-block;
      width: 50%;
      border-left: solid 1px $tertiary-color;

      .fi-mail {
        &:before {
          content: "\f1ad";
        }
      }
      a {
        width: 100%;
        text-align: center;
        padding: 10px 0px 4px;
      }
      @media only screen and (min-width: emCalc(850)) {
        width: 25%;
        float: left;
        float: right;
        height: 40px;
        border-left: none;
        border-right: solid 1px $tertiary-color;
      }
    }

    @media only screen and (min-width: emCalc(850)) {
      .print-page,
      .vrweb-inquiry-link,
      riot-solr-flag,
      bt-leads-share-url {
        height: auto;
        width: auto;
        display: inline-block;
        float: none;
        margin: auto;
        border: none;
        box-sizing: border-box;
        background: none;

        &:hover, &:active {
          background: none;
          text-decoration: underline !important;
        }

        &, a {
          color: $primary-color;
        }
      }
      .print-page,
      .vrweb-inquiry-link,
      riot-solr-flag {
        padding: 5px 8px;
      }

      bt-leads-share-url {
        a {
          color: $primary-color;
          padding: 5px 8px;
          margin: auto;
        }
      }
    }
  }
  //

  #Media {
    margin-bottom: 0px;
    padding-top: 5px;

    @media #{$small-down} {
      @include content-box(15px);
      background: $muted-color;
      padding-bottom: 8px;
    }

    @media #{$small} {
      border: solid 1px $muted-color-alt;
      border-top: none;
    }
  }
  // Slideshow.
  .rc-core-item-api-images-royalslider,
  .field-name-field-gallery-images {
    margin-bottom: 0px;

    @media #{$small}{
      margin-bottom: 4px;
    }

    .rsCaption,
    .rsTmb {
      display: none;
    }

    .rsNav .rsTmb {
      display: block;
    }

    .rsGCaption .rsCaption {
      display: block;
    }
  }

  // Viewed last 24 hours.
  .vrw-last-times-viewed {
    border: solid 1px $success-color;
    background-color: $white;
    padding: 5px;
    display: flex;
    margin: 15px 0;
    border-radius: 0;
    align-items: center;
    line-height: 1.2;
    font-size: $paragraph-font-size;
    &:before {
      background: url('../images/icon-time.svg') center no-repeat;
      background-size: 25px auto;
      display: inline-block;
      content: '';
      width: 70px;
      height: 30px;
    }
  }

  .group-vr-full-featured-amenities {
    clear: both;
    padding-bottom: 20px;
    text-transform: capitalize;

    @media #{$small-down} {
      @include content-box(15px);
      background: $white;
    }

    h3 {
      //@extend h4;
      color: $primary-color;
      margin-top: 0;
      text-transform: none;
      font-weight: 700;
      font-size: 19px;
      margin-bottom: 0px;
      padding-bottom: 2px;
      padding-top: 20px;
    }

    .view-content {
      ul {
        @extend .no-bullet;

        li {
          a {
            color: $body-font-color;
          }
        }
      }
    }
  }

  // Amenities tab.
  .group-vr-full-amenities {

    // Singular Category amenities.

    .amenity-wrapper h3.label-above{
      color: $primary-color;
      margin-top: 0;
      text-transform: none;
      font-weight: 700;
      font-size: 19px;
      //margin-bottom: 0px;
      padding-bottom: 2px;
      padding-top: 20px;
    }

    ul {
      list-style: none;
      margin-left: 0px;

      li {
        list-style: none;
        line-height: $document-line-height;
        padding-bottom: 3px;
        text-transform:capitalize;

        em {
          font-style: normal;
          font-weight: 400;

          &:before {
            content: ': ';
            font-weight: 700;
          }
        }
      }
    }
    // Float Escapia style amenities.
    div.amenity-wrapper {
      @include grid-column(4);
      @media #{$small-down} {
        @include grid-column(6);
      }
    }
  }

  // Rates tab.
  .group-vr-full-rates {

    table {

      &.rc-item-prices {
        @include grid-column(12);

        .rc-price-col {
          text-align: left;
        }
      }
      thead {
        background: $muted-color;
      }
      tr.even {
        td {
          background: lighten($muted-color, 2%);
        }
      }
    }
  }

  // Node Location Map.
  .group-vr-location {
    .map-results {
      min-height: 400px;
      margin-bottom: 20px;
    }
  }

  //Reviews
  .group-vr-property-reviews {
    .content {
      a {
        @include vrweb-button($primary-color, $display: block);
        width: 185px;
        margin: auto;
        display: inline-block;

        &:after {
          content: 'Leave a Review';
          display: inline-block;
          background: url("../images/icon-plus-white.svg") center no-repeat;
          background-size: 10px;
          background-position: right 5px;
          padding-right: 15px;
        }
      }
      h4 {
        margin: 0 0 .25rem;
      }
      .rc-core-item-review {
        padding: 1.5rem 0;
        border-bottom: 1px solid gray;
      }
      .rc-core-review-scores {
        clear: both;
        margin: 0 !important;
      }
      .rc-item-rating-stars {
        float: left;
        padding-right: 5px;
      }
      .rc-core-review-comment {
        padding: .25rem 0 .5rem;
        clear: both;
      }
      .rc-core-review-response {
        background: $muted-color;
        border: 1px solid $tertiary-color;
        padding: .5rem 1rem .65rem;
      }
    }
  }

  // driving directions button

  .content > .vrweb-driving-directions {
    @include vrweb-button($color: $secondary-color, $display: block);
    max-width: 200px;
    margin: 0px auto 15px auto;
    padding-top: 10px !important;

    @media #{$small-down}{
      margin-bottom: 5px;
    }
  }
}

// Share quote
.rc-item-price, .rc-item-quote {
  margin-left: 0;
  .bt-leads-share-url-form-wrapper {
    display: block;
    font-size: smaller;
    padding: .3em;
  }
}

// Property Glossary
.view-vr-listing-glossary {

  table {
    width: 100%;

    tr {
      border-bottom: 1px solid $muted-color;
    }

    .views-field-baths,
    .views-field-beds {
      text-align: center;
    }

    td {
      width: 25%;
      &.views-field-view-node {
        text-align: center;
        a {
          @include vrweb-button();

          @media #{$small} {
            width: 50%;
          }
        }
      }
    }
  }

  caption {
    background-color: $primary-color;
    color: $white;
    font-family: $header-font-family;
    padding: 12px;
    text-align: left;
    font-size: 1.6em;
  }
}
#user-review-form {
  label {
    padding: .5rem 0 0;
  }
  .form-item-review-info-arrival-month {
    width: calc(50% - 10px);
    float: left;
  }
  .form-item-review-info-arrival-year {
    width: calc(50% - 10px);
    float: right;
    margin-top: 31px;
  }
  .form-item-review-info-comment {
    clear: both;
  }
  .rc-star-input {
    padding: 1rem 0;
  }
  .rc-star {
    font-size: 19px;
  }
  .rc-star-clear {
    display: block;
    cursor: pointer;
    color: $primary-color;
  }
  button.form-submit {
    margin: 0 auto;
    display: block;
    width: 150px;
  }
  .error {
    li {
      color: $error-color;
    }
  }
}
.guest-review-thank-you-message {
  text-align: center;
  font-size: 21px;
  padding: 0 0 1rem;
  line-height: 1.1;
  color: $tertiary-color;
}

body.node-type-vr-listing {
  rc-riot-avail-filter {
    subtag[tag=rc-riot-avail-pricing] {
      .quote-disclaimer {
        padding: 0 1em 1em;
        font-size: 0.85em;
      }
    }
  }
}

.rc-item-rating {
  background: rgba($black, .6);
  z-index: 1;
  padding: 10px 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: $white;

  .rc-item-rating-value {
    font-size: 14px;
    padding-right: .5em;
  }

  .rc-item-rating-stars {
    margin-bottom: 0;
    padding: 0;
    color: $white;
    display: inline-block;
    float: none;
    margin-right: 5px;
    line-height: 1;

    @include mq(range, 450, 700) {
      flex-basis: 100%;
    }
  }

  .rc-item-rating-detail {
    font-size: 14px;
  }
}

[data-is="rc-riot-result-list-item"] .tag-content .image-wrapper .rc-item-rating {
  margin-bottom: 0px;
  padding-top: 7px;

  .rc-item-rating-stars {
    display: inline-block;
    float: none;
    vertical-align: middle;
  }

  .rc-item-rating-label {
    font-size: 14px;
  }

  .rc-item-rating-detail {
    display: inline-block;
    font-size: 14px;
  }
}

.view-search-content {
  max-width: $row-width;
  margin: 0 auto;
  padding: 30px 15px 10px;
}

riot-solr-result-list {
  subtag[tag=rc-riot-avail-pricing] {
    .quote-disclaimer {
      padding: 0 1em 1em;
      font-size: 0.85em;
    }
  }
}
