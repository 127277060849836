//
// Grid Variables
//
$include-html-grid-classes: $include-html-classes !default;

$row-width: em-calc(1000) !default;
$column-gutter: em-calc(30) !default;
$total-columns: 12 !default;

//
// Grid Function
//

@function gridCalc($colNumber, $totalColumns) {
  @return percentage(($colNumber / $totalColumns));
}

//
// Grid Mixins
//

// For creating container, nested, and collapsed rows.
@mixin grid-row($behavior: false) {

  // use @include grid-row(nest); to include a nested row
  @if $behavior == nest {
    width: auto;
    margin-#{$default-float}: -($column-gutter/2);
    margin-#{$opposite-direction}: -($column-gutter/2);
    margin-top: 0;
    margin-bottom: 0;
    max-width: none;
  }

    // use @include grid-row(collapse); to collapsed a container row margins
  @else if $behavior == collapse {
    width: 100%;
    margin: 0;
    max-width: $row-width;
  }

    // use @include grid-row(nest-collapse); to collapse outer margins on a nested row
  @else if $behavior == nest-collapse {
    width: auto;
    margin: 0;
    max-width: none;
  }

    // use @include grid-row; to use a container row
  @else {
    width: 100%;
    margin-#{$default-float}: auto;
    margin-#{$opposite-direction}: auto;
    margin-top: 0;
    margin-bottom: 0;
    max-width: $row-width;
  }

  // Clearfix for all rows
  @include clearfix();

}

// For creating columns - @include these inside a media query to control small vs. large grid layouts
@mixin grid-column($columns:false, $last-column:false, $center:false, $offset:false, $push:false, $pull:false, $collapse:false, $float:true) {

  position: relative;

  // If collapsed, get rid of gutter padding
  @if $collapse {
    padding-left: 0;
    padding-right: 0;
  }

    // Gutter padding whenever a column isn't set to collapse
    // (use $collapse:null to do nothing)
  @else if $collapse == false {
    padding-left: $column-gutter / 2;
    padding-right: $column-gutter / 2;
  }

  // If a column number is given, calculate width
  @if $columns {
    width: gridCalc($columns, $total-columns);

    // If last column, float naturally instead of to the right
    @if $last-column {
      float: $opposite-direction;
    }
  }

  // If offset, calculate appropriate margins
  @if $offset {
    margin-#{$default-float}: gridCalc($offset, $total-columns);
  }

  // Source Ordering, adds left/right depending on which you use.
  @if $push {
    #{$default-float}: gridCalc($push, $total-columns);
    #{$opposite-direction}: auto;
  }
  @if $pull {
    #{$opposite-direction}: gridCalc($pull, $total-columns);
    #{$default-float}: auto;
  }

  // If centered, get rid of float and add appropriate margins
  @if $center {
    margin-#{$default-float}: auto;
    margin-#{$opposite-direction}: auto;
    float: none !important;
  }

  @if $float {
    @if $float == left or $float == true {
      float: $default-float;
    } @else if $float == right {
      float: $opposite-direction;
    } @else {
      float: none;
    }
  }

}

@if $include-html-grid-classes != false {
  /* Grid HTML Classes */
  .row {
    @include grid-row;

    &.collapse {
      > .column,
      > .columns {
        @include grid-column($collapse: true);
      }

      .row {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .row {
      @include grid-row($behavior: nest);
      &.collapse {
        @include grid-row($behavior: nest-collapse);
      }
    }
  }

  .column,
  .columns {
    @include grid-column($columns: $total-columns);
  }

  @media only screen {

    .column,
    .columns {
      @include grid-column($columns: false);
    }

    @for $i from 1 through $total-columns {
      .small#{-$i} {
        @include grid-column($columns: $i, $collapse: null, $float: false);
      }
    }

    @for $i from 0 through $total-columns - 2 {
      .small-offset-#{$i} {
        @include grid-column($offset: $i, $collapse: null, $float: false);
      }
    }

    [class*="column"] + [class*="column"]:last-child {
      float: $opposite-direction;
    }
    [class*="column"] + [class*="column"].end {
      float: $default-float;
    }

    .column.small-centered,
    .columns.small-centered {
      @include grid-column($center: true, $collapse: null, $float: false);
    }
  }

  /* Styles for screens that are atleast 768px; */
  @media #{$small} {

    @for $i from 1 through $total-columns {
      .large#{-$i} {
        @include grid-column($columns: $i, $collapse: null, $float: false);
      }
    }

    @for $i from 0 through $total-columns - 1 {
      .row .large-offset-#{$i} {
        @include grid-column($offset: $i, $collapse: null, $float: false);
      }
    }

    @for $i from 1 through $total-columns - 1 {
      .push#{-$i} {
        @include grid-column($push: $i, $collapse: null, $float: false);
      }
      .pull#{-$i} {
        @include grid-column($pull: $i, $collapse: null, $float: false);
      }
    }

    .column.large-centered,
    .columns.large-centered {
      @include grid-column($center: true, $collapse: null, $float: false);
    }

    .column.large-uncentered,
    .columns.large-uncentered {
      margin-#{$default-float}: 0;
      margin-#{$opposite-direction}: 0;
      float: $default-float !important;
    }

    .column.large-uncentered.opposite,
    .columns.large-uncentered.opposite {
      float: $opposite-direction !important;
    }

  }

}
