//
// Button Group Variables
//
$include-html-button-classes: $include-html-classes !default;

// Sets the margin for the right side by default, and the left margin if right-to-left direction is used
$button-bar-margin-opposite: em-calc(10) !default;

//
// Button Group Mixins
//

// We use this to add styles for a button group container
@mixin button-group-container($styles:true, $float:false) {
  @if $styles {
    list-style: none;
    margin: 0;
    @include clearfix();
  }
  @if $float {
    float: #{$default-float};
    margin-#{$opposite-direction}: $button-bar-margin-opposite;
    & div {
      overflow: hidden;
    }
  }
}

// We use this to control styles for button groups
@mixin button-group-style($radius:false, $even:false, $float:$default-float) {

  // We use this to control the flow, or remove those styles completely.
  @if $float {
    margin: 0 0 0 (-$button-border-width);
    float: $float;
    // Make sure the first child doesn't get the negative margin.
    &:first-child {
      margin-#{$default-float}: 0;
    }
  }

  // We use these to control left and right radius on first/last buttons in the group.
  @if $radius == true {
    &:first-child,
    &:first-child > a,
    &:first-child > button,
    &:first-child > .button {
      @include side-radius($default-float, $button-radius);
    }
    &:last-child,
    &:last-child > a,
    &:last-child > button,
    &:last-child > .button {
      @include side-radius($opposite-direction, $button-radius);
    }
  } @else if $radius {
    &:first-child,
    &:first-child > a,
    &:first-child > button,
    &:first-child > .button {
      @include side-radius($default-float, $radius);
    }
    &:last-child,
    &:last-child > a,
    &:last-child > button,
    &:last-child > .button {
      @include side-radius($opposite-direction, $radius);
    }
  }

  // We use this to make the buttons even width across their container
  @if $even {
    width: percentage((100/$even) / 100);
    button, .button {
      width: 100%;
    }
  }

}

// Only include these CSS classes if $include-html-classes: true
@if $include-html-button-classes != false {

  /* Button Groups */
  .button-group {
    @include button-group-container;

    & > * {
      @include button-group-style();
    }

    &.radius > * {
      @include button-group-style($radius: $button-radius, $float: null);
    }
    &.round > * {
      @include button-group-style($radius: $button-round, $float: null);
    }

    @for $i from 2 through 8 {
      &.even#{-$i} li {
        @include button-group-style($even: $i, $float: null);
      }
    }
  }

  .button-bar {
    @include clearfix;
    .button-group {
      @include button-group-container($styles: false, $float: true);
    }
  }

}
