body.page-riottags-vrw-specials-items,
body.page-riottags-vrw-favorites {
  // Favorites page
  bt-leads-share-url {
    margin: auto;
    float: none;
    display: block;
    width: 100%;
    text-align: center;
    line-height: 1.0;
    a {
      @include vrweb-button($color: $primary-color);
      display: inline-block;
      float: none;
      &:before {
        background: url("../images/icon-share-white.svg") left center no-repeat; 
        margin-bottom: -2px;
      }
      span {
        display: inline;
        line-height: inherit !important;
      }
    }
  }

  rc-riot-avail-filter {
    width: 100% !important;
    @media #{$small} {
      width: 70% !important;
      .input-wrap, .submit-wrap {
        width: 24% !important;
      }
    }
  }

  riot-solr-result-map {
    display: none;
    @media #{$small} {
      display: block;
    }
  }
}