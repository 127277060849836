/**
 * Client specific and totally custom styles
 * This file will always be blank to start a project
 */

html {
  font-size: 100%;
}

// Admin Menu font-size fix.
#admin-menu > div > .dropdown > li > a {
  font-size: 13px;
}

// Don't use negative margin when using .row inside .row.
.row .row {
  margin-left: 0;
  margin-right: 0;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}