/*===========================================================
  Newsletter Sign up Form
===========================================================*/
.entitytype-newsletter-form {

  .form-item {
    @include grid-column(8);
    @include collapse(left);
  }
  .form-actions {
    @include grid-column(4);
    @include collapse(both);
    padding-top: 22px;

    button {
      display: block;
      width: 100%;
    }
  }
}

/*===========================================================
  Contact Form
===========================================================*/
.entitytype-contact-form {
  @include clearfix;

  .field-name-field-name,
  .field-name-field-email {
    @include grid-column(6);

    &.field-name-field-name {
      padding-left: 0;
      padding-right: 8px;

      @media #{$small-down}{
        @include grid-column(12);
        padding-left: 0;
        padding-right: 0;
      }
    }
    &.field-name-field-email {
      padding-right: 0;
      padding-left: 8px;

      @media #{$small-down}{
        @include grid-column(12);
        padding-left: 0;
        padding-right: 0;

        label {
          padding-top: 15px;
          height: $label-minheight + 15px;
        }
      }
    }
  }
  .field-name-field-contact-subject,
  #edit-field-body-no-summary {
    @include grid-column(12);
    padding-left: 0;
    padding-right: 0;

    label {
      padding-top: 15px;
      height: $label-minheight + 15px;
    }
  }
  #edit-actions {
    padding-top: 20px;
    clear: both;

    button {
      display: block;
      margin: auto;
    }
  }
}

/*===========================================================
  VR Inquiry Form
===========================================================*/

.entitytype-vr_inquiry-form {
  .date-padding {
    padding: 0px !important;
    width: 100%;
  }

  .date-no-float {
    width: 100%;
  }

  label {
    padding-top: 15px;
    height: $label-minheight + 15px;
    margin-bottom: 8px;
  }

  .field-name-field-email,
  .field-name-field-inquiry-arrive,
  .field-name-field-inquiry-adults,
  .field-name-field-inquiry-flexible-dates {
    @include grid-column(6);
    padding-left: 0px;
    padding-right: 8px;
  }

  .field-name-field-phone-number,
  .field-name-field-inquiry-depart,
  .field-name-field-inquiry-children,
  .field-name-field-inquiry-properties {
    @include grid-column(6);
    padding-right: 0px;
    padding-left: 8px;
  }

  .field-name-field-name,
  .field-name-field-body-no-summary {
    @include collapse(both);
    @include grid-column(12);

    &.field-name-field-name {
      label {
        padding-top: 0px;
        height: $label-minheight;
      }
    }
  }
  @media #{$small-down} {
    .field-name-field-email,
    .field-name-field-phone-number,
    .field-name-field-inquiry-properties,
    .field-name-field-inquiry-flexible-dates {
      @include grid-column(12);
      @include collapse(both);
    }
  }

  fieldset {
    margin: 0px;

    legend {
      padding-top: 15px;
      font-size: $label-size;
      font-weight: $label-weight;
      font-style: $label-style;
      color: $label-color;
      height: $label-minheight + 15px;
      width: 40px;
    }

    label {
      padding-top: 0px;
      height: $label-minheight;
      padding-left: 40px;
      margin-top: -$label-minheight;
    }

    .form-item {
      &, input {
        width: 100%;
      }
    }
  }
  .form-item-field-inquiry-arrive-und-0-value-date {
    label {
      padding-left: 43px;
    }
  }
  .form-item-field-inquiry-depart-und-0-value-date {
    label {
      padding-left: 48px;
    }
  }
  .field-name-field-inquiry-flexible-dates {
    padding-top: 20px;

    @media #{$small} {
      padding-top: 30px;
    }
  }
  .form-actions {
    clear: both;
    padding-top: 15px;
    padding-bottom: 15px;

    button {
      display: block;
      margin: auto;
    }
  }
}

.main .block-search-form #edit-submit {
  padding-left: 21px;
  padding-right: 21px;
  width: auto;
}
