// Collapse one or both side(s) of a grid element.
@mixin collapse($side) {
  @if $side == both {
    padding-left: 0 !important;
    padding-right: 0 !important;
  } @else {
    padding-#{$side}: 0 !important;
  }
}

// Determine which template to use for the search form icon.
@mixin datepicker-template($selectors, $template) {
  @if $template == button {
    #{$selectors} {
      @include prefix-postfix-base;
      @include postfix;
      @include replace-text($vrcalendar-image, 50%, 50%);
      @include button(0, $primary-color, false, false, false, true);
      @include grid-column($columns: 3, $float: left);
    }
    input[type="text"]#edit-rcav-begin,
    input[type="text"]#edit-rcav-end,
    input[type="text"]#edit-avail-filter-rcav-begin,
    input[type="text"]#edit-avail-filter-rcav-end {
      @include collapse(both);
      @include grid-column(9);

      @include placeholder {
        color: $black;
      }
    }
  } @else if $template == on-element {
    input[type="text"].hasDatepicker,
    input[type="password"].hasDatepicker,
    input[type="email"].hasDatepicker,
    input[type="tel"].hasDatepicker,
    input[type="number"].hasDatepicker,
    select.hasDatepicker,
    .form-select.hasDatepicker,
    textarea.hasDatepicker,
    input[type="text"]#edit-rcav-begin,
    input[type="text"]#edit-rcav-end,
    input[type="text"]#edit-avail-filter-rcav-begin,
    input[type="text"]#edit-avail-filter-rcav-end {
      //@include grid-column($columns:12);
      background-image: url($vrcalendar-image);
      background-repeat: no-repeat;
      background-position: right 10px center;
      background-size: 18px;
      min-height: 35px;
      padding-left: 10px;

      @include placeholder {
        color: $black;
      }
    }
    #{$selectors} {
      display: none;
    }
  }
}

@mixin desktop-navlink-styles {
  font-family: $head-gf;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: .42px;
  color: $muted-color;
}

@mixin mobile-navlink-styles {
  min-height: 58px;
  font-family: $head-gf;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: .42px;
  padding-top: 15px;
  color: $muted-color;
}

// Service text.
@mixin service-label() {
  display: inline-block;

  // We can't actually use this here because it doesn't respect our
  // inline-block above on desktop layouts and causes text to wrap.
  // @extend .hide-for-medium-down;

  @media #{$medium-down} {
    display: none !important;
  }
}

/* Calendar */

// Striped gradient out
@mixin striped-bg-out($grad-top-out, $grad-bottom-out, $grad-deg-out) {
  background: $grad-top-out; /* Old browsers */
  background: linear-gradient($grad-deg-out, $grad-top-out 50%, $grad-bottom-out 50%);
}

// Striped gradient in
@mixin striped-bg-in($grad-top-in, $grad-bottom-in, $grad-deg-in) {
  background: $grad-bottom-in; /* Old browsers */
  background: linear-gradient($grad-deg-in, $grad-top-in 50%, $grad-bottom-in 50%);
}

// modernizr class and Sass content inject for fallback code.
@mixin no-svg {
  .no-svg & {
    @content;
  }
}

// mixin that requires filename without path or extension and optional extension override
@mixin svg-bkgd($filename, $extension: '.png') {
  background-image: inline-image($filename '.svg');
  @include no-svg {
    background-image: inline-image($filename $extension);
  }
}

// Determine the columns to use for the homepage search avail filter form items.
@mixin avail-filter-columns($parent-width) {
  @if $parent-width >= 6 {
    @include grid-column(3);
  } @else {
    @include grid-column(6);
  }
}

// Gradient BGs
@mixin gradient-bg() {
  background: linear-gradient(to bottom, rgba(237, 237, 237, 0.36) 0%, rgba(0, 0, 0, 0) 100%);
}

// Featured background. Used for full-width featured content lists, such as
// Homepage Featured Rentals and Recently Viewed Properties,
@mixin vrweb-featured-bg() {
  background: $muted-color;
  padding: 0;
}

// Image frame borders.
@mixin image-borders() {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, .2);
}

// Used to truncate text which would otherwise wrap to next line
@mixin truncate-text($height: auto) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  // Not sure why there is a max-height. Keeping it here for legacy purpose.
  max-height: $height;
}

//adds transition to background color
@mixin active-background-color-transition {
  $transition: background .35s ease-in-out;
  transition: $transition;
}

//--------------------------------------------Hover state for Has-dropdown li's in main nav------------------------------------------------//
@mixin has-dropdown-hover {
  // Change this to $small instead to make the top bar break at mobile.
  @media #{$medium}{
    > a {
      position: relative;

      &:after {
        content: '';
        @include absolute-center($top: auto, $bottom: 0px);
        width: 0;
        height: 0;
        display: block;
        border-style: solid;
        border-width: 10px;
        border-color: $transparent $transparent $dropdown-border-color $transparent;
        opacity: 0;
        transition: opacity .35s ease-in-out;
      }

      &:hover, &:active {
        &:after {
          opacity: 1.0;
        }
      }
    }
    &:hover > a:after, &:active > a:after {
      opacity: 1.0;
    }
  }
}

//--------------------------------------------Controls gradient overlay on Homepage Slideshow------------------------------------------------//
@mixin slideshow-styles($fade-out:false, $overlay:true) {
  @if $overlay ==true {
    .rsContainer {
      &:after {
        content: '';
        position: absolute;
        z-index: 11;
        width: 100%;
        height: 100%;
        @include absolute-center($bottom: auto);
        background-image: linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .3));
      }
    }
  }
  @if $fade-out == true {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 200px;
      background-image: linear-gradient(rgba(255, 255, 255, .0), #fff);
      content: "";
      width: 100%;
      z-index: 12;
    }
  }

}


//--------------------------------------------Button mixins------------------------------------------------//

// We need to define a new button-base because there are some font settings like line height we don't always want
// This is because of variable type faces and font-sizes

@mixin vrweb-button-base() {
  border: $button-border;
}

@mixin button-font-size {
  font-size: $button-font-lrg;

  @media #{$medium-down}{
    font-size: $button-font-lrg;
  }
  @media #{$small-down}{
    font-size: $button-font-sml;
  }
  @media #{$tiny-down}{
    font-size: $button-font-tny;
  }
}

@mixin border-arrow($color:$primary-color, $direction:down, $width:10px, $location:bottom, $hover:false) {
  position: relative;

  &:before {
    content: '';
    @include absolute-center($top: auto);
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: $width;
    border-color: $color $transparent $transparent $transparent;
    bottom: -$width *2;

    @if $direction == up {
      border-color: $transparent $transparent $color $transparent;
    }
    @if $direction == right {
      border-color: $transparent $transparent $transparent $color;
    }
    @if $direction == left {
      border-color: $transparent $color $transparent $transparent;
    }
    @if $location == top {
      @include absolute-center($bottom: auto);
      top: - $width * 2;
    }
    @if $location == right {
      @include absolute-center($left: auto);
      right: - $width * 2;
    }
    @if $location == left {
      @include absolute-center($right: auto);
      left: - $width * 2;
    }
  }
  @if $hover == true {
    &:before {
      transition: opacity .35s ease-in-out;
      opacity: 0;
    }
    &:hover, &:active {
      &:before {
        opacity: 1.0;
      }
    }
  }
}

// Our wrapper mixin for all the settings you'd ever need, and some sensible defaults for everything
@mixin vrweb-button($color:$default-button-color, $size:medium, $display:inline-block, $primary:false, $border-style:false) {
  display: $display;
  background-color: $color;
  text-transform: uppercase;
  font-family: $body-gf;
  font-weight: 400;
  letter-spacing: .08em;
  transition-property: color, background-color, border-color;
  transition-duration: .25s;
  transition-timing-function: ease-in-out;
  text-align: center;
  line-height: 2;
  border-radius: $global-radius;
  border: none;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, .2);
  color: $white;
  height: auto;
  position: relative;
  &:hover,
  &:active {
    text-decoration: none;
    background-color: $default-button-hover-color;
    border-color: $default-button-hover-color;
  }

  // Sizes //
  @if $size == 'large' {
    min-height: 50px;
    font-size: 18px;
    padding: 16px 18px 14px;
  }
  @if $size == 'medium' {
    min-height: $form-component-height;
    font-size: 16px;
    padding: 8px 15px 7px;
  }
  @if $size == 'small' {
    min-height: 30px;
    font-size: 12px;
    padding: 8px 12px 7px;
  }
  @if $size == 'tiny' {
    min-height: 24px;
    font-size: 12px;
    padding: 5px 10px 4px;
  }
  @if $primary == true {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }

  @if $color == $tertiary-color {
    &:hover, &:active {
      background-color: $success-color;
    }
  }
  @if $border-style == true {
    background-color: $transparent;
    color: $color;
    border: solid 1px $color;
    box-shadow: none;

    &:hover, &:active {
      color: $white;
      background-color: $color;
      box-shadow: none;
    }
  }
}

@mixin gmap-button($color:$tertiary-color) {
  display: inline-block;
  min-height: 0px;
  height: auto;
  padding: 5px 7px;
  background: $color;
  margin-bottom: 3px;
  margin-top: 3px;
  color: #fff;
  font-weight: 400;
  letter-spacing: .03em;
  font-size: 13px;
  border-radius: 3px;
  font-family: $body-gf;
  line-height: 1.0;
  text-align: center;
  text-transform: none;
  text-decoration: none !important;

  &:hover, &:active {
    background: $tertiary-color;
    color: $white;
  }

  @if $color == $primary-color {
    &:hover, &:active {
      background: $tertiary-color;
    }
  }
  @if $color == $tertiary-color {
    &:hover, &:active {
      background: $primary-color;
    }
  }
}

@mixin teaser-titles($mtop:8px, $mbottom:3px, $alt-color:false, $larger:false) {
  margin-top: $mtop;
  margin-bottom: $mbottom;

  &, a {
    font-family: $head-gf;
    font-weight: 700;
    font-size: 19px;
    color: $secondary-color;
    font-style: italic;
    text-transform: none;
    letter-spacing: .03em;

    @if $larger == true {
      font-size: 19px;
    }
  }

  a:hover,
  a:active {
    color: $primary-color;
  }

  @if $alt-color == true {
    &, a {
      color: $black;
    }
    a:hover, a:active {
      color: $success-color;
    }
  }
}

@mixin teaser-title-padding {
  @media only screen and (min-width: 481px) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 140px;
  }

  @media #{$tiny-down}{
    padding-right: 50px;
  }
}

@mixin responsive-button($size: 25px, $font-size: 25px, $content: "+", $font-family: $arial, $font-color: #FFF, $radius: 20px) {

  @media #{$tiny-down} {
    color: $transparent;
    width: $size;
    height: $size;
    line-height: $size;
    border-radius: $radius;
    overflow: hidden;
    position: absolute;
    padding: 0;
    top: 0;
    right: 0;
    min-height: 0px;

    &:hover {
      color: $transparent;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: $content;
      color: #{$font-color} !important;
      display: block;
      width: $size;
      height: $size;
      line-height: $size;
      text-align: center;
      font-weight: bold;
      font-family: $arial;
      font-size: $font-size;
    }
  }
}

@mixin teaser-button-position() {
  position: absolute;
  top: 14px;
  right: 0.9375em; //Grid values - don't think there is a var for this?
  @media #{$small-down}{
    top: 8px;
  }
  @media #{$tiny-down}{
    top: 5px;
  }
}

//// Font calculator
//@function calculate-mobile-size($font-size-px, $font-divide-number){
//  @return $font-size-px / $font-divide-number
//}

// SVG background images with PNG and retina fallback

$image-path: '../images' !default;
$fallback-extension: 'png' !default;

@mixin background-image($name, $size:false) {
  background-image: url(#{$image-path}/#{$name}.svg);
  @if ($size) {
    background-size: $size;
  }

  .no-svg & {
    background-image: url(#{$image-path}/#{$name}.#{$fallback-extension});
  }
}

@mixin absolute-center($top:0, $bottom:0, $left:0, $right:0) {
  position: absolute;
  margin: auto;
  top: $top;
  bottom: $bottom;
  left: $left;
  right: $right;
}

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin icon($fontfamily: foundation-icons) {
  font-family: "#{$fontfamily}";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  text-decoration: inherit;
}

// Add percentage of white to a color
@function tint($color, $percent) {
  @return mix(white, $color, $percent);
}

// Add percentage of black to a color
@function shade($color, $percent) {
  @return mix(black, $color, $percent);
}

@mixin hide-text {
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}

// Mixin for placeholder styles
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder { /* Firefox 18- */
    @content;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

%no-label {
  > h3 {
    display: none !important;
  }
}

@mixin box-shadow($darker:false) {
  box-shadow: $global-box-shadow;

  @if $darker == true {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, .3);
  }
}

@mixin min($side) {
  @if $side == 'left' or $side == 'right' {
    padding-#{$side}: 0px;
  }

  @if $side == 'both' {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@mixin content-box($padding) {
  box-sizing: content-box;
  width: 100%;
  padding-left: $padding;
  padding-right: $padding;
  margin-left: -$padding;
}

@mixin grid-clear($num, $gutter:16px) {
  $padding: $gutter/2;
  clear: none;
  padding-left: $padding;
  padding-right: $padding;

  &:nth-of-type(#{$num}n+1) {
    clear: both;
    padding-left: 0px;
    padding-right: $gutter;
  }

  &:nth-of-type(#{$num}n) {
    clear: none;
    padding-right: 0px;
    padding-left: $gutter;
  }
}

@mixin normalize-page-header {
  background-image: none;

  @media #{$small} {
    height: auto;

    &:after {
      background: linear-gradient(rgba($black, .2), $transparent 70%);
    }
    .title-wrapper {
      position: relative;

      &:after {
        display: none;
      }
    }
    #page-title {
      color: $primary-color;
      text-shadow: none;
      margin-top: 40px;

      .addthis_toolbox {
        padding-top: 4px;
        a {
          background-color: $primary-color;
          color: $white;
          @include vrweb-button($size: 'small');
          &:hover, &:active {
            background-color: $secondary-color;
          }
        }
      }
    }
  }
  @media #{$medium} {
    padding-top: $header-height-desktop;

    &:after {
      background: linear-gradient(rgba(#4680b3, .75), $transparent 70%);
    }
  }
}

@mixin absolute-images($percentage) {
  background: $muted-color;
  position: relative;
  display: block;
  width: 100%;
  height: 0px;
  overflow: hidden;
  padding-top: $percentage;

  img {
    display: block;
    position: absolute;
    min-width: 102%;
    height: auto;
    margin: auto;
    top: 0px;
    left: -1%;
    right: -1%;
    bottom: 0px;
  }
}

@mixin range-slider-fw() {
  width: 100%;
  padding-top: 30px;
  padding-left: 5px;

  > h5 {
    top: 5px;
    width: 100%;
    text-align: center;

    &:after {
      content: ': ';
    }
  }
}

@mixin flex-grid($justify:space-between, $flow:row wrap, $align:stretch, $grow:0, $shrink:0, $basis:100%) {
  display:flex;
  flex-flow:$flow;
  justify-content:$justify;
  align-items:$align;

  > * {
    flex-grow:$grow;
    flex-shrink:$shrink;
    flex-basis:$basis;
  }
}

@mixin flex-wrapper($justify:space-between, $flow:row wrap, $align:stretch, $content:flex-start) {
  display:flex;
  flex-flow:$flow;
  justify-content:$justify;
  align-items:$align;
  align-content:$content;
}

@mixin riottag($riottag:riottag, $parent:false, $class:null) {
  @if $class==null {
    @if $parent==true {
      #{$riottag} &,
      [data-is="#{$riottag}"] &,
      [riot-tag="#{$riottag}"] & {
        @content;
      }
    }@else {
      #{$riottag},
      [data-is="#{$riottag}"],
      [riot-tag="#{$riottag}"] {
        @content;
      }
    }
  }@else {
    @if $parent==true {
      #{$riottag}.#{$class} &,
      [data-is="#{$riottag}"].#{$class} &,
      [riot-tag="#{$riottag}"].#{$class} & {
        @content;
      }
    }@else {
      #{$riottag}.#{$class},
      [data-is="#{$riottag}"].#{$class},
      [riot-tag="#{$riottag}"].#{$class} {
        @content;
      }
    }
  }
}

@mixin mq($realm: min, $value1: 0, $value2: 0) {
  @if $realm ==min {
    @media only screen and (min-width: emCalc($value1)) {
      @content;
    }
  }

  @if $realm ==max {
    @media only screen and (max-width: emCalc($value1)) {
      @content;
    }
  }

  @if $realm ==range {
    @media only screen and (min-width: emCalc($value1)) and (max-width: emCalc($value2)) {
      @content;
    }
  }
}
