.page-sitemap {
  .site-map-menus .content,
  .site-map-taxonomys .content {
    > .site-map-menu,
    > ul {
      list-style: none;
      margin-left: 0px;

      li {
        margin-bottom: 5px;
      }

      .site-map-menu {
        margin-top: 8px;
        margin-bottom: 8px;
        margin-left: 15px;
        list-style: circle;
      }
    }
  }
  .site-map-taxonomys {

  }
}