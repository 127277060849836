//=============================================
//    FOOTER
//    Styles, Typography, and Variables
//=============================================

$footer-block-title-size: 17px !default;
$footer-block-title-color: $black !default;
$footer-block-title-weight: 700 !default;
$footer-text-color: $primary-color !default;

//=============================================
//  Footer styles.
//-------------------------------------------//
.footer-wrapper {
  background: $muted-color;
  text-align: center;
  padding-top: 0;
  padding-bottom: 60px;

  @media #{$small} {
    text-align: left;
  }

  a {
    color: $charcoal;
  }

  a:hover, a:active {
    text-decoration: underline;
    color: $charcoal;
  }
}

.footer-top.lazy-bg-processed {
  background: url(../images/footer-bg.jpg) top center no-repeat;
  background-size: cover;
}

//Newsletter Signup & Property By Name
.block-vrfusion-newsletter-form {
  text-align: center;
  padding: 50px 15px 40px;

  .nf-title {
    font-family: $head-gf;
    font-style: italic;
    color: $white;
    font-size: 24px;
    text-transform: capitalize;
    font-weight: normal;
    margin-bottom: -6px;
    line-height: 32px;
    letter-spacing: 0px;
  }

  .form-type-emailfield {
    padding-right: 0px;

    label {
      visibility: hidden;
    }

    input {
      height: 40px;
      color: $charcoal;
    }
  }

  .form-actions {
    button {
      background-color: $secondary-color;
      min-height: 42px;
      height: 42px;
    }
  }

  @media #{$small} {
    @include grid-column(6);
    float: left;
  }
}

.block-menu-menu-footer-social-icons {
  text-align: center;
  padding: 50px 15px 40px;

  .vrweb-social {
    &:before {
      content: 'Follow Us';
      font-family: $head-gf;
      font-style: italic;
      color: $white;
      font-size: 24px;
      display: block;
      line-height: 32px;
      margin-bottom: 15px;
    }
  }

  @media #{$small} {
    @include grid-column(6);
    float: right;
  }
}

.view-display-id-vacation_rentals_dropdown {
  h6 {
    text-align: right;
  }

}

.footer-middle-content {
  @include clearfix();
  padding: 20px 0;
  @media #{$small} {
    padding: 60px 0 50px;
  }

  p,
  li,
  td {
    color: $charcoal;
    font-size: 16px;
  }

  section {
    @include grid-column(12);
    padding: 15px;

    * {
      margin-bottom: 0px;
    }

    h6,
    h4.block-title {
      font-family: $head-gf;
      color: $secondary-color;
      font-size: 20px;
      text-transform: capitalize;
      font-weight: normal;
      font-style: italic;
      letter-spacing: .2px;
      margin-bottom: 15px;
    }

    @media only screen and (min-width: emCalc(600)) and (max-width: emCalc(767)) {
      @include grid-column(6);
      text-align: left;
    }
  }
  @media #{$small} {
    text-align: left;

    .block-vrweb-base-contact-us {
      @include grid-column(4);
    }
    .block-menu-menu-quick-links-one {
      @include grid-column(3);
    }
  }

  .block-views-1f0e0e87c933b05654af099bcf51afea {
    .view {
      text-align: left;

      .view-header {
        @media only screen and (max-width: emCalc(599)) {
          text-align: center;
        }
      }
    }
    @media only screen and (min-width: emCalc(600)) and (max-width: emCalc(767)) {
      @include grid-column(12);
    }
    @media #{$small} {
      @include grid-column(4);
      float: right;
      .view {
        float: right;
        width: 100%;
      }
    }
    label {
	    display: none;
    }
    .form-actions {
	    padding-top: 0;
	    button {
		    color: $white;
		    padding-left: 0;
		    padding-right: 0;
		    border: none;
		    &:after {
			    display: none;
		    }
	    }
    }
    .form-item {
	    padding-right: 0;
    }
  }

}

.footer-bottom-wrapper {
  padding-bottom: 10px;
  padding-top: 10px;

  .copyright,
  .powered-by {
    text-align: center;
    line-height: 1.4;
    font-size: 13px;
  }
  @media #{$small} {
    .copyright {
      text-align: left;
    }

    .powered-by {
      text-align: right;
    }
  }
}
