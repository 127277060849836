/**
 * Callout Grid.
 */
.paragraphs-item-callout-grid {
  .group-content {
    >div {
      .container {
        a {
          @include vrweb-button($success-color);
          text-shadow: none;
        }
      }
    }
  }
}

/**
 * Two Column Layout.
 */
.paragraphs-item-two-column-layout {
  .group-content {
    div {
      >a {
        @include vrweb-button($success-color);
        text-shadow: none;
      }
    }
  }
}

.field-name-field-page-builder .entity-paragraphs-item {
  form {
    input,
    select,
    textarea {
      border: 1px solid #f1f1f1;
    }

    label {
      height: auto !important;
    }
  }
}

.field-name-field-page-builder {
  a.button {
    line-height: 2.3;
  }
}

.paragraphs-item-testimonials {
  .slick-slide {
    clear: none;
  }
}
