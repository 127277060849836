//
// Pagination Variables
//
$include-html-nav-classes: $include-html-classes !default;

// We use these to control the pagination container
$pagination-height: em-calc(24) !default;
$pagination-margin: em-calc(-5) !default;

// We use these to set the list-item properties
$pagination-li-float: $default-float;
$pagination-li-height: em-calc(24) !default;
$pagination-li-font-color: #222 !default;
$pagination-li-font-size: em-calc(14) !default;
$pagination-li-margin: em-calc(5) !default;

// We use these for the pagination anchor links
$pagination-link-pad: em-calc(1 7 1) !default;
$pagination-link-font-color: #999 !default;
$pagination-link-active-bg: darken(#fff, 10%) !default;

// We use these for disabled anchor links
$pagination-link-unavailable-cursor: default !default;
$pagination-link-unavailable-font-color: #999 !default;
$pagination-link-unavailable-bg-active: transparent !default;

// We use these for currently selected anchor links
$pagination-link-current-background: $primary-color !default;
$pagination-link-current-font-color: #fff !default;
$pagination-link-current-font-weight: bold !default;
$pagination-link-current-cursor: default !default;
$pagination-link-current-active-bg: $primary-color !default;

//
// Pagination Mixin
//

// Style the pagination container. Currently only used when centering elements.
@mixin pagination-container($center:false) {
  @if $center {
    text-align: center;
  }
}

// Style unavailable list items
@mixin pagination-unavailable-item() {
  a {
    cursor: $pagination-link-unavailable-cursor;
    color: $pagination-link-unavailable-font-color;
  }
  &:hover a,
  & a:focus {
    background: $pagination-link-unavailable-bg-active;
  }
}

// Style the current list item. Do not assume that the current item has
// an anchor <a> element.
@mixin pagination-current-item($has-anchor: true) {
  @if $has-anchor {
    a {
      background: $pagination-link-current-background;
      color: $pagination-link-current-font-color;
      font-weight: $pagination-link-current-font-weight;
      cursor: $pagination-link-current-cursor;

      &:hover,
      &:focus {
        background: $pagination-link-current-active-bg;
      }
    }
  } @else {
    height: auto;
    padding: $pagination-link-pad;
    background: $pagination-link-current-background;
    color: $pagination-link-current-font-color;
    font-weight: $pagination-link-current-font-weight;
    cursor: $pagination-link-current-cursor;

    &:hover,
    &:focus {
      background: $pagination-link-current-active-bg;
    }
  }
}

// We use this mixin to set the properties for the creating Foundation pagination
@mixin pagination($center:false, $base-style:true, $use-default-classes:true) {

  @if $base-style {
    display: block;
    height: $pagination-height;
    margin-#{$default-float}: $pagination-margin;

    li {
      height: $pagination-li-height;
      color: $pagination-li-font-color;
      font-size: $pagination-li-font-size;
      margin-#{$default-float}: $pagination-li-margin;

      a {
        display: block;
        padding: $pagination-link-pad;
        color: $pagination-link-font-color;
      }

      &:hover a,
      a:focus {
        background: $pagination-link-active-bg;
      }

      @if $use-default-classes {
        &.unavailable {
          @include pagination-unavailable-item();
        }
        &.current {
          @include pagination-current-item();
        }
      }
    }
  }

  // Left or center align the li elements
  li {
    @if $center {
      float: none;
      display: inline-block;
    } @else {
      float: $pagination-li-float;
      display: block;
    }
  }
}

@if $include-html-nav-classes != false {

  // Detach the nested class selectors from the containing
  // ul and div elements in order to support more flexibility
  // for external code that uses the Sass mixins.

  /* Default Pagination */
  ul.pagination {
    @include pagination;
  }

  /* Pagination centred wrapper */
  .pagination-centered {
    @include pagination-container(true);

    ul.pagination {
      @include pagination(true, false);
    }
  }
}
