//
// Typography Variables
//
$include-html-type-classes: $include-html-classes !default;

// We use these to control header font styles
$header-font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif !default;
$header-font-weight: bold !default;
$header-font-style: normal !default;
$header-font-color: #222 !default;
$header-line-height: 1.4 !default;
$header-top-margin: .2em !default;
$header-bottom-margin: .5em !default;
$header-text-rendering: optimizeLegibility !default;

// We use these to control header font sizes
$h1-font-size: em-calc(44) !default;
$h2-font-size: em-calc(37) !default;
$h3-font-size: em-calc(27) !default;
$h4-font-size: em-calc(23) !default;
$h5-font-size: em-calc(18) !default;
$h6-font-size: 1em !default;

// These control how subheaders are styled.
$subheader-line-height: 1.4 !default;
$subheader-font-color: lighten($header-font-color, 30%) !default;
$subheader-font-weight: 300 !default;
$subheader-top-margin: .2em !default;
$subheader-bottom-margin: .5em !default;

// A general <small> styling
$small-font-size: 60% !default;
$small-font-color: lighten($header-font-color, 30%) !default;

// We use these to style paragraphs
$paragraph-font-family: inherit !default;
$paragraph-font-weight: normal !default;
$paragraph-font-size: 1em !default;
$paragraph-line-height: 1.6 !default;
$paragraph-margin-bottom: em-calc(20) !default;
$paragraph-aside-font-size: em-calc(14) !default;
$paragraph-aside-line-height: 1.35 !default;
$paragraph-aside-font-style: italic !default;
$paragraph-text-rendering: optimizeLegibility !default;

// We use these to style <code> tags
$code-color: darken($alert-color, 15%) !default;
$code-font-family: Consolas, 'Liberation Mono', Courier, monospace !default;
$code-font-weight: bold !default;

// We use these to style anchors
$anchor-text-decoration: none !default;
$anchor-font-color: $primary-color !default;
$anchor-font-color-hover: darken($primary-color, 5%) !default;

// We use these to style the <hr> element
$hr-border-width: 1px !default;
$hr-border-style: solid !default;
$hr-border-color: #ddd !default;
$hr-margin: em-calc(20) !default;

// We use these to style lists
$list-style-position: outside !default;
$list-side-margin: 0 !default;
$list-side-margin-no-bullet: $list-side-margin;
$list-nested-margin: em-calc(20) !default;
$definition-list-header-weight: bold !default;
$definition-list-header-margin-bottom: .3em !default;
$definition-list-margin-bottom: em-calc(12) !default;

// We use these to style blockquotes
$blockquote-font-color: lighten($header-font-color, 30%) !default;
$blockquote-padding: em-calc(9 20 0 19) !default;
$blockquote-border: 1px solid #ddd !default;
$blockquote-cite-font-size: em-calc(13) !default;
$blockquote-cite-font-color: lighten($header-font-color, 20%) !default;
$blockquote-cite-link-color: $blockquote-cite-font-color !default;

// Acronym styles
$acronym-underline: 1px dotted #ddd !default;

// We use these to control padding and margin
$microformat-padding: em-calc(10 12) !default;
$microformat-margin: em-calc(0 0 20 0) !default;

// We use these to control the border styles
$microformat-border-width: 1px !default;
$microformat-border-style: solid !default;
$microformat-border-color: #ddd !default;

// We use these to control full name font styles
$microformat-fullname-font-weight: bold !default;
$microformat-fullname-font-size: em-calc(15) !default;

// We use this to control the summary font styles
$microformat-summary-font-weight: bold !default;

// We use this to control abbr padding
$microformat-abbr-padding: em-calc(0 1) !default;

// We use this to control abbr font styles
$microformat-abbr-font-weight: bold !default;
$microformat-abbr-font-decoration: none !default;

//
// Typography Placeholders
//

// These will throw a deprecation warning if used within a media query.
%lead {
  font-size: $paragraph-font-size + em-calc(3.5);
  line-height: 1.6;
}

%subheader {
  line-height: $subheader-line-height;
  color: $subheader-font-color;
  font-weight: $subheader-font-weight;
  margin-top: $subheader-top-margin;
  margin-bottom: $subheader-bottom-margin;
}

@if $include-html-type-classes != false {

  /* Typography resets */
  div,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  form,
  p,
  blockquote,
  th,
  td {
    margin: 0;
    padding: 0;
    direction: $text-direction;
  }

  /* Default Link Styles */
  a {
    color: $anchor-font-color;
    text-decoration: $anchor-text-decoration;
    line-height: inherit;

    &:hover,
    &:focus {
      color: $anchor-font-color-hover;
    }

    img {
      border: none;
    }
  }

  /* Default paragraph styles */
  p {
    font-family: $paragraph-font-family;
    font-weight: $paragraph-font-weight;
    font-size: $paragraph-font-size;
    line-height: $paragraph-line-height;
    margin-bottom: $paragraph-margin-bottom;
    text-rendering: $paragraph-text-rendering;

    &.lead {
      @extend %lead;
    }

    & aside {
      font-size: $paragraph-aside-font-size;
      line-height: $paragraph-aside-line-height;
      font-style: $paragraph-aside-font-style;
    }
  }

  /* Default header styles */
  h1, h2, h3, h4, h5, h6 {
    font-family: $header-font-family;
    font-weight: $header-font-weight;
    font-style: $header-font-style;
    color: $header-font-color;
    text-rendering: $header-text-rendering;
    margin-top: $header-top-margin;
    margin-bottom: $header-bottom-margin;
    line-height: $header-line-height - em-calc(3);

    small {
      font-size: $small-font-size;
      color: $small-font-color;
      line-height: 0;
    }
  }

  h1 {
    font-size: $h1-font-size - em-calc(10);
  }
  h2 {
    font-size: $h2-font-size - em-calc(10);
  }
  h3 {
    font-size: $h3-font-size - em-calc(5);
  }
  h4 {
    font-size: $h4-font-size - em-calc(5);
  }
  h5 {
    font-size: $h5-font-size;
  }
  h6 {
    font-size: $h6-font-size;
  }

  .subheader {
    @extend %subheader;
  }

  hr {
    border: $hr-border-style $hr-border-color;
    border-width: $hr-border-width 0 0;
    clear: both;
    margin: $hr-margin 0 ($hr-margin - em-calc(1));
    height: 0;
  }

  /* Helpful Typography Defaults */
  em,
  i {
    font-style: italic;
    line-height: inherit;
  }

  strong,
  b {
    font-weight: bold;
    line-height: inherit;
  }

  small {
    font-size: $small-font-size;
    line-height: inherit;
  }

  code {
    font-family: $code-font-family;
    font-weight: $code-font-weight;
    color: $code-color;
  }

  /* Lists */
  ul,
  ol,
  dl {
    font-size: $paragraph-font-size;
    line-height: $paragraph-line-height;
    margin-bottom: $paragraph-margin-bottom;
    list-style-position: $list-style-position;
    font-family: $paragraph-font-family;
  }

  ul, ol {
    margin-#{$default-float}: $list-side-margin;
    &.no-bullet {
      margin-#{$default-float}: $list-side-margin-no-bullet;
    }
  }

  /* Unordered Lists */
  ul {
    li {
      ul,
      ol {
        margin-#{$default-float}: $list-nested-margin;
        margin-bottom: 0;
        font-size: 1em; /* Override nested font-size change */
      }
    }
    &.square,
    &.circle,
    &.disc {
      li ul {
        list-style: inherit;
      }
    }

    &.square {
      list-style-type: square;
    }
    &.circle {
      list-style-type: circle;
    }
    &.disc {
      list-style-type: disc;
    }
    &.no-bullet {
      list-style: none;
    }
  }

  /* Ordered Lists */
  ol {
    li {
      ul,
      ol {
        margin-#{$default-float}: $list-nested-margin;
        margin-bottom: 0;
      }
    }
  }

  /* Definition Lists */
  dl {
    dt {
      margin-bottom: $definition-list-header-margin-bottom;
      font-weight: $definition-list-header-weight;
    }
    dd {
      margin-bottom: $definition-list-margin-bottom;
    }
  }

  /* Abbreviations */
  abbr,
  acronym {
    text-transform: uppercase;
    font-size: 90%;
    color: $body-font-color;
    border-bottom: $acronym-underline;
    cursor: $cursor-help-value;
  }
  abbr {
    text-transform: none;
  }

  /* Blockquotes */
  blockquote {
    margin: 0 0 $paragraph-margin-bottom;
    padding: $blockquote-padding;
    border-#{$default-float}: $blockquote-border;

    cite {
      display: block;
      font-size: $blockquote-cite-font-size;
      color: $blockquote-cite-font-color;
      &:before {
        content: "\2014 \0020";
      }

      a,
      a:visited {
        color: $blockquote-cite-link-color;
      }
    }
  }
  blockquote,
  blockquote p {
    line-height: $paragraph-line-height;
    color: $blockquote-font-color;
  }

  /* Microformats */
  .vcard {
    display: inline-block;
    margin: $microformat-margin;
    border: $microformat-border-width $microformat-border-style $microformat-border-color;
    padding: $microformat-padding;

    li {
      margin: 0;
      display: block;
    }
    .fn {
      font-weight: $microformat-fullname-font-weight;
      font-size: $microformat-fullname-font-size;
    }
  }

  .vevent {
    .summary {
      font-weight: $microformat-summary-font-weight;
    }

    abbr {
      cursor: $cursor-default-value;
      text-decoration: $microformat-abbr-font-decoration;
      font-weight: $microformat-abbr-font-weight;
      border: none;
      padding: $microformat-abbr-padding;
    }
  }

  @media #{$small} {
    h1, h2, h3, h4, h5, h6 {
      line-height: $header-line-height;
    }
    h1 {
      font-size: $h1-font-size;
    }
    h2 {
      font-size: $h2-font-size;
    }
    h3 {
      font-size: $h3-font-size;
    }
    h4 {
      font-size: $h4-font-size;
    }
  }

  // Only include these styles if you want them.
  @if $include-print-styles {
    /*
     * Print styles.
     *
     * Inlined to avoid required HTTP connection: www.phpied.com/delay-loading-your-print-css/
     * Credit to Paul Irish and HTML5 Boilerplate (html5boilerplate.com)
    */
    .print-only {
      display: none !important;
    }
    @media print {
      * {
        background: transparent !important;
        color: #000 !important; /* Black prints faster: h5bp.com/s */
        box-shadow: none !important;
        text-shadow: none !important;
      }

      a,
      a:visited {
        text-decoration: underline;
      }
      a[href]:after {
        content: " (" attr(href) ")";
      }

      abbr[title]:after {
        content: " (" attr(title) ")";
      }

      // Don't show links for images, or javascript/internal links
      .ir a:after,
      a[href^="javascript:"]:after,
      a[href^="#"]:after {
        content: "";
      }

      pre,
      blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
      }

      thead {
        display: table-header-group; /* h5bp.com/t */
      }

      tr,
      img {
        page-break-inside: avoid;
      }

      img {
        max-width: 100% !important;
      }

      @page {
        margin: 0.5cm;
      }

      p,
      h2,
      h3 {
        orphans: 3;
        widows: 3;
      }

      h2,
      h3 {
        page-break-after: avoid;
      }

      .hide-on-print {
        display: none !important;
      }
      .print-only {
        display: block !important;
      }
      .hide-for-print {
        display: none !important;
      }
      .show-for-print {
        display: inherit !important;
      }
    }
  }

}