// Common global styles.

div[class*="rc-"] {
  //  @extend p;
  margin: inherit;
  //  padding: inherit;
  font-size: $paragraph-font-size;
}

// Stay away from edges on smaller screens.
.node {
  @media #{$medium-down} {
    margin-left: 0 !important;
  }
  @media #{$small-down} {
    margin-right: 0 !important;
  }
}

// Overwrite default Foundation Style Colors
.contain-to-grid {
  background: $transparent;
}

// Remove dropdown arrow for desktop nav
@media all and (min-width: 1025px) {
  .top-bar ul > li.has-dropdown a:after,
  .top-bar ul > li.has-dropdown a:hover:after,
  .top-bar ul > li.has-dropdown a:active:after {
    border: none;
  }
  .top-bar-section .has-dropdown > a {
    padding-right: 15px !important;
  }
}

// Allow for collapsing of columns on complex layouts
.columns.collapse,
.column.collapse {
  padding-left: 0;
  padding-right: 0;
}

// Helper inline classes.
.label-inline,
.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

// List tweaks.
ul {
  list-style-type: $list-style-type;

  &.pagination {
    margin-bottom: 0;
  }

  &.no-bullet {
    margin: 0;
  }

  &.inline-list-center {
    margin: 0;
    text-align: center;
    list-style-type: none;

    li {
      display: inline-block;
      list-style-type: none;
      margin: 0 0.5em;
    }
  }
}

// Button styles

a.vrweb-button, .vrweb-button a {
  @include vrweb-button();
}

// Base Collection Link default for body links

body .main a.vrweb-collection-link {
  color: $anchor-font-color;
}

// Make Chosen "responsive"

.container-inline div.chosen-container {
  max-width: 100%;
}

// For some reason, foundation is really adamant about <button>s having shadows.
// If we set it to false, really set no shadows on this element.

body .button,
body button {
  // Base button styles
  @include vrweb-button();

  @if $inset-shadow == false {
    box-shadow: none;
  }

  @media #{$medium} {
    font-size: $button-font-lrg;
  }

  @media #{$medium-down} {
    font-size: $button-font-med;
  }

  @media #{$small-down} {
    font-size: $button-font-sml;
  }

  @media #{$tiny} {
    font-size: $button-font-tny;
  }
}

// Foundation Section overrides.
.section-container {

  // Foundation sections hate when the last element is floated.
  div.content {
    overflow: hidden;
  }

  section {
    background: white;
  }

  p.title {
    text-align: center !important;
  }
}

// AJAX Loader class, added to items before AJAX request and remove after it.
// Feel free to move this around.
.vrweb-loading {
  background: inline-image("../images/ajax-loader.gif") 0 0 no-repeat;
  min-height: 48px;
}

// Make RoyalSlider responsive.
div.royalSlider {
  width: 100% !important;
}

// Prevent Royal Slider FUBC.
// We use javascript to fade it in after page loads, see vrweb_foundation.js.
.js .royalSlider {
  visibility: hidden;
  opacity: 0;
  transition: opacity 400ms ease-out;
}

.royalSlider > .royalslider-item {
  float: left;
}

// Better RoyalSlider thumbnail overlays.
.rsDefault .rsThumb.rsNavSelected {
  background: rgba(255, 255, 255, 0.35);
}

// Remove the bottom padding on status messages when displayed inside a Zurb
// Foundation Reveal modal.
#status-messages.reveal-modal .alert-box {
  margin-bottom: 0;
}

// Fix Zurb Foundation Reveal Modal z-index and make it play nice with dropdown
// menus, lightboxes, etc.
.reveal-modal {
  z-index: 999;
}

// Reveal Modal flexible size. Adjusts to size of content.
.reveal-modal.flex {
  padding: 0.2em;
  height: auto;
  width: auto;
  // This is calculated and set automatically in the JS.
  left: 0;

  .close-reveal-modal {
    top: -0.5em;
    right: -0.5em;
    z-index: 1103;
    color: white;
    background: black;
    padding: 0 0.2em 0.1em;
    font-weight: bold;
    font-size: 1em;
    border-radius: 15px;
    background-clip: padding-box;
    box-shadow: 1px 1px 0.1em #999, -1px -1px 0.1em #999;
    border: 1px solid white;

    &:hover {
      text-decoration: none;
    }
  }
}

// Auxiliary visibility classes.
// These are based on Foundation's own visibility classes and add support for
// an extra "tiny" screen size.
.show-for-tiny {
  display: none !important;

  @media #{$tiny} {
    display: inherit !important;

    &.table {
      display: table;
    }

    &.thead {
      display: table-header-group !important;
    }

    &.tbody {
      display: table-row-group !important;
    }

    &.tr {
      display: table-row !important;
    }

    &.td,
    &.th {
      display: table-cell !important;
    }
  }
}

.hide-for-tiny {
  display: inherit !important;

  &.table {
    display: table;
  }

  &.thead {
    display: table-header-group !important;
  }

  &.tbody {
    display: table-row-group !important;
  }

  &.tr {
    display: table-row !important;
  }

  &.td,
  &.th {
    display: table-cell !important;
  }

  @media #{$tiny} {
    display: none !important;
  }
}

// custom highlight color on selection
::selection {
  background: lighten($secondary-color, 20%); /* Safari */
}

::-moz-selection {
  background: lighten($secondary-color, 20%); /* Firefox */
}

// Alert box mobile fix

@media #{$small-down}{
  #status-messages {
    .alert-box.alert {
      max-width: 90%;
      overflow: hidden;
      display: block;
    }
  }
}

// Layout update for date picker extra text on tiny view
@media #{$tiny}{
  .front {
    .rc-avail-embed-msg {
      padding: emCalc(10);
      text-align: center;

      .mns {
        display: inline-block;
        font-style: italic;
        width: 100%;
      }

      .start-over {
        display: inline-block;
        @include label;
        font-weight: normal;
        margin: emCalc(5);
      }
    }
  }
}

// views content missing bug fix

.section-container.tabs:not([data-section-resized]):not([data-section-small-style]) {
  visibility: visible;
}
