//Shared Styles
.node-team-members {
  .field-name-field-team-member-phone-number {
    &:before {
      content: "\f1f8";
      font-family: "foundation-icons";
      margin-right: 5px;
    }
  }
  .field-name-field-team-member-email-address {
    &:before {
      content: "\f16d";
      font-family: "foundation-icons";
      margin-right: 5px;
    }
    a {
      color: $black;
      text-decoration: underline;

      &:hover, &:active {
        text-decoration: none;
      }
    }
  }
  .field-name-body {
    padding-top: 10px;
  }
  @media #{$small} {
    .field-name-field-team-member-phone-number,
    .field-name-field-team-member-email-address {
      display: inline-block;
      width: auto;
      margin-top: 3px;

    }
    .field-name-field-team-member-phone-number + .field-name-field-team-member-email-address {
      padding-left: 10px;
      margin-left: 8px;
      border-left: solid 1px $muted-color-alt;
    }
  }
}

//Teaser Styles
.node-team-members.node-teaser {
  @include grid-column(12);
  @include clearfix();
  padding: 20px 0px;
  border-bottom: solid 1px $muted-color;

  .views-row-last & {
    border-bottom: none;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  @media #{$small-down} {
    .group-right {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  @media #{$small} {
    .group-left {
      @include grid-column(3);
      padding-right: 25px;
      padding-left: 0;
    }
    .group-right {
      @include grid-column(9);
      padding-left: 0;
      padding-right: 0;
    }
  }

  .field-name-title {
    h2 {
      @include teaser-titles($alt-color: true);
    }
  }
  .field-name-field-job-title {
    font-size: 17px;
    color: $tertiary-color;
    font-weight: 400;
    letter-spacing: .025em;
    padding-bottom: 5px;
  }

  .field-name-node-link {
    a {
      @include vrweb-button();
    }
  }

}

//Full Content Styles
.node-team-members.view-mode-full {
  padding-top: 15px;

  @media #{$small} {
    padding-top: 0;
  }
  .group-left {
    @media only screen and (min-width: emCalc(525)) {
      @include grid-column(4);
      padding-bottom: 20px;
      @include min(both);
    }
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .group-right {
    @media only screen and (min-width: emCalc(525)) {
      @include grid-column(8);
      padding-bottom: 20px;
      padding-left: 25px;
    }

    .field-name-title {
      h1 {
        margin-bottom: 0;
      }
      @if $page-header-image == true {
        display: none;
      }
    }
  }

  .field-name-field-job-title {
    @include teaser-titles();
  }
}

.node-type-team-members {
  @if $page-header-image == false {
    #page-title {
      display: none;
    }
  }
}

.view-team-members {
  max-width: 1000px;
  margin: auto;
}
