riot-solr-sorts,
[riot-tag="riot-solr-sorts"],
[data-is="riot-solr-sorts"] {
  #vrweb-search & {
    @include grid-column(12);
    margin: 0;
    padding: 15px 15px 0px;
    top: auto;
    left: auto;

    @media only screen and (min-width: emCalc(525)) and (max-width: #{$small-screen-down}) {
      width: 50%;
      float: right;
      padding-top: 15px;
      padding-left: 8px;
    }

    @media #{$small} {
      @include min(both);

    }

    @media only screen and (min-width: emCalc(825)) {
      width: 40%;
      padding-right: 15px;
      padding-top: 0;
      max-width: 250px;

      select {
        border-color: $toggle-border-color-desktop;
      }
    }
  }

  .search-filter-utils & {
    padding: 0 10px 10px;
    max-width: 250px;
    position:static;
    width:100%;
  }
}