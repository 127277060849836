//=================================================================
//    TOP BAR
//    Style and Variables
//    See _vrweb-settings for all topbar variables.
//=================================================================

// In order to get the top bar to break on mobile, replace the entire contents of this file with: https://gist.github.com/mariafromkorea/8d07e929d7b32cac168c

.top-bar-container {
  width: 100%;
  @include collapse(both);
  padding-left: 0;
  padding-right: 0;
  background: $topbar-touch-link-bg;

  @media #{$medium} {
    background: $topbar-bg;
    top: $header-fudge-factor !important;
    height: $topbar-height-desktop;
  }

  .top-bar, ul, ul li a {
    @media #{$medium} {
      background: $transparent;
      color: $primary-color;
    }
  }

  .top-bar {
    @media #{$medium-down}{
      min-height: $topbar-touch-height;
      margin-bottom: 0;
      overflow: hidden;
      max-height: $topbar-touch-height;
      transition: max-height .5s ease-in-out;

      &, &.expanded {
        background: $topbar-touch-link-bg;
      }

      &.expanded {
        max-height: 800px;
      }

      .service-label {
        display: none;
      }
    }
    //media

    @media #{$medium} {
      background: $topbar-bg;
      margin-bottom: 0px !important;

      &, .top-bar-section {
        height: $topbar-height-desktop;
      }

      @if $topbar-display-fullwidth == true {
        display: inline-block;
        width: auto;
      }
    }

    .main-nav {
      text-align: left;

      @media #{$medium-down}{
        background: $transparent;

        > li.first {
          > a {
            border-top: none;
          }
        }
      }

      //====================================
      //  Base Styles for all topbar li's
      //====================================
      li {
        @media #{$medium-down}{
          background: $topbar-touch-link-bg;
          overflow: hidden;
        }

        a:not(.button) {
          @media #{$medium-down}{
            @include mobile-navlink-styles;
            color: $topbar-touch-link-color;
            background: $transparent;
            transition-property: border-color, background, color;
            border-top: solid 1px lighten($topbar-touch-link-bg, 2%);
            border-bottom: solid 1px $topbar-touch-link-bg-hover;

            &:hover, &:active {
              border-color: $topbar-touch-link-bg-hover;
              background: $topbar-touch-link-bg-hover;
              color: $topbar-touch-link-color-hover;
            }
          }

          @media #{$medium} {
            @include desktop-navlink-styles; //edit in mixins
            color: $topbar-link-color;
            background: $topbar-link-bg;
            transition-property: color, background;
            transition-duration: .35s;
            transition-timing-function: ease-in-out;

            &:hover, &:active {
              color: $topbar-link-color-hover;
              background: $topbar-link-bg-hover;
            }
          }
        }
        //a

        &:hover > a, &:active > a {

          @media #{$medium-down} {
            background: $topbar-touch-link-bg-hover;
            color: $topbar-touch-link-color-hover;
          }

          @media #{$medium} {
            color: $topbar-link-color-hover;
            background: $topbar-link-bg-hover;
          }
        }
      }
      //li

      //====================================
      //  Top Level Links behavior
      //====================================

      //Top Level Link Styles, if you need to specify go here
      > li {
        > a:not(.button) {
          @media #{$medium} {
            height: $topbar-height-desktop;
          }
        }
      }
      //Top Level Link hover Behavior
      > li.has-dropdown {
        @include has-dropdown-hover; //controls the little arrow that shows up under a link with dropdown on hover
      }
      // @if $topbar-display-fullwidth == true {
      > li:nth-of-type(1),
      > li:nth-of-type(2) {
        .dropdown .dropdown {
          @media #{$medium} {
            left: 100% !important;
            right: auto !important;
            margin-right: 0px;
            margin-left: -$dropdown-border-width;
            box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15) !important;
          }
        }
      }
      //}
      //====================================
      //  Base styles for all dropdowns
      //====================================
      .has-dropdown {
        .dropdown {
          background: $dropdown-bg;

          @media #{$medium-down}{
            background: $dropdown-touch-bg;
            border: none;
          }

          @media #{$medium}{
            max-height: 0px;
            transition: max-height .25s ease-in-out;
            display: none;
            box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
          }

          li {
            @media #{$medium-down}{
              //background:$topbar-touch-link-bg;
            }
          }

          a, a:link, a:visited {

            @media #{$medium-down}{
              color: $dropdown-touch-link-color;
              background: $transparent;
              @include mobile-navlink-styles;

              &:hover, &:active {
                color: $dropdown-touch-link-color-hover;
                background: $dropdown-touch-link-bg-hover;
              }
            }

            @media #{$medium}{
              border-left: $dropdown-border-style $dropdown-border-width $dropdown-border-color;
              border-right: $dropdown-border-style $dropdown-border-width $dropdown-border-color;
              text-transform: none;
              color: $dropdown-link-color;
              background: $dropdown-link-bg;
              font-weight: 400;
              font-size: 15px;

              &:hover, &:active {
                color: $dropdown-link-color-hover;
                background: $dropdown-link-bg-hover;
              }
            }
          }
          //a

          //===================================================
          //  Styles for first/last links in dropdowns
          //  Add borders for top/bottom and some extra padding
          //===================================================
          li.first:not(.show-for-small) {
            > a:not(.button) {
              @media #{$medium}{
                border-top: $dropdown-border-style $dropdown-border-width $dropdown-border-color;
                padding-top: 15px;
              }
            }
            &.has-dropdown {
              > a:not(.button) {
                @media #{$medium} {
                  padding-right: 25px !important;
                }
                &:after {
                  top: 24px;
                }
              }
            }
          }
          li.last.has-dropdown,
          li.last.leaf {
            > a:not(.button) {
              @media #{$medium}{
                border-bottom: $dropdown-border-style $dropdown-border-width $dropdown-border-color;
                padding-bottom: 15px;
              }
            }
          }
          //========================================
          //  Control display of js generated links
          //  that appear on mobile
          //  Probably don't want to touch these
          //========================================
          li.js-generated {
            a {
              border-top: none;
            }
            & + li {
              display: none !important;
            }
            h5 {
              margin: 0;
            }
          }
          li.show-for-small {
            @media #{$medium-down}{
              display: block !important;
            }
          }
          //====================================
          //  Show Tertiary Nav
          //====================================
          li.has-dropdown {
            .dropdown {
              @media #{$medium} {
                display: none;
                margin-right: -$dropdown-border-width;
                box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.15);
              }
            }
            &:hover, &:active {
              > a:not(.button) {
                background: $topbar-touch-link-bg-hover;
                color: $topbar-touch-link-color-hover;
                @media #{$medium} {
                  color: $dropdown-link-color-hover;
                  background: $dropdown-link-bg-hover;
                }
              }
              .dropdown {
                @media #{$medium} {
                  display: block;
                }
              }
            }
          }
        }
        //dropdown

        &:hover, &:active {
          .dropdown {
            @media #{$medium} {
              display: block;
            }
            max-height: 1000px;
          }
        }
      }
      //has-dropdown
    }
    //main nav

    //===========================================
    //  Mobile Service Bar Component
    //  See layout/service-bar for desktop styles
    //===========================================
    .title-area {
      background: $topbar-touch-bg;
      @media #{$medium-down}{
        float: none;

        li {
          &.name {
            display: inline-block;
            color: $header-icon-color;
            float: left;
            height: $topbar-touch-height;
            line-height: $topbar-touch-height;

            .site-name a {
              color: $header-icon-color;
            }
          }
          &.slogan div {
            color: $header-icon-color;
            padding-left: 15px;
            line-height: $topbar-touch-height;
            float: left;
          }

          &.toggle-topbar.menu-icon {
            height: $topbar-touch-height;
            display: inherit !important;
            top: 0;
            margin-top: 0;
            right: 0;
            margin-left: 0;
            padding-left: 0px;

            a {
              height: 100%;
              width: 40px;
              background: $menu-icon-bg;
              position: relative;

              &:focus, &:active, &:hover {
                box-shadow: none;
              }

              span {
                display: block;
                width: 26px;
                height: 2px;
                margin: auto;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: $menu-icon-color;
                box-shadow: none !important;

                &:before,
                &:after {
                  content: '';
                  display: block;
                  width: 26px;
                  height: 2px;
                  margin: auto;
                  position: absolute;
                  left: 0;
                  right: 0;
                  background: $menu-icon-color;
                  transition: all .075s linear;
                }

                &:before {
                  top: -9px;
                  bottom: auto;
                }
                &:after {
                  top: auto;
                  bottom: -9px;
                }
              }
            }
          }
          //toggle-topbar.menu-icon
        }
        ul.icon {
          display: inline-block;
          color: $header-icon-color;
          float: right;
          margin-right: 40px;
          height: $topbar-touch-height;
          line-height: $topbar-touch-height;
          padding-left: 0;

          .checkout-page & {
            margin-right: 10px;
          }

          li {
            margin-left: 0px;

            &.vrweb_vr_search_link {
              display: none;
            }
            a, a:link, a:visited {
              padding-left: 8px;
              padding-right: 8px;
              color: $muted-color;
            }

            .fi-shopping-bag:before,
            .fi-heart:before,
            .fi-telephone:before,
            .fi-mail:before {
              font-size: 25px;
              line-height: 65px;
              font-family: "foundation-icons";
              background-color: transparent;
              text-align: center;
              display: block;
              color: $muted-color;
              font-size: 24px;
              padding: 5px 0;
              width: 30px;
              height: 30px;
              margin: 17px auto 0px;
              transition: background, .25s ease-in-out;
              line-height: 1;
            }

            .fi-telephone:before {
              margin-top: 15px;
              color: $primary-color;
              text-shadow: -1px 1px 0 $muted-color,
                  1px 1px 0 $muted-color,
                  1px -1px 0 $muted-color,
                  -1px -1px 0 $muted-color;
            }

            .fi-shopping-bag:before {
              content: '';
              background: url(../images/icon-detail-trips.svg) center center no-repeat;
              background-size: 19px auto;
              background-position: center 5px;
            }

            .vrweb-collection-block-count {
              line-height: 65px;
            }

            .fi-mail:before {
              content: '';
              background: url(../images/icon-detail-mail.svg) center center no-repeat;
              background-size: 24px;
            }

            &.bt_leads_share_url_form {
              a {
                width: 35px;
                height: 65px;
                &:before {
                  content: "\f1ad";
                  font-family: "foundation-icons";
                  font-style: normal;
                  font-weight: normal;
                  font-variant: normal;
                  text-transform: none;
                  font-size: 25px;
                  line-height: 65px;
                  -webkit-font-smoothing: antialiased;
                  display: inline-block;
                  text-decoration: inherit;
                }
                &[href*="/admin/structure/block/manage/bt_leads/share_url_form/configure"] {
                  display: none;
                }
              }
            }
            &.vrweb_base_back_link {
              //padding-top:12px;

              a {
                @include vrweb-button($color: $tertiary-color);
                line-height: normal;
              }
            }
          }
        }
      }
      @media (max-width: 360px) {
        ul.icon {
          margin-right: 45px;
        }
        ul.icon li a,
        ul.icon li a:link {
          padding-left: 4px;
          padding-right: 3px;
        }
      }
      //Medium down
      @media #{$small-down} {
        ul.icon {
          li.slogan {
            display: none;
          }
        }
      }
    }
    //title area
    &.expanded {
      .title-area > li.toggle-topbar.menu-icon a span:before {
        transform: translateY(450%) rotate(90deg);
        -webkit-transform: translateY(450%) rotate(90deg);
        -moz-transform: translateY(450%) rotate(90deg);
        -ms-transform: translateY(450%) rotate(90deg);
        -o-transform: translateY(450%) rotate(90deg);
      }
      .title-area > li.toggle-topbar.menu-icon a span:after {
        transform: translateY(-450%) rotate(-90deg);
        -webkit-transform: translateY(-450%) rotate(-90deg);
        -moz-transform: translateY(-450%) rotate(-90deg);
        -ms-transform: translateY(-450%) rotate(-90deg);
        -o-transform: translateY(-450%) rotate(-90deg);
      }
      .title-area > li.toggle-topbar.menu-icon a span {
        transform: translateY(0%) rotate(45deg);
        -webkit-transform: translateY(0%) rotate(45deg);
        -moz-transform: translateY(0%) rotate(45deg);
        -ms-transform: translateY(0%) rotate(45deg);
        -o-transform: translateY(0%) rotate(45deg);
      }
    }
  }
  //top bar
}

//top-bar-container
