//============================
// Floating Tabs
//============================

.floating-tabs {
  background-color: $black;
  left: 0.2em;
  margin: 0;
  min-width: emCalc(60);
  opacity: 0.6;
  padding: 0;
  position: fixed;
  top: 11.5em;
  z-index: 99;

  ul.tabs {
    margin: 0;
    padding: 5px 5px 9px 5px;
    box-shadow: 1px 1px 3px #999;
    border: 1px solid white;

    li {
      margin: 0;
      padding: 0;
      //background-color: $service-bar-bg;
      background-color: $transparent;

      &:hover {
        background-color: $white;
      }

      a {
        display: block;
        color: white;
        padding: 0 0 0 0.4em;
        margin: 0;
        font-size: 0.8em;
        font-weight: 400;
        text-shadow: 1px 1px 1px black;
        opacity: 1.0;

        &:hover {
          color: black;
          text-shadow: 1px 1px 1px white;
        }
      }

    }

  }

}
