[data-is="rc-riot-avail-pricing"] {

  //Both Searches, Mode Detail, Shared Styles
  body article.node-vr-listing rc-riot-avail-filter &,
  body riot-solr-search [data-is="rc-riot-result-list-item"] & {

    .offer-group {
      padding-top: 7px;
    }

    //Both Searches, Mode Detail
      .rc-price {
        .display-name {
          float:none;
        }
        .price {
          float:none;
          clear:none;
          display:block;
          margin:0 0 7px 0;
          width:unset;
          color:$primary-color;

          sup {
            font-size:12px;
          }
        }

        button.book {
          @include vrweb-button($success-color, $size:$small);
          float:none;
          width:100%;
          max-width:300px;
          margin-top: 10px;
        }

        button.quote,
        button.add {
          float: left;
          background-color: transparent;
          color: $primary-color;
          font-size: 14px;
          font-family: $head-gf;
          transition: color, .4s ease-in-out;
          font-style: italic;
          width: 50%;

          @media only screen and (min-width: 1250px) and (max-width: 1520px) {
            width: 100%;
            padding-top: 5px;
            padding-bottom: 5px;
          }

          @media only screen and (min-width: $small-screen) and (max-width: 1036px) {
            width: 100%;
            padding-top: 5px;
            padding-bottom: 5px;
          }

          @media only screen and (min-width: 420px) and (max-width: 612px) {
            width: 100%;
            padding-top: 5px;
            padding-bottom: 5px;
          }

          &:hover,
          &:active {
            color: $secondary-color;
            background-color: transparent;
          }

          &:before {
            background: url(../images/icon-detail-trips-2.svg) center center no-repeat;
            background-size: 100%;
            display: inline-block;
            width: 20px;
            height: 18px;
            content: '';
            box-sizing: border-box;
            margin-bottom: -5px;
            margin-right: -3px;
            opacity: .85;
          }
        }

        button.add {
          float: left;
          margin-bottom: 0px;
        }

        button.quote {
          float: right;
          &:before {
            background-image: url(../images/icon-detail-quote-2.svg);
            width: 18px;
            margin-bottom: -4px;
            margin-right: -2px;
          }
        }
      }

      .not-available, .display-name{
        font-weight: 600;
        font-size: 15px;
        color: $tertiary-color;
        text-align:center;
        padding-left: 0;
        padding-right: 0;
        padding-top: 7px;
        padding-bottom: 2px;
        border-top: solid 1px rgba(17, 17, 17, 0.1);
        margin-top: 10px;

      }
      .trigger{
        display:block;
        text-align:center;
        color: $primary-color;
        font-size: 14px;
        font-family: $head-gf;
        transition: color, .4s ease-in-out;
        font-style: italic;
        &:hover{
          color: $secondary-color;
        }

        &:before {
          background: url(../images/icon-detail-date-2.svg) center center no-repeat;
          background-size: 100%;
          display: inline-block;
          width: 18px;
          height: 18px;
          content: '';
          box-sizing: border-box;
          margin-right: -1px;
          margin-bottom: -3px;
          opacity: .85;
        }
      }
  }
  .tag-content {
    .pricing-detail {
      border-top: solid 1px rgba(17, 17, 17, 0.1);
      padding-top: 7px;
      padding-bottom: 7px;
      .not-available {
        border: none;
        padding: 0;
        margin: 0;
      }
    }
    .offer-group {
      .display-name {
        border-top: none;
        padding: 0;
        margin: 4px 0 2px;
      }
      > h5 {
        text-align: center;
        padding: 0;
        margin: 0;
        border-bottom: none;
      }
    }
  }


  //Map
  [data-is="rc-riot-result-list-item"] & {
    body [data-is="riot-solr-result-map"] & {
      .rc-price {
        button.quote,
        button.add {
          float: none;
          width: 100%;
        }
      }

      .display-name{
        font-weight: 600;
        font-size: 15px;
        color: $tertiary-color;
        text-align:center;
        padding-left: 0;
        padding-right: 0;
        padding-top: 7px;
        padding-bottom: 2px;
        border-top: solid 1px rgba(17, 17, 17, 0.1);
        margin-top: 10px;
      }
    }
  }

  .reveal-modal &{
    .tag-content .pricing-detail{
      .offer-group {
        padding: 10px 0;
      }
      .offer-group:nth-child(even) {
        background: $muted-color-alt;
        margin:5px 0;
      }
      h5{
        text-align:center;
        color:$primary-color;
        border-bottom:none;
        padding-bottom:0;
        margin:5px 0;
        font-size:1.2rem;
      }
      .price {
        float:none;
        clear:none;
        display:block;
        margin:0 0 5px 0;
        width:unset;
        color:$primary-color;

        sup {
          font-size:12px;
        }
      }
      button.book {
        @include vrweb-button($success-color, $size:$small);
      }

      button.quote,
      button.add {
        background-color: transparent;
        color: $primary-color;
        font-size: 14px;
        font-family: $head-gf;
        transition: color, .4s ease-in-out;
        font-style: italic;
        padding-top: 5px;
        padding-bottom: 5px;
        display: inline-block;
        float: none;

        &:hover,
        &:active {
          color: $secondary-color;
          background-color: transparent;
        }

        &:before {
          background: url(../images/icon-detail-trips-2.svg) center center no-repeat;
          background-size: 100%;
          display: inline-block;
          width: 20px;
          height: 18px;
          content: '';
          box-sizing: border-box;
          margin-bottom: -5px;
          margin-right: -3px;
          opacity: .85;
        }
      }

      button.quote {
        &:before {
          background-image: url(../images/icon-detail-quote-2.svg);
          width: 18px;
          margin-bottom: -4px;
          margin-right: -2px;
        }
      }
    }
  }
}
