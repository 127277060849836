.front {
  #page-title {
    @include grid-column(12);
    text-align: center;
    margin: 0px auto;
    padding: 15px 15px 0px;
  }
  .l-main,
  .main.columns {
    padding: 0;
  }

  //Homepage page content
  .node-page.view-mode-homepage {
    .page-title{
      text-align:center;
      margin:0 auto;
      padding: 0 15px 15px;
      clear: both;
    }
    .subtitle {
      max-width: $row-width;
      text-align:center;
      padding-left:15px;
      padding-right:15px;
      margin: 0 auto;
      padding: 0 30px 7px;
      color: $secondary-color;
      letter-spacing: 1.35px;
      font-size: 18px;
    }
    .field-name-body {
      max-width:880px;
      margin:0 auto 30px;
      text-align: center;
      padding-left:15px;
      padding-right:15px;
    }
  }

  .l-content-bottom {
    padding-bottom: 20px;
    background: $muted-color;
    margin-top: 36px;

    .block-vrweb-pages-vrweb-hp-node {
      position: relative;
      top: -20px;
    }
  }


  //Homepage subnav snippets
  .field-name-field-page-snippet-content {
  	padding: 0 15px 25px;
  	ul {
    	li {
        @media #{$small-down} {
          margin: 0 0 15px 0;
          float: none;
        }

        @media #{$small} {
          margin-top: 0px;
        }

        .group-content > a {
          overflow: hidden;
          display: block;

          img {
            display: block;
            width: 100%;
            opacity: 1;
            transition: transform, .3s ease-in-out;
          }

          &:after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: transparent linear-gradient(0deg, #103040BF 0%, #10304000 100%) 0% 0% no-repeat padding-box;
            opacity: 0.75;
            transition: opacity, .3s ease-in-out;
          }

          h3 {
            position: absolute;
            color: $muted-color;
            z-index: 3;
            text-shadow: none;
            font-size: 20px;
            text-transform: none;
            font-style: italic;
            transition-property: color;
            transition-duration: .35s;
            transition-timing-function: ease-in-out;
            position: absolute;
            bottom: 15px;
            text-align: center;
            width: 100%;
            display: block;

            @media #{$tiny-small} {
              font-size: 17px;
            }
          }

          &:active,
          &:hover {
            &:after {
              opacity: 0;
            }

            img {
              transform: scale(1.1);
            }
          }
        }
      }
      .field-name-field-snippet-text {
        display: none;
      }
  	}
  }

  @media #{$small-down} {
    .featured-tabs {
      position: relative;
      top: 40px;
    }
  }
}

//========================
//  Homepage Quick Search
//========================
.qs-trigger {
  display: none !important;

  @media #{$small-down} {
    .front & {
      display: block !important;
      z-index: 14;
      @include absolute-center($bottom: auto);
      color: $white;
      width: 100%;
      height: 43px;
      text-align: center;
      @include collapse(both);

      a, a:link, a:visited {
        text-align: center;
        background-color: $success-color;
        color: $white;
        display: inline-block;
        height: 40px;
        padding: 9px 11px 11px;
        transition: background-color, .4s ease-in-out;
        text-transform: uppercase;
        letter-spacing: .1em;
        font-weight: 700;
        width: 100%;
        max-width: none;

        &:after {
          content: '';
          display: inline-block;
          width: 30px;
          height: 12px;
          background: url('../images/chevron-down-white.svg') center no-repeat;
        }
      }
    }
    .front .show-search & {
      a, a:link, a:visited {
        color: $white;

        &:after {
          background-image: url('../images/chevron-up-white.svg');
        }
      }
    }
  }
}

.front {
  .l-featured {
    background: $featured-tabs-bg-mobile;

    .block-vrweb-base-homepage-video {
      position: relative;
      padding-top: 45.625%;
      overflow: hidden;
      height: 100%;

      @media #{$small-down} {
        margin-top: 40px;
      }

      video {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: auto;
      }
    }

    @media #{$small} {
      @if $featured-tabs-align-bottom == true {
        padding-bottom: 95px;

        @if $featured-tabs-number == 2 or $featured-tabs-number == 3 {
          padding-bottom: 130px;
        }
      }
    }
  }
}

// Layout for homepage value propositions.

.block-views-value-propositions-block {
  padding: 45px 15px;
  width: 100%;
  text-align:center;
  display: block;
  clear: both;
  @media #{$medium-down} {
    padding: 30px 15px;
  }
  .view-value-propositions {
	  @include grid-row();

    .view-header {
      h2 {
        margin-top: 20px;
        text-transform: none;
        font-style: italic;
        letter-spacing: 0px;
      }

      h3 {
        font-size: 18px;
        color: $secondary-color;
        letter-spacing: 1.35px;
      }
    }

	  .view-content {
		  width: 100%;
		  padding-top: 10px;
		  @media only screen and (min-width: emCalc(640)) {
			  display: flex;
			  justify-content: left;
			  flex-wrap: wrap;
		  }
		  .views-row {
			  @include grid-column(12);
			  margin: 3px;
			  @media only screen and (min-width: emCalc(640)) {
				  flex-basis: calc(33.33333% - 6px);
			  }
		  }
	  }
  }

  .node-value-propositions.node-teaser {
	  	padding: 35px 15px;
	  	@media #{$small-medium} {
  	  	padding: 30px 0 15px;
	  	}
	  	.field.field-name-title {
		  	padding-top:15px;
		  	h2 {
          color: $secondary-color;
          font-style: italic;
          font-size: 20px;
		  	}
		}
		img {
			display: block;
			max-height: 150px;
      border-radius: 100%;
			width: 150px;
      height: 150px;
			margin: 0px auto;
		}
		.field-name-title h2 {
			@include teaser-titles();
      margin: 15px 0;
			&, * {
				@media #{$medium-down} {
					font-size: 16px;
				}
				color: $white;
			}
		}
		.field-name-body {
			&, * {
				color: $charcoal;
        font-size: 16px;
			}
		}
    .field-name-node-link {
      a {
        color: $secondary-color;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }

        &:after {
          content: ' >';
        }
      }
    }
		.field-name-field-value-prop-link {
			a {
				color:$white;
				@include vrweb-button($color: $white, $border-style: true);
				&:hover {
					color: $primary-color;
				}
			}
		}
	}
}

// Layout for Homepage Callout view

.view-homepage-callouts {
  text-align: center;
  padding: 0 10px;
  .views-field-title {
    font-size:$h4-font-size;
    text-transform: uppercase;
    letter-spacing: .05em;
    font-weight: 400;
    color: $success-color;
    padding: .75rem 0 .5rem;
    span.field-content {
      line-height: 1.2;
      display: block;
      padding-top: 30px;
    }
  }
  .views-field-body {
    padding-bottom: 10px;
  }
}

.view-mode-featured_rental {
  @media (min-width: 420px) and (max-width: 550px) {
    font-size: 13px;
  }
  ul {
    list-style: none;
    margin: 0;
  }
}


// Layout for homepage promotions.

.block-views-40bb2578e15c2676e3c07dd4697bed0f {
  padding: 45px 15px 15px;
  width: 100%;
  text-align:center;
  display: block;
  clear: both;
  @media #{$medium-down} {
    padding-top: 25px;
    padding-bottom: 10px;
  }
  h4.block-title {
    font-size:$h2-font-size;
    text-transform: uppercase;
    letter-spacing: .09em;
    font-weight: 700;
    color: $black;
  }
  .view-display-id-block_homepage_promotions {
	  @include grid-row();
	  .view-content {
		  width: 100%;
		  padding-top: 10px;
		  @media only screen and (min-width: emCalc(640)) {
			  display: flex;
			  justify-content: left;
			  flex-wrap: wrap;
		  }
		  .views-row {
			  @include grid-column(12);
			  margin: 3px;
			  padding-left: 0;
			  padding-right: 0;
			  @media only screen and (min-width: emCalc(640)) {
				  flex-basis: calc(33.33333% - 6px);
			  }
		  }
	  }
  }

  .node-special.view-mode-homepage {
    .group-content {
  		position: relative;
  		.special-teaser-image {
    		img {
    			display: block;
    			height: auto;
    			width: 100%;
    			margin: 0px auto;
          border: 2px solid $black;
  		  }
        &:after {
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,.15);
          transition: background-color, .3s ease-in-out;
          z-index: 1;
          display: block;
          top: 0;
          left: 0;
        }
		  }
		  &:hover {
  		  .special-teaser-image:after {
          background: transparent;
        }
      }
		}
    .field-name-title,
    .special-subhead,
    .field-group-div {
      position: relative;
      z-index: 1;
      color: $white;
		}
		.field-name-title {
  		position: absolute;
  		top: 50%;
  		left: 50%;
  		transform: translate(-50%, -50%);
  		width: 90%;
  		text-align: center;
  		h2 {
  			@include teaser-titles();
    		margin: 0;
    		a {
      		letter-spacing: .08em;
      		line-height: 1.2;
    		}
  		}
		}
		.group-promo-wrap {
  		left: 0;
  		.field-name-node-link {
    		display: block;
    		position: absolute;
    		z-index: 9;
    		bottom: 20px;
    		width: 100%;
    		left: 0px;
    		a {
				  @include vrweb-button($color: $white, $border-style: true);
				  background: $primary-color;
				  border: 4px solid $primary-color;
				  &:after {
  				  content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border: 1px solid #fff;
				  }
				  &:hover {
  				  color: $primary-color;
            border: 4px solid $white;
            &:after {
              border: 1px solid $primary-color;
            }
				  }
    		}
  		}
		}
    @media (min-width: 640px) and (max-width: 767px) {
      a, .field-name-node-link, .group-promo-wrap{
        position:absolute !important;
        top:0;
        left:0;
        right:0;
        bottom:0 !important;
      }
      a{
        opacity:0;
      }
    }
	}
}

// Layout for homepage slideshow headline

.l-featured {
  .block-views-49e6d0d869ee101168c5c0f5495cb03a {
    position: absolute;
    z-index: 15;
    bottom: 45%;
    width: 90%;
    margin: 0 5%;
    @media #{$medium} {
      bottom: 35%;
    }
    @media #{$small-down} {
      bottom: 130px;
    }
    @media #{$tiny-down} {
      bottom: 50px;
    }
    .view-homepage-hero-headlines {
      color: $white;
      text-align: center;
      text-transform: uppercase;
      text-shadow: 0 0 5px $black;
      .views-field-title {
        font-size: $h1-font-size;
        font-weight: 700;
        letter-spacing: .09em;
        @media #{$small-down} {
          font-size: 1.15em;
          letter-spacing: .06em;
        }
      }
      .views-field-field-homepage-hero-subtitle {
        font-weight: 400;
        letter-spacing: 0;
        font-size: $h4-font-size;
        @media #{$small-down} {
          font-size: 1em;
        }
      }
    }
  }
}
