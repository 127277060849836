//=============================================
//
//  Form styles.
//  Foundation Form Overrides and custom styles.

//  See _vrweb-settings for all form variables.
//-----------------------------------------------------------------//

// Docs: http://foundation.zurb.com/docs/components/forms.html

// Default styles for descriptions.
body {
  form .description {
    font-size: $form-description-size;
    font-style: $form-description-style;
    position: relative;
    font-family: $arial;
    color: $primary-color;
    padding-top: 5px;
  }

  .item-list .page,
  ul.pagination,
  .pagination-centered {
    clear: both;
    height: auto;
  }
  //
  // Fix for check boxes not centered, sets font to always use Helvetica.
  //

  form.custom {
    .custom.checkbox {
      font-family: $body-gf;
    }
  }

  ul.breadcrumbs {
    display: none;
  }

  label {
    font-size: $label-size;
    font-weight: $label-weight;
    font-style: $label-style;
    color: $label-color;
    min-height: $label-minheight;
    margin-bottom: $label-margin-bottom;

    .featured-tabs & {
      color: $featured-tabs-touch-label-color;

      @media #{$small} {
        color: $featured-tabs-label-color;
      }
    }
    .large-text & {
      font-size: $label-size-large;
    }
  }
  .label-inline {
    background: $transparent;
    color: $secondary-color;
    font-weight: 600;
    text-transform: uppercase;
    font-family: $body-gf;
    letter-spacing: .03em;
  }
  .secondary.label {
    background: $transparent;
  }

  // Global ResCMS calendar/search button implementation
  // See variables in the correct layout file (_vrweb_layout_a/b/or c.scss)

  @include datepicker-template($vravailforms, $vrcalendar-position);

  a {
    transition: color, .25s ease-in-out;
  }
  .field-name-body {
    a {
      color: $tertiary-color;
      text-decoration: none;
      transition-property: color, text-decoration;
      transition-duration: .25s;
      transition-timing-function: ease-in-out;

      &:hover, &:active {
        text-decoration: underline;
        color: $tertiary-color;
      }
    }
    a.button {
      color: $white;
      text-decoration: none;
      transition-property: all;
    }
    .button-group a {
      color: #000;
      border-radius: 0px;

      &:hover, &:active {
        color: #000;
        text-decoration: underline;
      }
    }
  }
  // Send by email form
  // i.e. /printmail/vacation-rentals/property-name

  .page-printmail-vacation-rentals {

    .form-item-fld-from-addr {
      @include grid-column(6);

      @media #{$tiny-down}{
        @include grid-column(12);
      }
    }

    .form-item-fld-from-name {
      @include grid-column(6);

      @media #{$tiny-down}{
        @include grid-column(12);
      }
    }

    .form-item-txt-to-addrs {
      @include grid-column(6);

      textarea#edit-txt-to-addrs {
        width: 100% !important;
        float: left;
      }
      .description {
        clear: both;
      }
      @media #{$tiny-down}{
        @include grid-column(12);

        .description {
          margin-bottom: 20px;
        }
      }
    }
    .form-item-fld-subject {
      @include grid-column(6);

      @media #{$tiny-down}{
        @include grid-column(12);
      }
    }

    .form-type-item {
      @include grid-column(12);
      padding: emCalc(20);

      label {
        @media #{$tiny-down}{
          width: 100%;
        }
      }
    }

    .form-item-txt-message {
      @include grid-column(12);
    }

    #edit-btn-submit {
      @include grid-column(2);
      float: left;
      clear: both;
      margin-left: 14px;
      min-width: 100px;
    }

    #edit-btn-cancel {
      @include grid-column(2);
      float: left;
      min-width: 100px;
      margin-left: emCalc(14);
      @media #{$tiny-down}{
        clear: both;
        margin-left: 14px;
      }
    }

    #edit-btn-clear {
      display: none;
    }
  }

  //============================
  // Custom Form elements
  //============================
  //Set global height, border, box shadow and radius for input field and selects
  //Remove webkit appearance

  input[type="checkbox"] {
    display: inline-block;
    margin-bottom: 0px;

    & + label.option {
      display: inline;
    }
  }
  input[type="text"], input[type="password"], input[type="email"], input[type="tel"], input[type="number"], select, .form-select, textarea {
    font-family: $head-gf;
    -webkit-appearance: none;
    /*replace these scss styles with css if needed*/
    @include box-shadow();
    border-radius: $global-radius;
    border: $form-component-border;
    background: $form-component-bg;
    height: $form-component-height;
    font-weight: 400;
    font-size: 14px;
    color: $input-font-color;
    margin-bottom: 0px;
    padding-left: 10px;
    letter-spacing: normal;

    #admin-menu & {
      height: inherit;
    }
  }

  select, .form-select, .form-select.has-tip {
    font-family: $head-gf;
    padding-right: 20px;
    background: $form-component-bg url("../images/form-icon-select.svg") right 7px center no-repeat;
    background-size: 24px;
    padding-left: 10px;
    font-weight: 400;
    border-color: $form-component-border;

    /*nix the right padding for versions of IE that generate the arrow*/
    background-size: 20px auto \9
  ;
    padding-right: 0px \9
  ;
    letter-spacing: normal;
  }

  //Remove arrow in IE11 and IE10
  select.form-select::-ms-expand, select::-ms-expand {
    display: none !important;
  }

  :focus {
    outline-color: $transparent;
    outline-style: none;
    box-shadow: none;
  }

  //Firefox display mode to remove the arrow, adjust padding as needed
  @-moz-document url-prefix() {
    select, .form-select {
      -webkit-appearance: none;
      -moz-appearance: none;
    }
  }
  #edit-field-inquiry-properties {
    .select2-container {
      width: 100% !important;
    }
    .select2-search__field {
      box-shadow: none !important;
    }
    .select2-selection.select2-selection--multiple {
      border: $form-component-border;
      background: $form-component-bg;
    }
  }

  .section-vacation-rental-inquiry {
    .select2-container--open .select2-dropdown--below {
      @media #{$medium} {
        margin-top: -30px;
      }
    }
  }
  @media #{$medium} {
    .logged-in {
      .select2-dropdown {
        overflow: hidden;
        //max-height:100px;
        .select2-results__options,
        .select2-results {
          //max-height:94px !important;
        }
      }
    }
  }
  body {
    .button-tiny {
      @include vrweb-button($size: 'tiny');
    }
    .button-small {
      @include vrweb-button($size: 'small');
    }
    .button-medium {
      @include vrweb-button($size: 'medium');
    }
    .button-large {
      @include vrweb-button($size: 'large');
    }
  }
  fieldset {
    border: none;
    padding: 0px;
  }
}
