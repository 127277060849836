@charset "UTF-8";

/**
 * @file
 * This file is a custom file that loads all files. Each non-base layer
 * can be disabled.
 *
 * Do not name this file, "app.scss". If you run a compass update this file can
 * be wiped out with a compass update. By default, when a compass project is
 * created the file will be named app.scss. Thus this file is named,
 * THEMENAME.scss.
 *
 * This application file (THEMENAME.scss) is where all the partials are
 * imported.
 *
 * Theme styles are categorized using SMACSS standards. They utilize
 * categorization of styles into various categories. Those categories are the
 * following:
 *
 * - Base: CSS reset/normalize plus HTML element styling.
 * - Layout: Macro arrangement of a web page, including any grid systems.
 * - Component: Dictate minor layout modules or reusable elements.
 * - State: Describe the appearance of a module in various states.
 * - Theme: Purely visual optional styling ("look-and-feel") for a component.
 *
 * For more information about this new Drupal css file standard, please review
 * the following:
 * - https://drupal.org/node/1887922
 * - http://smacss.com/
 */
// Base
//
// Init file contains required imports. Here's where we include normalize,
// foundation and also compass.
@import "base/init";
//
// Include mixins.
@import "base/mixins";
//
// Include common base styles.
@import "base/common";
//
// Persist certain Drupal core styles. This is necessary because we're stripping
// all core CSS and certain things just don't work as expected without it.
@import "base/drupal";
// Font stacks
@import "fonts/default";
@import "fonts/foundation-icons";
@import "fonts/social-icons";
@import "fonts/tiktok";
//
//
// VRweb Components
@import "components/typography";
@import "components/forms";
@import "components/top-bar";
@import "components/buttons";
@import "components/floating-tabs";
@import "components/social-icons";
@import "components/slideshows";
@import "components/sitemap";
@import "components/weather-widget";
@import "components/featured-rentals";
@import "components/featured-content";
@import "components/booking-calendars";
@import "components/rs-captions";
@import "components/section-tabs";
@import "components/triptych";
@import "components/featured-tabs";
@import "components/toggle-font";
@import "components/subnav-snippets";
@import "components/team-members";
@import "components/page-header";
@import "components/modals";
@import "components/rc-item-quote";
@import "components/testimonials";
@import "components/page_builder";

// Layout
//
// Each section of the document has it's own partial seperated out to improve
// developer experience. Additionally, grids can be declared in layouts as well.
// Layouts related to specific pages or sections of the site and should use
// variables wherever possible to make the styles as project-agnostic and
// customizable as possible.
@import "layout/homepage";
@import "layout/sidebar";
@import "layout/header";
@import "layout/featured";
@import "layout/rc_checkout";
@import "layout/vacation-rentals";
@import "layout/next-gen";
@import "layout/forms";
@import "layout/footer";
@import "layout/vr-grouping";
@import "layout/page";
@import "layout/blog-ttd-events";
@import "layout/broker";
@import "layout/specials";
//riot components
@import "riottags/riot";
@import "riottags/rc-riot-avail-filter";
@import "riottags/riot-solr-facet-group";
@import "riottags/rc-riot-order";
@import "riottags/riot-solr-flag-counter";
@import "riottags/vrw-search-list";
@import "riottags/riot-solr-sorts";
@import "riottags/riot-tags-class-toggle";
@import "riottags/riot-solr-result-list";
@import "riottags/riot-solr-range-slider";
@import "riottags/riot-solr-current-search";
@import "riottags/rc-riot-result-list-item-detailed";
@import "riottags/rc-riot-avail-pricing";
@import "riottags/riot-solr-search";
@import "riottags/rc-riot-result-list-item";
@import "riottags/riot-solr-result-map";
@import "riottags/simple-search";
// Last, include any client specific overrides or styles
@import "app";
