//
// Switch Variables
//
$include-html-form-classes: $include-html-classes !default;

// Controlling border styles and background colors for the switch container
$switch-border-color: darken(#fff, 20%) !default;
$switch-border-style: solid !default;
$switch-border-width: 1px !default;
$switch-bg: #fff !default;

// We use these to control the switch heights for our default classes
$switch-height-tny: 22px !default;
$switch-height-sml: 28px !default;
$switch-height-med: 36px !default;
$switch-height-lrg: 44px !default;
$switch-bottom-margin: em-calc(20) !default;

// We use these to control default font sizes for our classes.
$switch-font-size-tny: 11px !default;
$switch-font-size-sml: 12px !default;
$switch-font-size-med: 14px !default;
$switch-font-size-lrg: 17px !default;
$switch-label-side-padding: 6px !default;

// We use these to style the switch-paddle
$switch-paddle-bg: #fff !default;
$switch-paddle-fade-to-color: darken($switch-paddle-bg, 10%) !default;
$switch-paddle-border-color: darken($switch-paddle-bg, 35%) !default;
$switch-paddle-border-width: 1px !default;
$switch-paddle-border-style: solid !default;
$switch-paddle-transition-speed: .1s !default;
$switch-paddle-transition-ease: ease-out !default;
$switch-positive-color: lighten($success-color, 50%) !default;
$switch-negative-color: #f5f5f5 !default;

// Outline Style for tabbing through switches
$switch-label-outline: 1px dotted #888 !default;

//
// Switch Mixins
//

// We use this mixin to create the base styles for our switch element.
@mixin switch-base($transition-speed:$switch-paddle-transition-speed, $transition-ease:$switch-paddle-transition-ease) {

  // Default position and structure for switch container.
  position: relative;
  padding: 0;
  display: block;
  overflow: hidden;
  border-style: $switch-border-style;
  border-width: $switch-border-width;
  margin-bottom: $switch-bottom-margin;

  // Default label styles for type and transition
  label {
    position: relative;
    #{$default-float}: 0;
    z-index: 2;
    float: $default-float;
    width: 50%;
    height: 100%;
    margin: 0;
    font-weight: bold;
    text-align: $default-float;

    // Transition for the switch label to follow paddle
    @include single-transition(all, $transition-speed, $transition-ease);
  }

  // So that we don't need to recreate the form with any JS, we use the
  // existing radio button, but we cleverly position and hide it.
  input {
    position: absolute;
    z-index: 3;
    opacity: 0;
    width: 100%;
    height: 100%;
    -moz-appearance: none;

    // Hover and focus styles for the paddle
    &:hover,
    &:focus {
      cursor: $cursor-pointer-value;
    }
  }

  // The toggle area for radio switches. We call is a paddle.
  span:last-child {
    position: absolute;
    top: -1px;
    #{$default-float}: -1px;
    z-index: 1;
    display: block;
    padding: 0;
    border-width: $switch-paddle-border-width;
    border-style: $switch-paddle-border-style;

    // Transition for the switch paddle
    @include single-transition(all, $transition-speed, $transition-ease);
  }

  // When a label isn't :checked, we hide it as it slides away.
  input:not(:checked) + label {
    opacity: 0;
  }

  // Controlling the position of the labels as they are toggled.
  input:checked {
    display: none !important;
  }
  input {
    #{$default-float}: 0;
    display: block !important;
  }

  // Left Label alignment and position changes, including fixes for while inside a custom form
  input:first-of-type + label,
  input:first-of-type + span + label {
    #{$default-float}: -50%;
  }
  input:first-of-type:checked + label,
  input:first-of-type:checked + span + label {
    #{$default-float}: 0%;
  }

  // Right Label alignment and position changes, including fixes for while inside a custom form
  input:last-of-type + label,
  input:last-of-type + span + label {
    #{$opposite-direction}: -50%;
    #{$default-float}: auto;
    text-align: $opposite-direction;
  }
  input:last-of-type:checked + label,
  input:last-of-type:checked + span + label {
    #{$opposite-direction}: 0%;
    #{$default-float}: auto;
  }

  // Hiding custom form spans since we auto-create them
  span.custom {
    display: none !important;
  }

  // Bugfix for older Webkit, including mobile Webkit. Adapted from:
  // http://css-tricks.com/webkit-sibling-bug/
  @media only screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 480px) {
    @if $experimental {
      -webkit-animation: webkitSiblingBugfix infinite 1s;
    }
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
    @if $experimental {
      -webkit-animation: none 0;
    }
  }

  form.custom & .hidden-field {
    margin-left: auto;
    position: absolute;
    visibility: visible;
  }
}

// We use this mixin to create the size styles for switches.
@mixin switch-size($height:$switch-height-med, $font-size:$switch-font-size-med, $line-height:2.3em) {

  height: em-calc($height);

  label {
    padding: em-calc(0, $switch-label-side-padding);
    line-height: $line-height;
    font-size: em-calc($font-size);
  }

  input {
    // Move the paddle to the right position
    &:first-of-type:checked ~ span:last-child {
      #{$default-float}: 100%;
      margin-#{$default-float}: em-calc(-$height + 1px);
    }
  }

  span:last-child {
    width: em-calc($height);
    height: em-calc($height);
  }

}

// We use this mixin to add color and other fanciness to the switches.
@mixin switch-style($paddle-bg:$switch-paddle-bg, $positive-color:$switch-positive-color, $negative-color:$switch-negative-color, $radius:false, $base-style:true) {

  @if $base-style {
    background: $switch-bg;
    border-color: $switch-border-color;

    span:last-child {
      border-color: darken($paddle-bg, 30%);
      background: $paddle-bg;
      @if $experimental {
        background: -moz-linear-gradient(top, $paddle-bg 0%, darken($paddle-bg, 5%) 100%);
        background: -webkit-linear-gradient(top, $paddle-bg 0%, darken($paddle-bg, 5%) 100%);
      }
      background: linear-gradient(to bottom, $paddle-bg 0%, darken($paddle-bg, 5%) 100%);

      // Building the alternating colored sides of the switch
      @if $experimental {
        -webkit-box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.07),
        1000px 0 0 1000px $positive-color,
        -2px 0 10px 0 rgba(0, 0, 0, 0.07),
        -1000px 0 0 1000px $negative-color;
      }
      box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.07),
      1000px 0 0 980px $positive-color,
      -2px 0 10px 0 rgba(0, 0, 0, 0.07),
      -1000px 0 0 1000px $negative-color;
    }

    &:hover,
    &:focus {
      span:last-child {
        background: $paddle-bg;
        @if $experimental {
          background: -moz-linear-gradient(top, $paddle-bg 0%, darken($paddle-bg, 10%) 100%);
          background: -webkit-linear-gradient(top, $paddle-bg 0%, darken($paddle-bg, 10%) 100%);
        }
        background: linear-gradient(to bottom, $paddle-bg 0%, darken($paddle-bg, 10%) 100%);
      }
    }

    &:active {
      background: transparent;
    }
  }

  // Setting up the radius for switches
  @if $radius == true {
    @include radius(4px);
    span:last-child {
      @include radius(3px);
    }
  } @else if $radius {
    @include radius($radius);
    span:last-child {
      @include radius($radius - 1px);
    }
  }

}

// We use this to quickly create switches with a single mixin
@mixin switch($transition-speed:$switch-paddle-transition-speed, $transition-ease:$switch-paddle-transition-ease, $height:$switch-height-med, $font-size:$switch-font-size-med, $line-height:2.3em, $paddle-bg:$switch-paddle-bg, $positive-color:$switch-positive-color, $negative-color:$switch-negative-color, $radius:false, $base-style:true) {
  @include switch-base($transition-speed, $transition-ease);
  @include switch-size($height, $font-size, $line-height);
  @include switch-style($paddle-bg, $positive-color, $negative-color, $radius, $base-style);
}

@if $include-html-form-classes != false {

  /* Foundation Switches */
  @media only screen {

    // Containing element for the radio switch
    div.switch {
      @include switch;

      // Large radio switches
      &.large {
        @include switch-size($switch-height-lrg, $switch-font-size-lrg);
      }

      // Small radio switches
      &.small {
        @include switch-size($switch-height-sml, $switch-font-size-sml, 2.1em);
      }

      // Tiny radio switches
      &.tiny {
        @include switch-size($switch-height-tny, $switch-font-size-tny, 1.9em);
      }

      // Add a radius to the switch
      &.radius {
        @include radius(4px);
        span:last-child {
          @include radius(3px);
        }
      }

      // Make the switch completely round, like a pill
      &.round {
        @include radius(1000px);
        span:last-child {
          @include radius(999px);
        }
        label {
          padding: em-calc(0 $switch-label-side-padding + 3);
        }
      }

    }

    @if $experimental {
      @-webkit-keyframes webkitSiblingBugfix {
        from {
          position: relative;
        }
        to {
          position: relative;
        }
      }
    }
  }
}
