//Global Styles for Search Pages
body[class*="page-riottags"][class*="vrw-favorites"],
body[class*="page-riottags"][class*="vrw-specials"],
body[class*="page-riottags"][class*="vrw-search"], 
body.page-taxonomy-term:not(.blog-ttd-events-page) {
  #page-title {
    text-align: center;
  }

  .taxonomy-term-description{
    max-width:1000px;
    padding:0 15px;
    margin:5px auto;
  }
        
  .highlight {
    position: relative;
    @media #{$small} {
      top: -60px;
    }
  }

  .main.columns {
    padding: 0;
  }
  .l-main {
    padding-bottom: 0;
    max-width: none;
  }
}

body[class*="page-riottags"][class*="vrw-favorites"],
body[class*="page-riottags"][class*="vrw-specials"],
body[class*="page-riottags"][class*="vrw-search"],
body.node-type-special.section-special,
body.node-type-vr-listing.unit-details-page,
body.page-taxonomy-term {
  .reveal-modal {
    padding: 10px;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
}