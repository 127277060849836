$rcquote-cell-bg: $white;
$rcquote-cell-alt-bg: $muted-color;
$rcquote-table-border-color: $muted-color-alt;

.rc-item-quote {
  .reveal-modal & {
    table {
      width: 100%;

      th,
      td {
        background: $rcquote-cell-bg;
      }

      tr.even {
        th,
        td {
          background: $rcquote-cell-alt-bg;
        }
      }
      &, * {
        border-color: $rcquote-table-border-color;
      }
    }

    .rc-item-price-buy {
      //margin-bottom:0;

      button {
        margin: 0 auto;
        @include vrweb-button($book-button-bg);
        display: block;

        &:hover,
        &:active {
          background-color: $book-button-bg-hover;
        }
      }
    }
  }
}