//
// Block Grid Variables
//
$include-html-grid-classes: $include-html-classes !default;

// We use this to control the maximum number of block grid elements per row
$block-grid-elements: 12 !default;
$block-grid-default-spacing: em-calc(20) !default;

// Enables media queries for block-grid classes. Set to false if writing semantic HTML.
$block-grid-media-queries: true !default;

//
// Block Grid Mixins
//

// We use this mixin to create different block-grids. You can apply per-row and spacing options.
// Setting $base-style to false will ommit default styles.
@mixin block-grid($per-row:false, $spacing:$block-grid-default-spacing, $base-style:true) {

  @if $base-style {
    display: block;
    padding: 0;
    margin: 0 (-$spacing/2);
    @include clearfix;

    & > li {
      display: inline;
      height: auto;
      float: $default-float;
      padding: 0 ($spacing/2) $spacing;
    }
  }

  @if $per-row {
    & > li {
      width: 100%/$per-row;
      padding: 0 ($spacing/2) $spacing;

      &:nth-of-type(n) {
        clear: none;
      }
      &:nth-of-type(#{$per-row}n+1) {
        clear: both;
      }
    }
  }

}

@if $include-html-grid-classes {
  /* Foundation Block Grids for below small breakpoint */
  @media only screen {
    [class*="block-grid-"] {
      @include block-grid;
    }

    @for $i from 1 through $block-grid-elements {
      .small-block-grid-#{($i)} {
        @include block-grid($i, $block-grid-default-spacing, false);
      }
    }
  }

  /* Foundation Block Grids for above small breakpoint */
  @media #{$small} {
    /* Remove small grid clearing */
    @for $i from 1 through $block-grid-elements {
      .small-block-grid-#{($i)} > li:nth-of-type(#{$i}n+1) {
        clear: none;
      }
    }
    @for $i from 1 through $block-grid-elements {
      .large-block-grid-#{($i)} {
        @include block-grid($i, $block-grid-default-spacing, false);
      }
    }
  }
}
