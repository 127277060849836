/** //==================================
*
*    GLOBAL THEME OVERRIDES
*    Styles and Variables
*
**/
//===================================

/**
* 	RC RIOT AVAIL FILTER
**/

/**
* 
*	Homepage Quick Search
*	Change to 'true' to remove spaces between 
*  form components on desktop display
*/

$qs-display-flush: true !default;

/** //======================================
* 	SUBMIT BUTTON COLORS
**/
$submit-bg: $primary-color !default;
$submit-bg-hover: $success-color !default;
$submit-bg-desktop: $success-color !default;
$submit-bg-hover-desktop: darken($primary-color, 1%) !default;

/** //======================================
* 	SEARCH WIDGET COLOR THEME
**/
$search-list-widget-bg: $muted-color;
$search-list-widget-bg-desktop: $muted-color;

/** //======================================
* 	RANGE SLIDER COLOR THEME
**/
$ranges-selected-bg: $tertiary-color;
$ranges-not-selected-bg: darken($muted-color, 10%);

$ranges-selected-bg-desktop: $ranges-selected-bg;
$ranges-not-selected-bg-desktop: darken($muted-color, 20%);

/** //======================================
* MAP VARIABLES
**/
// Must be tall enough to accomodate vertical height of the search widget for $small
// Make taller if the sidebar search is getting cut off at the bottom
$map-min-height-desktop: 600px;

/** //======================================
* 	RIOT TAGS CLASS TOGGLE VARIABLES
*/

//------------MOBILE TOGGLES----------------

// default color
$toggle-link-color: $primary-color;
$toggle-link-color-hover: $secondary-color;

// default background
$toggle-link-bg: $muted-color;
$toggle-link-bg-hover: $muted-color;

// default icon
$toggle-icon-color: $toggle-link-color;
$toggle-icon-color-hover: $toggle-link-color-hover;

// default border
$toggle-border-color: $muted-color-alt;

// active color
$toggle-active-link-color: $black;

// active background
$toggle-active-link-bg: $white;

// active icon
$toggle-active-icon-color: $toggle-active-link-color;

//------------DESKTOP TOGGLES-----------------

// default color
$toggle-link-color-desktop: $gray;
$toggle-link-color-hover-desktop: $toggle-link-color-hover;

// default bg
$toggle-link-bg-desktop: $muted-color;
$toggle-link-bg-hover-desktop: $toggle-link-bg-hover;

// default icon
$toggle-icon-color-desktop: $toggle-icon-color;
$toggle-icon-color-hover-desktop: $toggle-icon-color-hover;

// default border 
$toggle-border-color-desktop: $toggle-border-color;

// active color
$toggle-active-link-color-desktop: $toggle-active-link-color;

// active bg
$toggle-active-link-bg-desktop: $toggle-active-link-bg;

//active icon color
$toggle-active-icon-color-desktop: $toggle-active-icon-color;

/** //======================================
*  	RC RIOT CURRENT SEARCH VARIABLES
*/
$current-search-item-bg: $secondary-color;
$current-search-item-border-color: $current-search-item-bg;
$current-search-item-color: $white;
$current-search-icon-color: $white;

$current-search-item-bg-desktop: $secondary-color;
$current-search-item-border-color-desktop: $current-search-item-bg-desktop;
$current-search-item-color-desktop: $white;
$current-search-icon-color-desktop: $white;

$current-search-count-color: $secondary-color;
$current-search-count-color-desktop: $secondary-color;
$current-search-modal-bg: $muted-color;

/** //======================================
* 	VR TEASER LIST VARIABLES
**/
$teaser-border-color: $muted-color;
$list-teaser-bg: $muted-color;
$list-teaser-bg-desktop: none;

/** //======================================
* 	VR SEARCH TRIGGER BUTTON VARIABLES
**/
$trigger-button-color: $tertiary-color !default;
$trigger-button-color-hover: $black;
$trigger-button-color-active: $tertiary-color !default;
$trigger-mobile-search: true !default;
$trigger-trigger-height: 50px !default;
$mobile-expanded-menu-height: 297px !default;

/** //======================================
* 	RC-RIOT-RESULT-LIST-ITEM VARIABLES
**/

//The percentage of padding set to image link wrappers in which images are absolutely positioned for consistent height
$result-list-item-image-padding-ratio: 65%;

//The background color used on the image link wrapper when no image exists
$result-list-item-noimg-bg: $muted-color-alt;

//Default background color for result list items
$result-list-item-bg: $muted-color;

//Default active background color, when you hover over an result item
$result-list-item-active-bg: $muted-color-alt;

// Default active background color for the pricing block, when you hover over a result item
$result-list-item-pricing-active-bg: $muted-color-alt;

/** //======================================
* 	VR SEARCH PRICING VARIABLES
**/

//Global text color for prices
$original-price-text-color: $primary-color;
$pricing-text-color: $success-color;
$pricing-text-font-weight: 600;

//global common button
//book now button color theme
$book-button-bg: $success-color;
$book-button-bg-hover: $tertiary-color;

//ad to cart button color theme
$add-button-bg: $primary-color;
$add-button-bg-hover: $tertiary-color;

// Show detailed quote color theme
$quote-button-bg: $black;
$quote-button-bg-hover: $tertiary-color;
$quote-text-link-color: $black;

// alternative color for pricing,
// used when we have multi-step calls to action to book
// and we need to differentiate styles
$book-button-alt-bg: $tertiary-color;
$book-button-alt-bg-hover: $black;
$alt-pricing-color: $secondary-color;

//Search List
$search-list-item-pricing-bg: darken($muted-color, 5%);
$search-list-item-pricing-bg-desktop: $muted-color;

//Grid View
$grid-view-item-pricing-bg: $search-list-item-pricing-bg;
$grid-view-item-price-bg: $white;

//Summary Trigger Button, says "View Options" by default
$trigger-text-link-color: $black;
$trigger-text-link-color-hover: $success-color;
$trigger-icon-color: $success-color;
$trigger-icon-color-hover: $success-color;

//Specials Pricing color
$display-name-color: $black;
$display-detail-color: $primary-color;
$savings-color: $success-color;

/** //======================================
* 	RC RIOT ORDER
**/

//Order Modal Typography Variables
$rcorder-dates-color: $black;
$rcorder-dates-weight: 600;

$rcorder-display-name-color: $black;
$rcorder-display-name-weight: 600;
//$rcorder-display-name-border-color:$muted-color;

$rcorder-display-detail-color: $primary-color;

$rcorder-travelers-color: $primary-color;
$rcorder-travelers-weight: 400;

// Share button color theme
$share-button-bg: $primary-color;
$share-button-bg-hover: $black;

// Search Accom Button
$search-accom-border-color: $black;
$search-accom-icon-color: $white;

$search-accom-bg: $secondary-color;
$search-accom-bg-hover: $black;

$search-accom-color: $white;
$search-accom-color-hover: $white;
//====================================