//============================
// Social Icons - Footer
//============================

.vrweb-social {
  margin: 0;
  display: inline-block;

  > li {
    margin-left: 0;
    margin-right: .5em;

    @media #{$tiny-down}{
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }

  @media #{$tiny-down}{
    li.first {
      margin-left: 0px;
    }
  }
}

.twitter,
.facebook,
.google-plus,
.linkedin,
.youtube,
.instagram,
.pinterest {
	a {
		font: 0/0 sans-serif;
		&:before {
			@include icon(social-icons);
			font-size: 40px;
			padding: 0px;
			color: $white;
			transition: color 300ms ease-in-out;
		}
		&:hover {
			&:before {
				text-decoration: none;
				transition: color 300ms ease-in-out;
			}
		}
	}
}

// Icons
a:before {

  .twitter & {
    content: "\e604";
  }

  .facebook & {
    content: "\e605";
  }

  .youtube & {
    content: "\f167";
  }

  .linkedin & {
    content: "\e609";
  }

  .google-plus & {
    content: "\e606";
  }

  .pinterest & {
    content: "\e607";
  }

  .instagram & {
    content: "\e60f";
  }
}

// Icon colors
a:hover:before {

  .twitter & {
    color: #4099ff;
  }

  .facebook & {
    color: #3b5998;
  }

  .youtube & {
    color: #bb0000;
  }

  .linkedin & {
    color: #007bb6;
  }

  .google-plus & {
    color: #dd4b39;
  }

  .pinterest & {
    color: #cb2027;
  }

  .instagram & {
    color: #517fa4;
  }
}


