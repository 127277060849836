.vrfusion-rs-caption {
  position: absolute;
  top: 0;
  left: 0;
  height: emCalc(40);
  width: 100%;
  color: $white;
  background: #D0D0D0;
  background: rgba(0, 0, 0, 0.5);
  padding: 0 emCalc(10);
  line-height: emCalc(40);
}