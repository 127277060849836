//Homepage Featured Properties grid styles

.block-views-d7fb3a93163bfe4c1d91f19e8effd379 {
  clear: both;
}

.view-display-id-block_homepage_featured {
  background-color: $muted-color;
  box-shadow: 0px 3px 20px #0000000D;
  padding-top: 40px;
  padding-bottom: 35px;

  h2 {
    text-transform: none;
    font-style: italic;
    margin-top: .4em;
    letter-spacing: 0;
  }

  @media #{$medium-down} {
    padding-top: 30px;
    padding-bottom: 25px;
  }
  div.view-header {
    text-align: center;

    h2 {
      margin-bottom: 40px;
      text-transform: none;
    }
  }
  .view-content {
    @include grid-row();
    padding-left: 15px;
    padding-right: 15px;
  }
  .views-row {
    @include grid-column(12);
    @include min(both);

    @media only screen and (min-width: emCalc(600)) {
      @include grid-column(4);
      @include grid-clear(3);
    }
  }
  riot-solr-flag,
  [riot-tag="riot-solr-flag"],
  [data-is="riot-solr-flag"] {
	  top: 7px;
	  right: 7px;
  }
}

.result-list,
.view-display-id-block_homepage_featured {
  riot-solr-flag {
    background-color: transparent;

    .flag-inactive i,
    .flag-active i {
      background: $muted-color-alt url(/sites/all/themes/custom/vrweb_foundation/images/icon-favorite-toggle-off.svg) center 3px no-repeat;
      background-size: 35px 35px;
      width: 34px;
      height: 34px;
      opacity: .9;
      border-radius: 100%;
      cursor: pointer;
    }

    .flag-active i  {
      background-image: url(/sites/all/themes/custom/vrweb_foundation/images/icon-favorite-toggle-on.svg);
    }
  }
}

//Teaser Images
.group-favorites-image-wrapper {
  position: relative;
  @extend %no-label;

  .rc-core-images {
    display: block;
    position: relative;
    width: 100%;
    height: 0px;
    padding-top: 65%;
    overflow: hidden;
    background: $muted-color;

    img {
      display: block;
      min-width: 102%;
      height: auto;
      @include absolute-center($left: -1%, $right: -1%);
    }
  }

  .rc-item-rating {
    background: rgba($black, .6);
    z-index: 1;
    padding: 10px 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: $white;

    .rc-item-rating-value {
      font-size: 14px;
      padding-right: .5em;
    }

    .rc-item-rating-stars {
      margin-bottom: 0;
      padding: 0;
      color: $white;
      display: inline-block;
      float: none;
      margin-right: 5px;
      line-height: 1;

      @include mq(range, 450, 700) {
        flex-basis: 100%;
      }
    }

    .rc-item-rating-detail {
      font-size: 14px;
    }
  }

  .vrweb-collection-link {
    z-index: +1;
    top: -8px;
    right: -10px;

    @if $favorites-style == 'icon' {
      top: 0;
      right: 0;
    }

  }
}

.view-mode-featured_rental {
  margin-bottom: 20px;

  .group-content {
    @include min(both);
    box-shadow: 0px 3px 20px #0000000D;
    text-align: center;
    padding-bottom: 10px;
    background: $muted-color;
    color: $primary-color;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0px;
    .item-list {
	    list-style: none;
	    margin: 0 4px;
	    li {
		    color: $primary-color;
        font-size: 16px;
	    }
    }
  }
  .group-cat-wrapper {
    .rc-core-cat {
      display: inline-block;
      @media #{$small-down} {
        display: block;
      }
    }
  }
  .rc-core-cat-evrn_client_20 {
    li.first {
      &:after {
        content: ",";
        @media (min-width: 420px) and (max-width: 767px) {
          content: "";
        }
      }
    }
  }
  .field-name-title {
    padding-left: 5px;
    padding-right: 5px;
  }
  .title {
    @include teaser-titles();
    margin-top: 20px;
    margin-bottom: 9px;
    @media (min-width: 420px) and (max-width: 550px) {
      margin-bottom: 6px;
    }
    a {
      text-decoration: none;
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: .2px;
      font-style: italic;
      color: $secondary-color;
      text-transform: none;
      &:hover {
        text-decoration: underline;
        color: $secondary-color;
      }
      @media (min-width: 420px) and (max-width: 550px) {
        line-height: 1;
      }
    }
  }

  .rc-item-pricing {
    @include grid-column(12);
    margin-bottom: 10px;
    margin-top: 5px;

    .rc-item-price {
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
  .rc-lodging-detail {
    display: inline-block;
    width: auto;
    line-height: 1.0;
    padding-left: 8px;
    padding-right: 8px;

    @media only screen and (min-width: emCalc(420)) and (max-width: emCalc(767)) {
      border-left: none !important;
      line-height: 1.4;
    }
  }

  .rc-lodging-detail + .rc-lodging-detail {
    border-left: solid 1px $primary-color;
  }

  @media #{$small-down} {
    .field-name-title {
      padding-left: 5px;
      padding-right: 5px;
    }

    .title {
      margin-top: 5px;
      margin-bottom: 3px;
      a {
        line-height: 20px;
        font-size: 16px;
      }
    }

    .rc-core-cat-evrn_client_21,
    .group-cat-wrapper {
      li {
        line-height: 1.25;
      }
    }
    .rc-lodging-detail {
      line-height: 1.25;
    }
  }

  @media only screen and (min-width: emCalc(420)) and (max-width: emCalc(599)) {
    .group-favorites-image-wrapper {
      @include grid-column(6);
      @include min(both);

      .vrweb-collection-link {
        right: auto;
        left: -10px;

        @if $favorites-style == 'icon' {
          left: 0;
        }
      }
    }

    .group-content {
      padding-bottom: 0px;
    }
    .rc-item-pricing,
    .field-name-title {
      @include grid-column(6);
      float: right;
    }
    .rc-item-pricing {
      clear: right;
    }
    .rc-lodging-detail {
      @include grid-column(6);
      float: right;
    }
  }

  @media only screen and (min-width: emCalc(600)) and (max-width: emCalc(767)) {
    .rc-lodging-detail {
      @include grid-column(12);
    }
  }
}
