/**
 * Brokers Styles.
 */

/**
 * Variables.
 */
$variable1: 'content1';
$variable2: 'content2';

/**
 * View Mode: Full.
 */
.node-broker.view-mode-full {
  .field-name-field-bio {
    line-height: 1.4;
  }
  .field-name-field-email {
    padding: 1em 0;
    font-weight: bold;
  }
  .field-name-field-phone {
    font-size: 1.5em;
  }
}

/**
 * View Mode: Teaser.
 */
.node-broker.view-mode-teaser {

}

/**
 * View name: Meet the Team
 *
 * Display id: page_1
 */
.view-meet-the-team.view-display-id-page_1 {
  .view-content {
    @include grid-row(nest);
    .views-row {

      @include grid-column(12);

      @media #{$small} {
        @include grid-column(6);
      }

      @media #{$medium} {
        @include grid-column(4);
      }
    }
  }
}