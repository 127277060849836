//
// Global Foundation Mixins
//

$experimental: true !default;

// We use this to control border radius.
@mixin radius($radius:$global-radius) {
  @if $radius {
    @if $experimental {
      -webkit-border-radius: $radius;
    }
    border-radius: $radius;
  }
}

// We use this to create equal side border radius on elements.
@mixin side-radius($side, $radius:$global-radius) {
  @if $side == left {
    @if $experimental {
      -moz-border-radius-bottomleft: $radius;
      -moz-border-radius-topleft: $radius;
      -webkit-border-bottom-left-radius: $radius;
      -webkit-border-top-left-radius: $radius;
    }
    border-bottom-left-radius: $radius;
    border-top-left-radius: $radius;
  } @else if $side == right {
    @if $experimental {
      -moz-border-radius-topright: $radius;
      -moz-border-radius-bottomright: $radius;
      -webkit-border-top-right-radius: $radius;
      -webkit-border-bottom-right-radius: $radius;
    }
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  } @else if $side == top {
    @if $experimental {
      -moz-border-radius-topright: $radius;
      -moz-border-radius-topleft: $radius;
      -webkit-border-top-right-radius: $radius;
      -webkit-border-top-left-radius: $radius;
    }
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
  } @else if $side == bottom {
    @if $experimental {
      -moz-border-radius-bottomright: $radius;
      -moz-border-radius-bottomleft: $radius;
      -webkit-border-bottom-right-radius: $radius;
      -webkit-border-bottom-left-radius: $radius;
    }
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}

// We can control whether or not we have inset shadows edges.
@mixin inset-shadow($active:true) {
  @if $experimental {
    -webkit-box-shadow: $shiny-edge-size $shiny-edge-color inset;
  }
  box-shadow: $shiny-edge-size $shiny-edge-color inset;

  @if $active {
    &:active {
      @if $experimental {
        -webkit-box-shadow: $shiny-edge-size $shiny-edge-active-color inset;
      }
      box-shadow: $shiny-edge-size $shiny-edge-active-color inset;
    }
  }
}

// We use this to add transitions to elements
@mixin single-transition($property:all, $speed:300ms, $ease:ease-out) {
  @if $experimental {
    -webkit-transition: $property $speed $ease;
    -moz-transition: $property $speed $ease;
  }
  transition: $property $speed $ease;
}

// We use this to add box-sizing across browser prefixes
@mixin box-sizing($type:border-box) {
  @if $experimental {
    -moz-box-sizing: $type;
    -webkit-box-sizing: $type;
  }
  box-sizing: $type;
}

// We use this to create equilateral triangles
@mixin css-triangle($triangle-size, $triangle-color, $triangle-direction) {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: inset $triangle-size;
  @if ($triangle-direction == top) {
    border-color: $triangle-color transparent transparent transparent;
    border-top-style: solid;
  }
  @if ($triangle-direction == bottom) {
    border-color: transparent transparent $triangle-color transparent;
    border-bottom-style: solid;
  }
  @if ($triangle-direction == left) {
    border-color: transparent transparent transparent $triangle-color;
    border-left-style: solid;
  }
  @if ($triangle-direction == right) {
    border-color: transparent $triangle-color transparent transparent;
    border-right-style: solid;
  }
}

// We use this to do clear floats
@mixin clearfix() {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// We use this to add a glowing effect to block elements
@mixin block-glowing-effect($selector:focus, $fade-time:300ms, $glowing-effect-color:fade-out($primary-color, .25)) {
  @if $experimental {
    -webkit-transition: -webkit-box-shadow $fade-time, border-color $fade-time ease-in-out;
    -moz-transition: -moz-box-shadow $fade-time, border-color $fade-time ease-in-out;
  }
  transition: box-shadow $fade-time, border-color $fade-time ease-in-out;

  &:#{$selector} {
    @if $experimental {
      -webkit-box-shadow: 0 0 5px $glowing-effect-color;
      -moz-box-shadow: 0 0 5px $glowing-effect-color;
    }
    box-shadow: 0 0 5px $glowing-effect-color;
    border-color: $glowing-effect-color;
  }
}

//
// Foundation Variables
//

// The default font-size is set to 100% of the browser style sheet (usually 16px)
// for compatibility with brower-based text zoom or user-set defaults.

// Since the typical default browser font-size is 16px, that makes the calculation for grid size.
// If you want your base font-size to be different and not have it effect the grid breakpoints,
// set $em-base to $base-font-size and make sure $base-font-size is a px value.
$base-font-size: 100% !default;

// $base-line-height is 24px while $base-font-size is 16px
$base-line-height: 150% !default;

// This is the default html and body font-size for the base em value.
$em-base: 16px !default;

// It strips the unit of measure and returns it
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// New Syntax, allows to optionally calculate on a different base value to counter compounding effect of em's.
// Call with 1, 2, 3 or 4 parameters, 'px' is not required but supported
// em-calc(10 20 30px 40);
// Space delimited, if you want to delimit using comma's, wrap it in another pair of brackets
// em-calc((10, 20, 30, 40px));
// Optionally call with a different base (eg: 8px) to calculate em.
// em-calc(16px 32px 48px, 8px);
// If you require to comma separate your list
// em-calc((16px, 32px, 48), 8px);

@function convert-to-em($value, $base-value: $em-base) {
  $value: strip-unit($value) / strip-unit($base-value) * 1em;
  @if ($value == 0em) {
    $value: 0;
  }
  // Turn 0em into 0
  @return $value;
}

@function em-calc($values, $base-value: $em-base) {
  $max: length($values);

  @if $max == 1 {
    @return convert-to-em(nth($values, 1), $base-value);
  }

  $emValues: ();
  @for $i from 1 through $max {
    $emValues: append($emValues, convert-to-em(nth($values, $i), $base-value));
  }
  @return $emValues;
}

// Support for the old syntax

@function emCalc($values) {
  @return em-calc($values);
}

// Maybe you want to create rems with pixels
// $rem-base: 0.625 !default; //Set the value corresponding to body font size. In this case, you should set as: body {font-size: 62.5%;}
// @function remCalc($pxWidth) {
//   @return $pxWidth / $rem-base * 1rem;
// }

// We use these to control various global styles
$body-bg: #fff !default;
$body-font-color: #222 !default;
$body-font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif !default;
$body-font-weight: normal !default;
$body-font-style: normal !default;

// We use this to control font-smoothing
$font-smoothing: subpixel-antialiased !default;

// We use these to control text direction settings
$text-direction: ltr !default;

// NOTE: No need to change this conditional statement, $text-direction variable controls it all.
$default-float: left !default;
$opposite-direction: right !default;
@if $text-direction == ltr {
  $default-float: left;
  $opposite-direction: right;
} @else {
  $default-float: right;
  $opposite-direction: left;
}

// We use these as default colors throughout
$primary-color: #2ba6cb !default;
$secondary-color: #e9e9e9 !default;
$alert-color: #c60f13 !default;
$success-color: #5da423 !default;

// We use these to make sure border radius matches unless we want it different.
$global-radius: 3px !default;
$global-rounded: 1000px !default;

// We use these to control inset shadow shiny edges and depressions.
$shiny-edge-size: 0 1px 0 !default;
$shiny-edge-color: rgba(#fff, .5) !default;
$shiny-edge-active-color: rgba(#000, .2) !default;

// We use this to control whether or not CSS classes come through in the gem files.
$include-html-classes: true !default;
$include-print-styles: true !default;
$include-html-global-classes: $include-html-classes !default;

// Media Queries
$small-screen: 768px !default;
$medium-screen: 1280px !default;
$large-screen: 1440px !default;

$screen: "only screen" !default;
$small: "only screen and (min-width: #{$small-screen})" !default;
$medium: "only screen and (min-width:#{$medium-screen})" !default;
$large: "only screen and (min-width:#{$large-screen})" !default;
$landscape: "only screen and (orientation: landscape)" !default;
$portrait: "only screen and (orientation: portrait)" !default;

// Used to provide media query values for javascript components.
meta.foundation-mq-small {
  font-family: $small;
  width: $small-screen;
}

meta.foundation-mq-medium {
  font-family: $medium;
  width: $medium-screen;
}

meta.foundation-mq-large {
  font-family: $large;
  width: $large-screen;
}

//We use this as cursors values for enabling the option of having custom cursors in the whole site's stylesheet
$cursor-crosshair-value: crosshair !default;
$cursor-default-value: default !default;
$cursor-pointer-value: pointer !default;
$cursor-help-value: help !default;
$cursor-text-value: text !default;

@if $include-html-global-classes {

  // Set box-sizing globally to handle padding and border widths
  *,
  *:before,
  *:after {
    @include box-sizing(border-box);
  }

  html,
  body {
    font-size: $base-font-size;
  }

  // Default body styles
  body {
    background: $body-bg;
    color: $body-font-color;
    padding: 0;
    margin: 0;
    font-family: $body-font-family;
    font-weight: $body-font-weight;
    font-style: $body-font-style;
    line-height: 1; // Set to $base-line-height to take on browser default of 150%
    position: relative;
    cursor: $cursor-default-value;
  }

  a:hover {
    cursor: $cursor-pointer-value;
  }

  // Grid Defaults to get images and embeds to work properly
  img,
  object,
  embed {
    max-width: 100%;
    height: auto;
  }

  object,
  embed {
    height: 100%;
  }
  img {
    -ms-interpolation-mode: bicubic;
  }

  #map_canvas,
  .map_canvas {
    img,
    embed,
    object {
      max-width: none !important;
    }
  }

  // Miscellaneous useful HTML classes
  .left {
    float: left !important;
  }
  .right {
    float: right !important;
  }
  .text-left {
    text-align: left !important;
  }
  .text-right {
    text-align: right !important;
  }
  .text-center {
    text-align: center !important;
  }
  .text-justify {
    text-align: justify !important;
  }
  .hide {
    display: none;
  }

  // Font smoothing
  // Antialiased font smoothing works best for light text on a dark background.
  // Apply to single elements instead of globally to body.
  // Note this only applies to webkit-based desktop browsers on the Mac.
  .antialiased {
    -webkit-font-smoothing: antialiased;
  }

  // Get rid of gap under images by making them display: inline-block; by default
  img {
    display: inline-block;
    vertical-align: middle;
  }

  //
  // Global resets for forms
  //

  // Make sure textarea takes on height automatically
  textarea {
    height: auto;
    min-height: 50px;
  }

  // Make select elements 100% width by default
  select {
    width: 100%;
  }

}
