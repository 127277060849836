riot-solr-flag-counter,
[riot-tag="riot-solr-flag-counter"],
[data-is="riot-solr-flag-counter"] {

  //global
  section a i {
    display: block;
    width: 20px;
    background: url("../images/icon-heart-service.svg") center no-repeat;
    float: left;
    height: 20px;
  }

  .flag-count {
    float: left;

    &:before {
      content: '(';
    }

    &:after {
      content: ')';
    }
  }

  //topbar link
  .top-bar & {

    @media #{$medium-down} {
      section {

        a,
        a i,
        .flag-count {
          height: $topbar-touch-height;
          line-height: $topbar-touch-height;
        }
        i {
          display: block;
          width: 30px;
          background: url("../images/icon-detail-favorite.svg") center no-repeat;
          background-size: 24px;
          float: left;
          height: 30px !important;
          margin-top: 17px;
          margin-right: 3px;
        }

        a {
          display: block;

          .favorites-label {
            display: none;
          }

          .flag-count {
            color: $muted-color;
            line-height: $topbar-touch-height;
          }
        }
      }
    }
  }

  //service bar link
  .l-service-bar & {
    @media #{$medium} {
      section {
        a {
          color: $service-bar-link-color;

          &:hover,
          &:active {
            color: $service-bar-link-color-hover;
          }

          .flag-count {
            margin-left: 2px;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
