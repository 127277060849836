.page-specials {
  #page-title {
    text-align: center;
  }
}

//Specials Landing Page Grid Styles
.view-specials.view-display-id-page_specials {
  .view-header {

    .description {
      max-width: 70%;
      margin: 0 auto 30px;
    }

    .all-specials {
      @include vrweb-button($success-color);
      //@include saletag('white');
    }
  }
  .discounted-units-wrapper {
    padding-top: 15px;
    padding-bottom: 15px;

    a {
      @include vrweb-button($success-color);
      //@include saletag('white');
    }
  }
  .view-content {
    @include clearfix();
    display: flex; 
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
    @media #{$small} {
      padding: 15px 0px;
    }
  }
  .views-row {
    flex: 0 1 auto;
    flex-basis: 100%;
    align-items: stretch;
    margin: 0 1% 25px;

    @media #{$small} {
      flex-basis: 47.9%; // do not use 50% * safari bug
    }

    @media (min-width: emCalc(1024)) {
      flex-basis: 31.2%; // do not use 33.332% * safari bug
    }

    .node-special.view-mode-teaser {
      width: 100%;
      height: 100%;
      padding-bottom: 15px;
      @media #{$small} {
        padding-bottom: 75px;
        margin: 0 0px !important;
      }
    }
  }
}

// View Mode: Teaser
// Specials Landing Page Special Teasers
.node-special.node-teaser {
  background: $muted-color;
  position: relative;
  .group-left {
    @include min(both);
  }
  .group-right {
    position: static;
    padding: 5px 10px;
    h3 {
      font-size: 23px;
      margin-top: 10px;
      margin-bottom: 0;
      text-transform: none;
      letter-spacing: .025em;

      @media #{$tiny} and (max-width: emCalc(639)) {
        margin-top: 0;
      }
    }
    span.date-display-end {
      &:after {
        content: '';
        display: block; 
      }
    }
    .field-name-body {
      padding-top: 10px;
    }
    .label-above {
      padding-top: 10px;
    }
  }
  .group-header {
    padding: 0;

    .field-name-title {
      padding: 0;

      h2 {
        margin: 0;

        a {
          display: block;
          width: 100%;
          padding: 10px 15px 7px;
          background-color: $primary-color;
          color: $white;
          font-size: 17px;
          transition: background-color .15s ease-out;

          &:hover, &:active {
            background: $tertiary-color;
            color: $white;
            text-decoration: none;
          }

          &:after {
            content: '';
            display: inline-block;
            width: 15px;
            height: 16px;
            background: url("../images/chevron-right-white.svg") right center no-repeat;
            margin-bottom: -2px;
          }
        }
      }
    }
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  .label-inline,
  .label-above {
    text-transform: none;
    font-weight: 600;
    color: $primary-color;
  }
  .field-name-field-minimum-stay,
  .field-name-field-valid-dates,
  .field-name-field-for-stay-dates-between,
  .field-name-field-call-to-book-number,
  .field-name-field-promo-code {
    margin-top: 10px;
  }

  .group-special-cta,
  .group-addl-info {
    padding: 0 10px;
  }

  /*.special-teaser-image {
    margin-bottom: 15px;
  }*/

  .field-name-title {

    h2 {
      @include teaser-titles();
      a{
        font-size: 21px;
        font-weight: 600;
        line-height: 1.1;
        color: $tertiary-color;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
  .field-name-field-special-subhead {
    color: $primary-color;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    font-family: $head-gf;
    letter-spacing: .03em;
  }

  .group-promo-wrap {
    @extend %no-label;
    @include clearfix();
    padding-top: 0;
    margin-bottom: 0;

    @media #{$small} {
      position: absolute;
      padding: 0;
      left: 10px;
      right: 10px;
      bottom: 15px;
    }

    .field-name-field-promo-code {
      @include grid-column(7.5);
      padding-left: 10px;
      padding-right: 10px;
      background: #fff;
      border: solid 1px $primary-color;
      border-right: none;
      border-top-left-radius: $global-radius;
      border-bottom-left-radius: $global-radius;
      margin-top: 0;
      font-weight: 700;
      height: 45px;
      padding-top: 3px;

      &, * {
        color: $primary-color;
      }

      .label-inline  {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 400;
        padding-top: 3px;
        letter-spacing: .03em;
        display:block;
        line-height:1.0;
      }
    }

    .field-name-node-link {
      @include grid-column(4.5);
      @include min(both);

      a {
        @include vrweb-button($border-style: true, $color: $primary-color, $display: block);
        @include min(both);
        background-color: $primary-color;
        color: #FFF;
        font-size: 14px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
        height: 45px;
        padding-top: 13px;
      }
    }
  }
}

// View Mode: Full
// Specials Detail Page Layout
.node-special.view-mode-full {
  .group-left {
    span.date-display-end {
      &:after {
        content: '';
        display: block; 
      }
    }    
  }
  &.view-mode-teaser,
  &.view-mode-full {
    [class*="special-subhead"] {
      padding-top: 10px;
      font-size: 18px;
      color: $primary-color;
      padding-bottom: 5px;
      text-transform: uppercase;
      font-weight: 300;
      letter-spacing: .05em;
    }

    .field-name-field-for-stay-dates-between,
    .field-name-field-valid-dates,
    .field-name-field-minimum-stay,
    .field-name-field-promo-code,
    .field-name-field-call-to-book-number,
    .date-display-start,
    .date-display-end {
      padding-bottom: 10px;
      font-weight: 300;

      .label-above,
      .label-inline {
        text-transform: none;
        font-weight: 600;
        color: $tertiary-color;
      }
    }

    .field-name-field-promo-code,
    .field-name-field-call-to-book-number {
      &,
      .label-above,
      .label-inline {
        color: $success-color;
      }
    }

    .field-name-field-call-to-book-number + .field-name-field-promo-code {
      display: none;
    }
  }

  &.view-mode-full {
    .group-left,
    .group-right {
      @include grid-row();
      padding-left: 15px;
      padding-right: 15px;
    }
    .group-left {
      img {
        display: block;
        width: 100%;
        height: auto;
        @include box-shadow();
        margin-bottom: 15px;
      }

      @media only screen and (min-width:emCalc(620)) {
        .field-name-field-special-image {
          @include grid-column(4);
          @include min(left);
        }
        .group-summary {
          @include grid-column(8);
          @include min(left);
        }
      }
    }
    .group-right {
      padding-top: 15px;
    }

    .field-name-body {
      padding-top: 15px;
      clear: both;
    }
  }

  &.view-mode-teaser {
    .group-right {
      padding:10px 15px;
    }

    .field-name-title {
      h2 {
        margin-bottom:0px;

        @media #{$medium} {
          font-size:24px;
        }
      }
    }
    img {
      width:100%;
      height:auto;
    }
    .field-name-field-call-to-book-number {
      background:$white;
      padding:10px;
      border:solid 1px $success-color;
      border-radius:$global-radius;
    }
    .group-promo-wrap {
      display:flex;
      background:$white;
      border-radius:$global-radius;
      align-items:stretch;
      @include box-shadow();

      .promo-code,
      .field-name-node-link {
        flex:1 1 auto;
        position:relative;
        display:flex;
        height:50px;
        flex-flow:row wrap;
        align-items:center;
      }

      .promo-code {
        padding-left:10px;
        padding-right:10px;
        border:solid 1px $success-color;
        border-top-left-radius:$global-radius;
        border-bottom-left-radius:$global-radius;
        padding:5px 10px;
        color:$success-color;
        font-size:15px;

        .label-inline {
          color:$success-color;
          font-size:14px;
          font-weight:300;
          text-transform:none;
        }
      }

      .field-name-node-link {
        flex:0 1 150px;

        a {
          width:100%;
          text-align:center;
          height:50px;
          background: $success-color;
          color:$white;
          padding-top:17px;
          font-weight:300;
          letter-spacing:.05em;
          border-top-right-radius:$global-radius;
          border-bottom-right-radius:$global-radius;
          line-height:1.2;
          font-size:12px;

          @media only screen and (min-width:emCalc(400)) {
            font-size:14px;
          }
          @media only screen and (min-width:emCalc(620)) and (max-width:emCalc(699)) {
            font-size:12px;
          }
        }
      }
    }
    .field-name-field-call-to-book-number + .group-promo-wrap {
      display:none;
    }
  }
}

//OFF Canvas Specials Search Behavior
body.node-type-special,
body.units-on-special {
  .l-main {
    max-width: none;
    .main.columns {
      padding-right: 0;
      padding-left:0;
    }
  }
  #page-title {
    text-align: center;
  }
}

.node-special.view-mode-popup_teaser {

  .node-title {
    a {
      @include teaser-titles();

      text-decoration: underline;

      &:hover, &:active {
        text-decoration: none;
      }
    }
  }
  .field-name-title h2 {
    font-size: 22px;
    margin-bottom: 5px;
  }
  .field-name-field-special-subhead {
    text-transform: uppercase;
    font-weight: 600;
    color: $secondary-color;
    padding-bottom: 10px;
    .field-label {
      display: none !important;
    }
  }
  .field-name-field-call-to-book-number,
  .field-name-field-valid-dates,
  .field-name-field-for-stay-dates-between,
  .field-name-field-minimum-stay,
  .field-name-field-valid-dates {
    .label-inline,
    .label-above {
      font-weight: 700;
      padding-top: 10px;
      font-size: 15px;
      text-transform: none;
      color: inherit;
    }
  }
  .field-name-field-promo-code {
    padding-top: 10px;
    font-weight: 700;
    color: $secondary-color;

    .field-label {
      font-weight: 400;
      padding-top: 10px;
      font-size: 15px;
      display: inline;
    }
  }
}
