rc-riot-order,
[riot-tag="rc-riot-order"],
[data-is="rc-riot-order"] {

  //Global
  .vrweb-collection-block-count {
    float: left;
    margin-left: 2px;
    margin-right: 4px;

    &:before {
      content: '(';
    }

    &:after {
      content: ')';
    }
  }

  .fi-shopping-bag {
    float: left;
  }

  //topbar link
  .top-bar & {
    .price.sub-total {
      display: none;

      @media only screen and (min-width: emCalc(525)) {
        display: inline;
      }
    }

    @media #{$medium-down} {
      .fi-shopping-bag:before,
      .totals.trigger,
      .vrweb-collection-block-count {
        height: $topbar-touch-height;
      }

      .totals.trigger,
      .vrweb-collection-block-count {
        line-height: $topbar-touch-height + 5px;
      }

      .fi-shopping-bag:before {
        font-size: 24px;
        line-height: $topbar-touch-height;
      }

      .totals.trigger {
        display: block;
        padding-top: 0;
      }

      .vrweb-collection-block-count {
        display: block;
      }
    }
  }

  //service bar link
  .l-service-bar & {

    @media #{$medium} {

      .totals.trigger {
        color: $service-bar-link-color;

        &:hover,
        &:active {
          color: $service-bar-link-color-hover;

          .fi-shopping-bag:before {
            color: $service-bar-link-color;
          }
        }
      }

      .fi-shopping-bag:before {
        font-size: 22px;
      }
    }
  }

  //Modal
  .reveal-modal & {
    max-height: 600px;
    overflow-y: scroll;
    max-width: 900px;
    margin: auto;
    position: static;

    .line-items {
      > h5 {
        text-align: center;
        padding: 10px 0px 5px;
      }
      .line-item {
        position: relative;
        border-top-style: solid;
        border-top-color: $muted-color-alt;

        .teaser-img {
          height: 0;
          overflow: hidden;
          padding-top: 60%;
          background: $result-list-item-noimg-bg url("../images/icon-missing-image.png") center no-repeat;
          background-size: auto 30%;
          position: relative;

          img {
            width: 102%;
            @include absolute-center($left: -1%, $right: -1%);
          }
        }

        .name {
          @include teaser-titles($alt-color: true);

          &, a {
            font-size: 17px;
          }
        }

        @media only screen and (min-width: emCalc(585)) {
          .teaser-img {
            padding-top: 20%;
            width: 30%;
          }
          .name {
            margin-top: 0px;
          }
          .group-summary {
            width: 40%;
            padding-right: 0;
            float: left;
            padding-left: 10px;

            > * {
              width: 100%;
              text-align: left;
              @include min(both);
              display: block;
              float: left;
              clear: left;
              font-size: 14px;
            }
            div.dates {
              color: $rcorder-dates-color;
              font-weight: $rcorder-dates-weight;
            }
            .display-name {
              color: $rcorder-display-name-color;
              font-weight: $rcorder-display-name-weight;
              width: auto;
              display: inline-block;
              // &:before {
              // 	content:'';
              // 	display:block;
              // 	width:100%;
              // 	height:1px;
              // 	background:$rcorder-display-name-border-color;
              // 	margin-top:5px;
              // 	margin-bottom:5px;
              // }
            }

            .display-detail {
              color: $rcorder-display-detail-color;
            }

            .trav-wrap {
              color: $rcorder-travelers-color;
              font-weight: $rcorder-travelers-weight;
            }
          }
          .actions {
            button {
              top: 20px;
              left: 5px;
              @include box-shadow();
            }
          }
        }
      }

      div.details {

        div.price {

          @include clearfix();

          .price {
            background: rgba($grid-view-item-price-bg, .9);
            display: inline-block;
            width: auto;
            color: $alt-pricing-color;
            position: absolute;
            padding: 7px 10px;
            left: 0px;
            font-size: 18px;
            font-family: $body-gf;
            letter-spacing: .05em;
            box-shadow: 1px 1px 2px rgba($black, .4);
            top: 38%;

            @media only screen and (min-width: emCalc(450)) {
              top: 48%;
            }

            @media only screen and (min-width: emCalc(585)) {
              position: relative;
              background: none;
              padding: 0;
              box-shadow: none;
            }
          }

          button {
            @include vrweb-button($book-button-alt-bg, $size: small);
            float: right;
            font-weight: 600;
            padding-left: 8px;
            padding-right: 8px;

            &:hover,
            &:active {
              background-color: $book-button-alt-bg-hover;
            }
          }

          button.quote {
            display: inline;
            color: $quote-text-link-color;
            box-shadow: none;
            padding: 0;
            letter-spacing: .035em;
            font-style: italic;
            font-size: 13px;
            float: left;
            text-align: left;
            margin-top: 8px;
          }
        }

        @media only screen and (min-width: emCalc(585)) {
          padding-right: 0;
          width: 30%;
          float: right;
          position: relative;
          padding-left: 10px;

          div.price {
            text-align: center;

            button {
              width: 100%;
              white-space: normal;

              &, &.quote {
                text-align: center;
              }

              &.quote {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }

    .totals {
      background: $search-list-item-pricing-bg-desktop;
      color: $black;
      font-weight: 700;

      .total-price {
        color: $pricing-text-color;
        font-weight: 700;

        sup {
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
    .actions.bottom {
      background: $search-list-item-pricing-bg-desktop;
      border-bottom: solid 15px $white;

      .tag-content {
        display: inline;
      }

      button {
        @include vrweb-button($share-button-bg);

        &:hover,
        &:active {
          background-color: $share-button-bg-hover;
        }
      }

      > button {
        @include vrweb-button($book-button-bg);

        &:hover,
        &:active {
          background-color: $book-button-bg-hover;
        }
      }
    }
    .actions.accom {
      //position:relative;
      padding-top: 0;

      button {
        //position:relative;
        border-top-color: $search-accom-border-color;
        color: $search-accom-color;
        font-weight: 600;
        background: $search-accom-bg;
        box-shadow: 0px -2px 3px rgba(0, 0, 0, .25);

        &:hover,
        &:active {
          background: $search-accom-bg-hover;
          text-decoration: underline;
          color: $search-accom-color-hover;

          #search-link-arrow {
            right: 10px;
          }
        }
      }

      * {
        fill: $search-accom-icon-color;
      }
    }
  }
}