riot-solr-range-slider,
[riot-tag="riot-solr-range-slider"],
[data-is="riot-solr-range-slider"],
rc-riot-solr-price-slider,
[riot-tag="rc-riot-solr-price-slider"],
[data-is="rc-riot-solr-price-slider"] {

  riot-solr-facet-group &,
  [riot-tag="riot-solr-facet-group"] &,
  [data-is="riot-solr-facet-group"] & {

    body & {
      &,
      &.has-prices {
        .noUi-connect {
          background: $ranges-selected-bg;

          @media #{$small} {
            background: $ranges-selected-bg-desktop;
          }
        }
        .noUi-base,
        .ranges-canvas.noUi-target .noUi-origin.noUi-background {
          background: $ranges-not-selected-bg;
          box-shadow: inset 0px 1px 2px rgba($black, .1);

          @media #{$small} {
            background: $ranges-not-selected-bg-desktop;
          }
        }
      }
    }
  }

  #vrweb-search & {
    @media #{$small} {
      @include range-slider-fw();
    }
  }

  .reveal-modal & {
    @media only screen and (max-width: emCalc(525)) {
      @include range-slider-fw();
    }
  }
}