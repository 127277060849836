@font-face {
  font-family: 'social-icons';
  src: url('../fonts/social-icons/social-icons.eot?1l1ory');
  src: url('../fonts/social-icons/social-icons.eot?#iefix1l1ory') format('embedded-opentype'),
  url('../fonts/social-icons/social-icons.woff?1l1ory') format('woff'),
  url('../fonts/social-icons/social-icons.ttf?1l1ory') format('truetype'),
  url('../fonts/social-icons/social-icons.svg?1l1ory#social-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'social-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-thumbs-o-up:before {
  content: "\f087";
}

.icon-youtube:before {
  content: "\f167";
}

.icon-github:before {
  content: "\e600";
}

.icon-github2:before {
  content: "\e601";
}

.icon-flickr:before {
  content: "\e602";
}

.icon-vimeo:before {
  content: "\e603";
}

.icon-twitter:before {
  content: "\e604";
}

.icon-facebook:before {
  content: "\e605";
}

.icon-googleplus:before {
  content: "\e606";
}

.icon-pinterest:before {
  content: "\e607";
}

.icon-tumblr:before {
  content: "\e608";
}

.icon-linkedin:before {
  content: "\e609";
}

.icon-dribbble:before {
  content: "\e60a";
}

.icon-stumbleupon:before {
  content: "\e60b";
}

.icon-lastfm:before {
  content: "\e60c";
}

.icon-rdio:before {
  content: "\e60d";
}

.icon-spotify:before {
  content: "\e60e";
}

.icon-instagram:before {
  content: "\e60f";
}

.icon-dropbox:before {
  content: "\e610";
}

.icon-skype:before {
  content: "\e611";
}

.icon-paypal:before {
  content: "\e612";
}

.icon-soundcloud:before {
  content: "\e613";
}

.icon-mixi:before {
  content: "\e614";
}
