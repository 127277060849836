.reveal-modal,
.bt-leads-share-url-form.reveal-modal {
  .close-reveal-modal {
    display: block;
    width: 34px;
    height: 34px;
    text-indent: -999px;
    overflow: hidden;
    box-shadow: 0px 1px 3px rgba($black, .5);
    top: -10px;
    right: -10px;
    background: $white url("../images/icon-x-black.svg") center no-repeat;
    background-size: auto 15px;
    border-radius: 300px;

    &:hover,
    &:active {
      background-color: lighten($tertiary-color, 20%);
    }
  }
}