//=================================================================
//    FEATURED TABS
//    Style and Variables
//    See _vrweb-settings for all featured-tab variables.
//=================================================================
.featured-tabs {
  @media #{$small-down} {
    background: transparent;
    position: relative;
    height: 0px;
    overflow: hidden;
    transition-property: all;
    transition-duration: .5s;
    transform: rotateX(-90deg);
    transform-origin: top;
    animation-timing-function: ease-in-out;
    border-radius: 0px;
  }

  //set heights for mobile
  .show-search & {
    @media #{$small-down} {
      transform: rotateX(0deg);
      height:auto;
      z-index:99;
    }
  }

  //
  @media #{$small} {
    @include absolute-center($bottom: 15px, $top: auto);
    z-index: 15;
    border-radius: $global-radius;
    background: $featured-tabs-bg;
    max-width: 940px;

    //===============================================
    //Bottom Aligned TABS
    //===============================================

    @if $featured-tabs-align-bottom == true {
      top: auto;
      bottom: 0px;
      max-width: none;
      width: 100%;
      border-radius: 0px;
      height: 95px;

      @if $featured-tabs-number == 3 or $featured-tabs-number == 2 {
        height: 130px;
      }
      .ft-fw {
        @include grid-row;
      }
    }
  }
  @media #{$medium-down} {
    @if $featured-tabs-align-bottom == true {
      .ft-fw {

        @media #{$medium-down}{
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
  @media #{$medium} {
    @if $page-header-image == true {
      @include absolute-center($top: auto, $bottom: 5%);
    }
  }

  .section-container,
  .section-container.auto,
  .section-container.tabs {
    background: $transparent;
    margin-bottom: 0px;

    > section {
      background: $transparent;
      padding-top: 0 !important;

      .content {
        background: $transparent;
        border: none;
      }

      .title {
        background: $transparent;
        border: none;

        a {
          background: $featured-tab-touch-bg;
          color: $featured-tab-touch-color;
          font-size: 12px;
          padding-bottom: 8px;
          padding-top: 9px;
          text-decoration: none !important;
          height: 50px;
          white-space: normal;
          font-weight: 400;
          font-family: $body-gf;
          letter-spacing: .02em;

          &:hover,
          &:active {
            color: $featured-tab-touch-color-over;
            background: $featured-tab-touch-bg-over;
          }

          @media #{$tiny} {
            height: auto;
            max-height: $form-component-height;
          }

          @media #{$small} {
            background: $featured-tab-bg;
            color: $featured-tab-color;
            transition-property: color, background;
            transition-duration: .35s;
            transition-timing-function: ease-in-out;
            font-size: 17px;
            padding-top: 8px;
            padding-bottom: 6px;
            max-height: none;

            &:hover,
            &:active {
              color: $featured-tab-color-over;
              background: $featured-tab-bg-over;
            }
          }
        }
        //a

        &:hover,
        &:active {
          background: $transparent;

          a {
            color: $featured-tab-touch-color-over;
            background: $featured-tab-touch-bg-over;
          }

          @media #{$small} {
            a {
              color: $featured-tab-color-over;
              background: $featured-tab-bg-over;
            }
          }
        }
      }
      //title

      &.active {
        .title {
          background: $transparent;
          border: none;

          @media #{$small-down}{
            margin-bottom: 0;
          }

          @media #{$small} {
            &:after {
              content: '';
              display: block;
              width: 0px;
              height: 0px;
              @include absolute-center($top: auto, $bottom: -24px);
              border-style: solid;
              border-width: 12px;
              border-color: $featured-tab-active-bg $transparent $transparent $transparent; //drop in color if the variable is an image or gradient
            }
          }

          a {
            background: $transparent;
            color: $featured-tab-touch-active-color !important;

            @media #{$small} {
              background: $featured-tab-active-bg !important;
              color: $featured-tab-active-color !important;
            }
          }
          //a

          &:hover, &:active {
            a {
              background: $transparent;

              @media #{$small} {
                background: $featured-tab-active-bg !important;
                color: $featured-tab-active-color !important;
              }
            }
            //a
          }
        }
        //title
      }
    }
    //section

    //===============================================
    //	Zero TABS
    //===============================================
    @if $featured-tabs-number == 0 {
      > section {
        .content {
        }
        .title {
          display: none !important;
        }
      }
    }
    //===============================================
    //	Two or Three TABS
    //===============================================
    @if $featured-tabs-number == 3 or $featured-tabs-number == 2 {
      > section {
        .content {
          padding-top: 63px;

          @media #{$tiny} {
            padding-top: 55px
          }

          @media #{$small} {
            padding-top: 53px;
          }
        }
        .title {
          a {

          }
        }
      }
    }
    //===============================================
    //	Two TABS
    //===============================================
    @if $featured-tabs-number == 2 {
      > section {
        .title {
          width: 50% !important;
        }
        &:nth-of-type(1) .title {
          left: 0px !important;

          a {
            @media #{$small}{
              border-top-left-radius: $global-radius;
            }
          }

          @media #{$small-down}{
            width: 50% !important;
            top: 0px !important;
            position: absolute;
          }
        }
        &:nth-of-type(2) .title {
          left: 50% !important;

          a {
            @media #{$small}{
              border-top-right-radius: $global-radius;
            }
          }
          @media #{$small-down}{
            width: 50% !important;
            top: 0px !important;
            left: 50% !important;
            position: absolute;
          }
        }
        &:nth-of-type(3) .title {
          display: none !important;
        }
      }
    }
    //===============================================
    //	Three TABS
    //===============================================
    @if $featured-tabs-number == 3 {
      > section {
        .title {
          width: 33.33% !important;
        }
        &:nth-of-type(1) .title {
          left: 0px !important;

          a {
            @media #{$small}{
              border-top-left-radius: $global-radius;
            }
          }
          @media #{$small-down}{
            width: 33.333% !important;
            top: 0px !important;
            position: absolute;
          }
        }
        &:nth-of-type(2) .title {
          left: 33.333% !important;

          a {
            border-left: solid 1px darken($featured-tab-bg, 8%);
            border-right: solid 1px darken($featured-tab-bg, 8%);
          }

          &:hover,
          &:active {
            a {
              border-color: $featured-tab-bg-over;
              border-color: $featured-tab-bg-over;
            }
          }

          &.active .title {
            a {
              border-left-color: $featured-tab-active-bg;
              border-right-color: $featured-tab-active-bg;
            }
          }

          @media #{$small-down}{
            width: 33.333% !important;
            top: 0px !important;
            left: 33.333% !important;
            position: absolute;
          }
        }
        &:nth-of-type(3) .title {
          left: 66.666% !important;
          a {
            @media #{$small}{
              border-top-right-radius: $global-radius;
            }
          }

          @media #{$small-down}{
            width: 33.333% !important;
            top: 0px !important;
            left: 66.666% !important;
            position: absolute;
          }
        }
      }
    }
    //===============================================
    //	Button Style TABS
    //===============================================
    @if $featured-tabs-button-style == true {
      > section {
        .content {
        }
        .title {
          @media #{$small} {
            padding-left: 2px;
            padding-right: 2px;
            padding-top: 6px;

            a {
              border-radius: $global-radius;
              padding-top: 7px;
              padding-bottom: 6px;
            }
          }
        }

        &.active {
          .title {
            &:after {
              display: none;
            }

            &:hover > a,
            &:active > a,
            a,
            a:active,
            a:hover {
              background: $transparent;
            }
          }
        }
      }
    }

    //===============================================
    //	Button Style and Bottom Aligned TABS
    //===============================================
    @if $featured-tabs-button-style == true and $featured-tabs-align-bottom == true {
      > section {
        .content {
          @media #{$small} {
            padding-left: 2px;
            padding-right: 2px;
          }
        }
        .title {
          a {

          }
        }
      }
    }
  }

  .view-vacation-rental-listings {
    text-align: center;
    h6 {
      margin: 0 0 .5rem;
      line-height: 1;
    }
  }

  .view-vr-listing-featured-amenities {
    margin-top: 2px;
    .item-list.table {
      padding-top: 0px;
      padding-bottom: 0px;

      @media #{$small-down} {
        width: 100%;
        display: block;
      }
      .inline-list {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0;
      }
    }
    li {
      @media #{$small-medium} {
        margin-left: .5rem;
      }
      @media #{$small-down}{
        margin-left: 0px;
        margin-bottom: 5px;
        @include grid-column(6);
        padding-left: 5px;
        padding-right: 5px;

        &:nth-of-type(2n+1) {
          padding-left: 0px;
          clear: both;
        }
        &:nth-of-type(2n) {
          padding-right: 0px;
        }
      }
      span.field-content {
        line-height: 1;
        display: block;
      }
      a {
        @include vrweb-button();
        display: inline-block;
        border-radius: $global-radius !important;
        overflow: hidden;

        @media #{$small-down}{
          width: 100%;
          background: $secondary-color;
        }
      }
    }
  }
  .view-id-vacation_rental_listings {
    form#ctools-jump-menu {
      margin-bottom: 0;
    }
  }
  rc-riot-avail-filter,
  [data-is="rc-riot-avail-filter"] {
    padding-top: 0;
  }
}
