//=============================================
//    HOMEPAGE TRIPTYCH
//    Style and Variables
//=============================================

// Color for title blocks (Blog, Events, Things to Do)
$triptych-block-title-color: $primary-color !default;

// Color for content titles.
$triptych-content-title-color: $tertiary-color !default;

//=============================================
//
//  Triptych styles.
//  This component only exists on the homepage
//-------------------------------------------//

.l-triptych {
  display: none;
  background-color: $muted-color;
  padding-bottom: 70px;

  .triptych-content {
    @media only screen and (min-width: emCalc(740)) {
      display: flex;
    }

    max-width: $row-width;
    margin: 0 auto;
    float: none;
    padding-bottom: 20px;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 45px 45px;
  }

  @media only screen and (min-width: emCalc(600)) {
    display: block;
    max-width: none;
  }

  //Global styles
  section {
    @include grid-column(12);
    @include min(both);
    background-color: $muted-color;
    box-shadow: 0px 3px 20px #0000000D;
    position: relative;

    @media only screen and (min-width: emCalc(740)) and (max-width: emCalc(1149)) {
      flex-basis: calc(50% - 23px);
      padding-bottom: 34px;
    }

    @media only screen and (min-width: emCalc(1150)) {
      flex-basis: calc(33% - 30px);
      padding-bottom: 34px;
    }

    article {
      position: static;
    }

    .view-header {
      padding-bottom: 10px;

      h4 {
        @media only screen and (max-width: emCalc(739)) {
          display: block;
          width: 100%;
          text-align: center;

          &, a {
            background: $secondary-color;
            color: $white;
          }
          a {
            display: block;
            width: 100%;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: .15em;
            font-size: 16px;
            padding: 8px 15px;
            box-sizing: content-box;
            margin-left: -15px;
            text-decoration: none !important;
            color: $white !important;

            &:hover, &:active {
              background: $tertiary-color;
            }

            &:after {
              content: '';
              display: inline-block;
              width: 11px;
              height: 16px;
              background: url("../images/chevron-right-white.svg") center no-repeat;
              margin-left: 10px;
            }
          }
        }

        @media only screen and (min-width: emCalc(740)) {
          margin-bottom: 0px;

          &, a {
            font-weight: 700;
            color: $secondary-color;
            text-transform: uppercase;
            letter-spacing: .1em;
            font-size: 20px;
          }

          a {
            display: block;
            text-align: left;

            &:after {
              content: '';
              display: inline-block;
              width: 11px;
              height: 16px;
              background: url("../images/chevron-right-black.svg") center no-repeat;
              margin-left: 10px;
              opacity: 0;
              transition: opacity .25s ease-out;
            }

            &:hover, &:active {
              text-decoration: none;
              color: $primary-color;

              &:after {
                opacity: 1.0;
              }
            }
          }
        }
      }
    }

    .group-ttd-image-wrapper,
    .group-event-image-wrapper,
    .group-blog-image-wrapper {
      position: relative;
      height: 0;
      padding-top: 77%;

      .field-name-field-teaser-image {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        top: 0px;
        left: 0px;

        img {
          transition: all .3s ease-in-out;
        }
      }

      &:hover {
        img {
          transform: scale(1.2);
        }
      }
    }

    .field-name-field-blog-category,
    .field-name-field-event-category,
    .field-name-field-ttd-category {
      top: 0px;
      left: 0px;
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-size: 20px;
        font-style: italic;
        font-weight: normal;
        color: $white;
        text-align: center;
        padding: 10px 5px;
        font-family: $header-font-family;
        text-rendering: geometricPrecision;
        letter-spacing: .5px;
        transition: all .25s ease-in-out;
        &:hover {
          color: darken($white, 7%);
        }
      }
    }

    .field-name-title {
      padding: 15px 10px 0 20px;

      h5,
      h6 {
        margin-top: 5px;
        font-size: 20px;
      }
    }

    .field-name-body {
      padding: 5px 10px 0 20px;
    }

    .field-name-node-link {
      @media only screen and (min-width: emCalc(740)) {
        position: absolute;
        bottom: 0px;
        padding: 10px 10px 20px 20px;
      }

      a {
        text-transform: uppercase;
        color: $secondary-color;
        font-weight: bold;
        font-size: 16px;

        &:hover {
          text-decoration: underline;
        }

        &:after {
          content: ' >';
        }
      }
    }
  }

  //Nodes
  .node-promoted {
    margin-bottom: 20px !important;

    .group-content {
      a {
        img {
          display: block;
          position: relative;
        }
      }
    }

    .group-left {
      @include grid-column(4);
      @include min(both);
    }

    .group-right {
      @include grid-column(8);
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      @include image-borders();
      box-shadow: none;
    }

    .field-name-field-event-date {
      padding-bottom: 5px;
    }

    .group-content > a,
    .field-name-field-teaser-image {
      position: relative;
      &:after {
        z-index: 2;
        display: block;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: transparent linear-gradient(#10304000 0%, #103040BF 100%);
        opacity: 0.75;
      }
    }

    .field-name-title {
      h5, h6 {
        @include teaser-titles($mtop: 0px);
      }
    }
  }

  section.block-vrfusion-site-vrw-triptych-text {
    width: 100%;
    text-align: center;
    float: none;
    flex-basis: 100%;
    background-color: transparent;
    box-shadow: none;
    display: block;
    padding-bottom: 0px;
    margin-bottom: -20px;

    h2 {
      margin-top: 60px;
      text-transform: none;
      font-style: italic;
      letter-spacing: 0px;
    }

    h3 {
      font-size: 18px;
      color: $secondary-color;
      letter-spacing: 1.35px;
    }
  }
}
