rc-riot-result-list-item-detailed,
[riot-tag="rc-riot-result-list-item-detailed"],
[data-is="rc-riot-result-list-item-detailed"] {
  @include clearfix();
  background: $white;
  position: relative;
  margin-bottom: 20px;
  background: $list-teaser-bg;
  @include box-shadow();

  a.image-wrap {
    padding-top: $result-list-item-image-padding-ratio;
    background: $result-list-item-noimg-bg url("../images/icon-missing-image.png") center no-repeat;
    background-size: auto 30%;
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
  }
  img {
    display: block;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: -1%;
    right: -1%;
    min-width: 102%;
  }

  .first,
  .middle,
  .right {
    width: 100%;
    position: relative;
  }

  .middle {
    padding: 0px 10px 8px;
  }

  h4 {
    @include teaser-titles($alt-color: true, $larger: true);
    margin: 0;
    padding: 8px 0px 3px;
  }

  @media only screen and (min-width: emCalc(525)) {
    padding: 20px 0px;
    border-bottom: solid 1px $teaser-border-color;
    margin-bottom: 0;
    box-shadow: none;
    background: $list-teaser-bg-desktop;

    riot-solr-flag
    [riot-tag="riot-solr-flag"],
    [data-is="riot-solr-flag"] {
      top: 20px;
      left: 0px;
      right: auto;
    }

    .first {
      @include grid-column(5);
      @include min(both);
      min-height: 1px;
    }

    .middle {
      @include grid-column(7);
    }

    .right {
      @include grid-column(7);
      float: right;
      clear: right;
    }

    h4 {
      padding-top: 0;
      margin-top: 2px;
    }
  }

  @media #{$medium} {
    .first {
      @include grid-column(4);
      @include min(both);
    }

    .middle {
      @include grid-column(5.5)
    }

    .right {
      @include grid-column(2.5);
      @include min(both);
    }
  }

  .rc-details {

    .rc-detail {
      line-height: 1.0;

      + .rc-detail {
        border-left: solid 1px $black;
        padding-left: 3px;
        margin-left: 3px;
      }
    }
  }
}