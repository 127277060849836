//=============================================
//    HEADER & SERVICE BAR
//    Style and Variables
//=============================================
$service-bar-link-color: $muted-color !default;
$service-bar-link-color-hover: darken($service-bar-link-color, .3) !default;

$service-bar-button-color: $white !default;
$service-bar-button-bg: $tertiary-color !default;

//=============================================
//
//  Header & Service Bar styles.
//-----------------------------------------------------------------//

.l-header {
  padding: 0px;
  width: 100%;
  left: 0px;
  right: 0px;
  transition: none !important;

  @media #{$medium-down} {
    max-width: none;
    padding: 0;
  }

  @media #{$medium} {
    .has-banner &,
    .front & {
      min-height: 470px;
      background: linear-gradient(180deg, rgba(16, 48, 64, 0.5625) 0%, #10304000 100%) 0% 0% no-repeat padding-box;
    }

    .l-header-region {
      position: relative;
      height: 0;
      overflow: visible;
    }
  }
  @if $page-header-image == true {
    @media #{$medium} {
      @include absolute-center($bottom: auto);
      height: $header-height-desktop;
      z-index: +16;

      .checkout-page & {
        height: auto;
      }
    }
  }
  //================================================
  //  Display topbar as inline with logo
  //  for $topbar-display-fullwidth:false;
  //  Dont touch these unless you have a good reason
  //================================================
  @if $topbar-display-fullwidth == false {
    .header-content {
      @include grid-row;
    }
    .nav-contain {
      @media #{$medium}{
        @include grid-column(8);
        float: right;
        clear: right;
        padding-top: 20px;
        padding-right: 125px;
      }
    }
    .l-logo-header {
      padding-bottom: 0px;
    }
  }
  //================================================
  //  Display topbar as fulwidth below logo
  //  for $topbar-display-fullwidth:true;
  //  Dont touch these unless you have a good reason
  //================================================
  @if $topbar-display-fullwidth == true {
    padding-bottom: 0px;
    @include collapse(both);

    .header-top-content {
      display: block;
      @include grid-row;
      padding: 0px 15px 10px;

      @media #{$medium-down} {
        padding: 0px;
      }
    }

    .nav-wrapper {
      display: block;
      width: 100%;
      background: $topbar-bg;

      @media #{$medium} {
        text-align: center;
      }

      .nav-contain {
        @include grid-row;
      }
    }
  }

  .header-right {
    @media #{$medium} {
      padding-top: 25px;
      padding-right: 15px;
    }
  }

  .l-logo-header {
    padding-top: 25px;
    @include min(right);
  }
  .logo {
    float: none;
    position: relative;

    a#logo img {
      width: 302px;
      height: auto;
    }
  }
  .mobile-logo {
    display: block;
    width: 125px;
    height: $topbar-touch-height;
    background: url("../logo-mobile.png") right center no-repeat;
    background-size: auto 52px;

    @media only screen and (min-width: emCalc(400)) {
      background-size: auto 52px;
    }
  }
}

/*          SERVICE BAR
----------------------------------*/
.l-service-bar {
  position: relative;

  .riot_tags_vrw_favorites_counter {
    margin-left: 1.5em;
    #riot-solr-flag-counter section a {
      i {
        width: 22px;
        height: 20px;
        background-image: url(../images/icon-detail-favorite.svg);
        background-size: 100%;
      }

      .flag-count {
        margin-right: 0px;
        font-size: 13px;
        color: $service-bar-link-color;
      }
    }
  }

  .riot_tags_vrw_order {
    i.fi-shopping-bag {
      width: 18px;
      height: 18px;
      background-image: url(../images/icon-detail-trips.svg);
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center 1px;

      &:before {
        display: none;
      }
    }

    .vrweb-collection-block-count {
      font-size: 13px;
      margin-left: 4px;
      color: $service-bar-link-color;
    }
  }

  .vrfusion_newsletter_form_client_newsletter_link {
    margin-left: 1.1em;
    i.fi-mail {
      width: 22px;
      height: 18px;
      background-image: url(../images/icon-detail-mail.svg);
      background-size: 100%;
      display: inline-block;

      &:before {
        display: none;
      }
    }
  }

  .vrweb_base_site_phone_number {
    body:not(.checkout-page) & {
      position: absolute;
      right: 0px;
      top: 70px;
      z-index: 2;
    }
  }

  .vrweb_base_site_phone_number_2,
  .vrweb_base_site_phone_number {
    a {
      border: 1px solid $service-bar-link-color;
      padding: 4px;
      font-size: 14px;
      color: $service-bar-link-color;
      font-family: karla;
      transition-property: color, border-color;
      transition-duration: .35s;
      transition-timing-function: ease-in-out;
      &:hover {
        border-color: $topbar-link-color-hover;
        color: $topbar-link-color-hover;
      }
    }
  }
}

