// Drupal-specific stuff.
// Since we're removing all Drupal core CSS files, here we can manually
// re-add what's important and tweak contrib styles, etc.

// Hide admin menu on medium and smaller.
#admin-menu {
  @extend .hide-for-medium-down;
}

// Remove Admin Menu's page margin on medium screens.
html body.admin-menu {
  margin-top: 0 !important;

  @media #{$medium} {
    margin-top: 29px !important;
  }
}

html body.admin-menu-with-shortcuts {
  margin-top: 0 !important;

  @media #{$medium} {
    margin-top: 65px !important;
  }
}

// Make the "sticky" Top Bar play nice with the "fixed" Admin Menu.
.admin-menu .fixed {
  top: emCalc(29px);
}

// Override Drupal Core pager styles. This is necessary because we need to keep
// the .pager class on pagers for Views AJAX to work. If you are disabling
// Drupal Core CSS you can safely comment the following lines.
.item-list .pager {
  clear: none;
  margin: 15px auto;
}

.item-list .pager li {
  padding: 0;
}

/**
 * For anything you want to hide on page load when JS is enabled, so
 * that you can use the JS to control visibility and avoid flicker.
 */
html.js .js-hide {
  display: none;
}

/**
 * Hide elements from all users.
 *
 * Used for elements which should not be immediately displayed to any user. An
 * example would be a collapsible fieldset that will be expanded with a click
 * from a user. The effect of this class can be toggled with the jQuery show()
 * and hide() functions.
 */
.element-hidden {
  display: none;
}

/**
 * Hide elements visually, but keep them available for screen-readers.
 *
 * Used for information required for screen-reader users to understand and use
 * the site where visual display is undesirable. Information provided in this
 * manner should be kept concise, to avoid unnecessary burden on the user.
 * "!important" is used to prevent unintentional overrides.
 */
.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}

/**
 * The .element-focusable class extends the .element-invisible class to allow
 * the element to be focusable when navigated to via the keyboard.
 */
.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto;
}

/**
 * Autocomplete.
 *
 * @see autocomplete.js
 */
/* Suggestion list */
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
  zoom: 1; /* IE7 */
}

/* Animated throbber */
html.js input.form-autocomplete {
  background-image: url(/misc/throbber.gif);
  background-position: 100% 2px; /* LTR */
  background-repeat: no-repeat;
}

html.js input.throbbing {
  background-position: 100% -18px; /* LTR */
}

/**
 * Progress behavior.
 *
 * @see progress.js
 */
/* Bar */
.progress .bar {
  background-color: #fff;
  border: 1px solid;
}

.progress .filled {
  background-color: #000;
  height: 1.5em;
  width: 5px;
}

.progress .percentage {
  float: right; /* LTR */
}

/* Throbber */
.ajax-progress {
  display: inline-block;
}

.ajax-progress .throbber {
  background: $transparent url(/misc/throbber.gif) no-repeat 0px -18px;
  float: left; /* LTR */
  height: 15px;
  margin: 2px;
  width: 15px;
}

.ajax-progress .message {
  padding-left: 20px;
}

tr .ajax-progress .throbber {
  margin: 0 2px;
}

.ajax-progress-bar {
  width: 16em;
}

/**
 * Contextual links regions.
 */
.contextual-links-region {
  outline: none;
  position: relative;
}

.contextual-links-region-active {
  outline: #999 dashed 1px;
}

/**
 * Contextual links.
 */
a.contextual-links-trigger {
  background: $transparent url("/modules/contextual/images/gear-select.png") no-repeat 2px 0;
  border: 1px solid $transparent;
  display: none;
  height: 18px;
  margin: 0;
  padding: 0 2px;
  outline: none;
  text-indent: 34px;
  width: 28px;
  overflow: hidden;
  border-radius: 4px;

  .contextual-links-region:hover & {
    display: block;
  }

  div.contextual-links-active & {
    display: block;
    background-position: 2px -18px;
    background-color: #fff;
    border-color: #ccc;
    border-bottom: none;
    position: relative;
    z-index: 1;
    border-radius: 4px 4px 0 0;
  }

  &:hover {
    background-position: 2px -18px;
  }
}

div.contextual-links-wrapper {
  display: none;
  font-size: 90%;
  position: absolute;
  right: 5px;
  top: 2px;
  z-index: 999;

  html.js & {
    display: block;
  }

  a {
    text-decoration: none;
  }

  ul.contextual-links {
    background-color: #fff;
    border: 1px solid #ccc;
    display: none;
    margin: 0;
    padding: 0.25em 0;
    position: absolute;
    right: 0;
    text-align: left;
    top: 18px;
    white-space: nowrap;
    border-radius: 4px 0 4px 4px;

    div.contextual-links-active & {
      display: block;
    }

    li {
      line-height: 100%;
      list-style: none;
      list-style-image: none;
      margin: 0;
      padding: 0;

      a {
        color: #333 !important;
        display: block;
        margin: 0.25em 0;
        padding: 0.25em 1em 0.25em 0.5em;
        // This was added by myself.
        text-shadow: 0 0 0 $transparent;
        font-family: sans-serif;
        font-size: 13px;
        font-weight: normal !important;

        &:hover {
          background-color: #bfdcee;
        }
      }
    }
  }
}

// We always want our exposed form submit buttons to consume 100% and by default be displayed as blocks.
.views-submit-button button {
  display: block;
  height: $form-component-height;
  line-height: 0;
  width: 100%;
}

// Masquerade block.
.block-masquerade-masquerade {
  @include grid-column(3);

  a {
    color: $secondary-color;
  }
}
