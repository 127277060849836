/// Default Font Stacks ///

// The following font family declarations are based on the Microsoft core web
// fonts which are common fonts available on most computer systems. The DejaVu
// and Nimbus Sans fonts are commonly available on Linux systems where the MS
// fonts are less common. Tahoma and Helvetica are also widely available.
//
// A user's web browser will look at the comma-separated list and will
// attempt to use each font in turn until it finds one that is available
// on the user's computer. The final "generic" font (sans-serif, serif or
// monospace) hints at what type of font to use if the web browser doesn't
// find any of the fonts in the list.

// First, let's create some font stacks.
$times-new-roman: "Times New Roman", Times, Georgia, "DejaVu Serif", serif;
$times: Times, "Times New Roman", Georgia, "DejaVu Serif", serif;
$georgia: Georgia, "Times New Roman", "DejaVu Serif", serif;
$verdana: Verdana, Tahoma, "DejaVu Sans", sans-serif;
$tahoma: Tahoma, Verdana, "DejaVu Sans", sans-serif;
$helvetica: 'Helvetica Neue', Helvetica, Helvetica, Arial, sans-serif;
$arial: Arial, Helvetica, "Nimbus Sans L", sans-serif;
$lucida: 'Lucida Grande', Arial, sans-serif;

// For an explanation of why "sans-serif" is at the end of this list, see
// http://meyerweb.com/eric/thoughts/2010/02/12/fixed-monospace-sizing/
$consolas: "Consolas", "Bitstream Vera Sans Mono", "Courier New", "DejaVu Sans Mono", Courier, monospace, sans-serif;

/*
@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/pt-sans/PTSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/pt-sans/PTSans-Regular.woff') format('woff'),
  url('../fonts/pt-sans/PTSans-Regular.ttf')  format('truetype'),
  url('../fonts/pt-sans/PTSans-Regular.svg#PTSans-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/pt-sans/PTSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/pt-sans/PTSans-Italic.woff') format('woff'),
  url('../fonts/pt-sans/PTSans-Italic.ttf')  format('truetype'),
  url('../fonts/pt-sans/PTSans-Italic.svg#PTSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/pt-sans/PTSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/pt-sans/PTSans-BoldItalic.woff') format('woff'),
  url('../fonts/pt-sans/PTSans-BoldItalic.ttf')  format('truetype'),
  url('../fonts/pt-sans/PTSans-BoldItalic.svg#PTSans-BoldItalic') format('svg');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'PT Sans';
  src: url('../fonts/pt-sans/PTSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/pt-sans/PTSans-Bold.woff') format('woff'),
  url('../fonts/pt-sans/PTSans-Bold.ttf')  format('truetype'),
  url('../fonts/pt-sans/PTSans-Bold.svg#PTSans-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}
*/
/* karla-regular - latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../fonts/karla/karla-v22-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/karla/karla-v22-latin-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
  unicode-range: U+000-5FF; /* Download only latin glyphs */
}

/* karla-700 - latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('../fonts/karla/karla-v22-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/karla/karla-v22-latin-700.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
  unicode-range: U+000-5FF;
  /* Download only latin glyphs */
}

/* karla-italic - latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 400;
  src: local(''),
    url('../fonts/karla/karla-v22-latin-italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/karla/karla-v22-latin-italic.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
  unicode-range: U+000-5FF;
  /* Download only latin glyphs */
}

/* karla-700italic - latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 700;
  src: local(''),
    url('../fonts/karla/karla-v22-latin-700italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/karla/karla-v22-latin-700italic.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
  unicode-range: U+000-5FF;
  /* Download only latin glyphs */
}

/* lora-regular - latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../fonts/lora/lora-v24-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/lora/lora-v24-latin-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
  unicode-range: U+000-5FF;
  /* Download only latin glyphs */
}

/* lora-italic - latin */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 400;
  src: local(''),
    url('../fonts/lora/lora-v24-latin-italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/lora/lora-v24-latin-italic.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
  unicode-range: U+000-5FF;
  /* Download only latin glyphs */
}

// Global google font setup.
// See line 23 of template.tpl.php to set Google font
$head-gf: 'Lora', serif;
$body-gf: 'Karla', sans-serif;
