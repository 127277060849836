rc-riot-avail-filter,
[riot-tag="rc-riot-avail-filter"],
[data-is="rc-riot-avail-filter"] {

  //Global
  body & {
    .submit-wrap {
      input {
        @include vrweb-button($submit-bg, $display: block);
        margin-bottom: 0;
        width: 100%;
        transition: .3s;
        border: 0px;
        display: block;
        box-shadow: 0 0 0 3px $submit-bg-desktop, 0 0px 5px $submit-bg-desktop;
        padding: 8px 0;
        background-color: $submit-bg-desktop;
        &:hover,
        &:active {
          background-color: $submit-bg-hover-desktop;
          box-shadow: 0 0 0 3px $submit-bg-hover-desktop, 0 0px 5px $submit-bg-hover-desktop;
        }
      }
    }
    .flex-select{
      width:100%;
      padding-right:0;
    }
  }

  // Homepage Quick Search
  body.front & {
    form {
      margin: 0 auto;
    }

    @if $qs-display-flush == true {
      @media #{$small} {
        &,
        &.has-travelers {
          .input-wrap {
            padding-right: 10px;

            input,
            select {
              border-radius: 0;
              border: 0px;
              @include box-shadow();
            }
          }
        }
      }
    }
  }

  //Basic Page Qucik Search
  .sidebar-second & {
    position: relative;
    padding-top:0;

    .input-wrap input{
      background-image:url('../images/form-icon-sidebar-datepicker.svg');
      font-size: 14px !important;
      @include placeholder{
        font-size: 14px !important;
      }
    }

  }
  //Search List
  #vrweb-search & {
    padding: 15px;

    @media #{$small} {
      @include min(both);

      .input-wrap {
        padding: 0 5px 15px;

        &:nth-of-type(2n+1) {
          padding-left: 0;
        }

        &:nth-of-type(2n) {
          padding-right: 0;
        }
      }
    }
  }

  //Main Search
  body riot-solr-search .search-header &,
  body [riot-tag="riot-solr-search"] .search-header &,
  body [data-is="riot-solr-search"] .search-header & {
    padding-bottom: 15px;

    form {
      margin-bottom: 0;
    }

    @media #{$small} {
      height: 70px;

      &.has-travelers {
        .rc-riot-avail-filter-form .input-wrap {
          width: 24%;
          @include collapse(left);

          input,
          select {
            border-radius: 0;
            border-right: none;
            @include box-shadow();
          }

          &:first-of-type {
            input {
              border-top-left-radius: $global-radius;
              border-bottom-left-radius: $global-radius;
            }
          }
        }
        .submit-wrap {
          @include collapse(both);
          margin-left: 3px;

          input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            @include min(both);
            @include box-shadow();
          }
        }
      }
    }

    @media #{$medium} {
      height: 80px;
      padding-top: 18px;

      .input-wrap,
      .submit-wrap {

        input,
        select {
          .front & {
            height: 44px;
          }
        }
      }
    }
    @media only screen and (min-width: emCalc(1150)) {
      width: 56%;
    }
  }
}
