body.page-riottags-vrw-search-list {
  //advanced search off canvas behavior

  .off-canvas-trigger {
    display: none;
    left: 0px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;

    @media #{$small-down} {
      display: block;
    }

    a {
      display: block;
      height: $trigger-trigger-height;
      background: $success-color;
      background: linear-gradient(lighten($trigger-button-color, 5%), darken($trigger-button-color, 5%) 10%, $trigger-button-color 90%, darken($trigger-button-color, 10%));
      background: $trigger-button-color;
      border-color: darken($trigger-button-color, 5%);
      border-width: 1px 2px 3px 2px;
      border-style: solid;
      text-align: center;
      color: $white;
      font-size: 20px;
      text-transform: uppercase;
      padding-top: 4px;
      font-weight: 700;
      letter-spacing: normal;
      letter-spacing: .05em;
      transition: none;
      width: 100%;
      border-radius: 3px;
      height: $trigger-trigger-height - 5px;
      margin: 8px auto 0px auto;
      text-decoration: none;

      &:hover, &:active {
        border-color: $trigger-button-color-active;
        background: $trigger-button-color-active;
        text-decoration: none;
        color: $white;
      }

      &:before {
        content: '';
        display: inline-block;
        width: 30px;
        height: 35px;
        background-image: url("../images/icon-x-white.svg");
        background-size: 20px;
        background-position: 5px center;
        background-repeat: no-repeat;
        margin-right: 5px;
        margin-bottom: -10px;
        transform: rotate(45deg);
        transition: transform .15s ease-out;

        .show-search & {

        }
      }

      i {
        display: inline-block;
        width: 30px;
        height: 12px;
        background: rgba(0, 0, 0, .2) url("../images/more-actions.svg") center no-repeat;
        margin-left: 8px;
        background-size: 23px auto;
        border-radius: 2px;
      }
    }
  }

  .show-search {
    .off-canvas-trigger {
      background: $trigger-button-color-active;
      @include min(both);
      padding-top: 0;

      a {
        background: $trigger-button-color-active;
        border-color: $trigger-button-color-active;
        color: $white;
        border-radius: 0px;

        &:hover, &:active {
          background: $trigger-button-color-active;
          color: $white;
        }

        i {
          width: 0px;
        }

        &:before {
          background-image: url("../images/icon-x-white.svg");
          background-size: 20px;
          background-position: 5px center;
          transform: rotate(180deg);
        }
      }
    }
  }

  @media #{$small} {
    // .main.columns {
    // 	padding-left:15px !important;
    // 	padding-right:15px !important;
    // }
  }
}

//search list behavior
#vrweb-search {
  @include grid-row();
  padding-bottom: 20px;

  @media #{$small} {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media #{$small-down} {
    riot-solr-container.search-filters,
    [riot-tag="riot-solr-container"].search-filters,
    [data-is="riot-solr-container"].search-filters {
      background: none;
      transition-property: all;
      transition-duration: .5s;
      transform: rotateX(-90deg);
      transform-origin: top;
      animation-timing-function: ease-in-out;
      height: 0;
      overflow: hidden;
      background: $search-list-widget-bg;
      padding: 0;
    }

    .show-search & {
      riot-solr-container.search-filters,
      [riot-tag="riot-solr-container"].search-filters,
      [data-is="riot-solr-container"].search-filters {
        height: auto;
        transform: rotateX(0deg);
        overflow:visible; 
      }

      riot-solr-facet-group,
      [riot-tag="riot-solr-facet-group"],
      [data-is="riot-solr-facet-group"] {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .search-utils {
      //background:$muted-color;
      @include clearfix();
      @include box-shadow();
    }
  }

  @media #{$small} {
    riot-solr-container.search-filters,
    [riot-tag="riot-solr-container"].search-filters,
    [data-is="riot-solr-container"].search-filters {
      background: $search-list-widget-bg-desktop;
      width: 33.333%;
      //margin-left:1%;
      float: left;
      position: relative;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: $global-radius;
      margin-top: 15px;
      box-shadow: 0px 1px 4px rgba($black, .3);
      text-align: center;

      &:before {
        content: 'Refine Search';
        display: inline-block;
        width: auto;
        text-align: center;
        padding: 10px 10px 0px 23px;
        font-size: 20px;
        background: url("../images/icon-search-black.svg") left 14px no-repeat;
        background-size: auto 20px;
      }

      riot-solr-facet-group,
      [riot-tag="riot-solr-facet-group"],
      [data-is="riot-solr-facet-group"] {
        text-align: left;
      }
    }

    riot-solr-result-list,
    [riot-tag="riot-solr-result-list"],
    [data-is="riot-solr-result-list"],
    .search-utils {
      @include grid-column(8);
      float: right;
      clear: right;
      padding-left: 25px;
      padding-right: 0;
      padding-top: 0;
    }

    .search-utils {
      padding-top: 15px;
    }

    riot-solr-result-list,
    [riot-tag="riot-solr-result-list"],
    [data-is="riot-solr-result-list"] {
      //background:green;
    }
  }

  @media #{$medium} {
    riot-solr-container.search-filters,
    [riot-tag="riot-solr-container"].search-filters,
    [data-is="riot-solr-container"].search-filters {
      width: 25%;
    }

    riot-solr-result-list,
    [riot-tag="riot-solr-result-list"],
    [data-is="riot-solr-result-list"],
    .search-utils {
      width: 75%;
    }
  }

  &.map-active {
    max-width: none;

    @media #{$small-down} {
      padding-bottom: 0;
    }
    riot-solr-container.search-filters,
    [riot-tag="riot-solr-container"].search-filters,
    [data-is="riot-solr-container"].search-filters {
      @media #{$small} {
        position: absolute;
        top: 0px;
        bottom: 20px;
      }
    }
  }
}

.map-wrapper {
  position: relative;

  @media #{$small} {
    @include grid-column(8);
    float: right;
    clear: none !important;
    padding-right: 0;
    padding-left: 25px;
  }

  @media #{$medium} {
    width: 75%;
    clear: none !important;
  }

  #vrweb-search & {
    riot-solr-result-map,
    [riot-tag="riot-solr-result-map"],
    [data-is="riot-solr-result-map"],
    .map-results,
    .map-results > div,
    .gm-style {
      width: 100%;
      height: 500px !important;

      @media #{$small} {
        height: $map-min-height-desktop !important;
      }
    }
  }
  #vrweb-search:not(.map-active) & {
    display: none;
  }

  #vrweb-search.map-active & {
    display: block;
  }
}