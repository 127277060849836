//
// Section Variables
//
$include-html-section-classes: $include-html-classes !default;

// We use these to set padding and hover factor
$section-title-padding: em-calc(15) !default;
$section-content-padding: em-calc(15) !default;
$section-function-factor: 10% !default;

// These style the titles
$section-title-color: #333 !default;
$section-title-color-active: #333 !default;
$section-title-bg: #efefef !default;
$section-title-bg-active: darken($section-title-bg, $section-function-factor) !default;
$section-title-bg-active-tabs: #fff !default;
$section-title-bg-hover: darken($section-title-bg, $section-function-factor / 2) !default;

// Want to control border size, here ya go!
$section-border-size: 1px !default;
$section-border-style: solid !default;
$section-border-color: #ccc !default;

// Font controls
$section-font-size: em-calc(14) !default;

// Control the color of the background and some size options
$section-content-bg: #fff !default;
$section-vertical-nav-min-width: em-calc(200) !default;
$section-vertical-tabs-title-width: em-calc(200) !default;
$section-bottom-margin: em-calc(20) !default;

$title-selector: ".title" !default;
$content-selector: ".content" !default;
$active-region-selector: ".active" !default;

//
// Section Mixins
//

// basic styles for sections.js: positions
@mixin section-container-base($section-type: accordion) {
  width: 100%;
  position: relative;
  display: block;
  margin-bottom: $section-bottom-margin;

  @if $section-type != accordion {
    &:not([data-section-resized]):not([data-section-small-style]) {
      visibility: hidden;
    }
  }

  @if $section-type != tabs {
    &[data-section-small-style] {
      width: 100% !important; // override inline style

      & > [data-section-region], & > section, & > .section {
        padding: 0 !important; // override inline style
        margin: 0 !important; // override inline style
        & > [data-section-title], & > #{$title-selector} {
          width: 100% !important; // override inline style
        }
      }
    }
  }
}

// foundation styles for sections
@mixin section-container-style($section-type: accordion) {

  // Accordion container border styles
  @if $section-type == accordion {
    border-top: $section-border-size $section-border-style $section-border-color;
  }

    // Tabs container border styles
  @else if $section-type == tabs {
    border: none;
  }

    // Vertical Tabs container border styles
  @else if $section-type == vertical-tabs {
    border: none;
  }

    // Vertical Nav container border styles
  @else if $section-type == vertical-nav {
    border: none;
  }

    // Horizontal Nav container border styles
  @else if $section-type == horizontal-nav {
    background: $section-title-bg;
    border: $section-border-size $section-border-style $section-border-color;
  }
}

//basic styles for section.js: section region positions
@mixin section-base($section-type: accordion) {

  & > [data-section-title], & > #{$title-selector} {
    margin-bottom: 0;
    a {
      width: 100%;
      display: inline-block;
      white-space: nowrap;
    }
  }

  & > [data-section-content], & > #{$content-selector} {
    display: none;
  }

  &#{$active-region-selector} {
    & > [data-section-content], & > #{$content-selector} {
      display: block;
    }
  }

  &:not(#{$active-region-selector}) {
    padding: 0 !important; //override inline style
  }

  // Style for when Accordion
  @if $section-type == accordion {
    margin: 0;
    & > [data-section-title], & > #{$title-selector} {
      width: 100%;
    }
  }

    // Styles for when tabs
  @else if $section-type == tabs {

    & > [data-section-title], & > #{$title-selector} {
      width: auto;
      position: absolute;
      top: 0;
      #{$default-float}: 0;
    }
  }

    // Styles for when Vertical Tabs
  @else if $section-type == vertical-tabs {

    & > [data-section-title], & > #{$title-selector} {
      position: absolute;
      top: 0;
      #{$default-float}: 0;
      width: $section-vertical-tabs-title-width;
    }

    &#{$active-region-selector} {
      padding-#{$default-float}: $section-vertical-tabs-title-width;

      & > [data-section-title], & > #{$title-selector} {
        width: $section-vertical-tabs-title-width;
      }
    }
  }

    // Styles for when Vertical Nav
  @else if $section-type == vertical-nav {

    position: relative;
    display: inline-block;

    & > [data-section-title], & > #{$title-selector} {
      position: static;
      width: auto;
      a {
        display: block;
      }
    }

    & > [data-section-content], & > #{$content-selector} {
      position: absolute;
      top: 0;
      #{$default-float}: 0;
      z-index: 999;
      min-width: $section-vertical-nav-min-width;
    }
  }

    // Styles for when Horizontal Nav
  @else if $section-type == horizontal-nav {

    position: relative;
    float: #{$default-float};

    & > [data-section-title], & > #{$title-selector} {
      position: static;
      width: auto;
      a {
        display: block;
      }
    }

    & > [data-section-content], & > #{$content-selector} {
      width: auto;
      position: absolute;
      top: 0;
      #{$default-float}: 0;
      z-index: 999;
      min-width: $section-vertical-nav-min-width;
    }
  }
}

//foundation styles for section regions
@mixin section-style($section-type:accordion, $title-selector: $title-selector, $content-selector: $content-selector, $title-padding:$section-title-padding, $title-color:$section-title-color, $title-font-size:$section-font-size, $title-bg:$section-title-bg, $title-bg-hover:$section-title-bg-hover, $title-bg-active:$section-title-bg-active, $title-color-active:$section-title-color-active, $content-bg:$section-content-bg, $content-padding:$section-content-padding, $section-border-size:$section-border-size, $section-border-style:$section-border-style, $section-border-color: $section-border-color) {

  // Default Styles
  & > #{$title-selector} {
    background-color: $title-bg;
    cursor: $cursor-pointer-value;
    border: $section-border-style $section-border-size $section-border-color;
    a {
      padding: $title-padding;
      color: $title-color;
      font-size: $title-font-size;
      background: none;
    }
    &:hover {
      background-color: $title-bg-hover;
    }
  }

  & > #{$content-selector} {
    padding: $content-padding;
    background-color: $content-bg;
    border: $section-border-style $section-border-size $section-border-color;

    & > *:last-child {
      margin-bottom: 0;
    }
    & > *:first-child {
      padding-top: 0;
    }
    & > *:last-child:not(.flex-video) {
      padding-bottom: 0;
    }
  }

  &#{$active-region-selector} {
    & > #{$title-selector} {
      background: $title-bg-active;
      a {
        color: $title-color-active;
      }
    }
  }

  &:not(#{$active-region-selector}) {
    padding: 0 !important; //override inline style
  }

  // Style for when Accordion
  @if $section-type == accordion {
    & > #{$title-selector} {
      border-top: none;
    }
  }

    // Styles for when tabs
  @else if $section-type == tabs {

    &#{$active-region-selector} > #{$title-selector} {
      border-bottom: 0;
    }
  }

    // Styles for when Vertical Tabs
  @else if $section-type == vertical-tabs {

    &#{$active-region-selector} {
      padding-#{$default-float}: $section-vertical-tabs-title-width - em-calc(1px);

      & > #{$title-selector} {
        background-color: $title-bg-active;
      }
    }
  }
}

@mixin section-container($base-style:true, $section-type:accordion) {
  @include section-container-base($section-type);
  @include section-container-style($section-type);
}

@mixin section($section-type:accordion, $title-selector:$title-selector, $content-selector: $content-selector, $title-padding:$section-title-padding, $title-color:$section-title-color, $title-font-size:$section-font-size, $title-bg:$section-title-bg, $title-bg-hover:$section-title-bg-hover, $title-bg-active:$section-title-bg-active, $title-color-active:$section-title-color-active, $content-bg:$section-content-bg, $content-padding:$section-content-padding, $section-border-size:$section-border-size, $section-border-style:$section-border-style, $section-border-color: $section-border-color) {
  @include section-base($section-type);
  @include section-style($section-type, $title-selector, $content-selector, $title-padding, $title-color, $title-font-size, $title-bg, $title-bg-hover, $title-bg-active, $title-color-active, $content-bg, $content-padding, $section-border-size, $section-border-style, $section-border-color);
}

@if $include-html-section-classes != false {

  /* Sections */

  [data-section=''], [data-section='auto'], .section-container.auto,
  [data-section='vertical-tabs'], .section-container.vertical-tabs,
  [data-section='vertical-nav'], .section-container.vertical-nav,
  [data-section='horizontal-nav'], .section-container.horizontal-nav,
  [data-section='accordion'], .section-container.accordion {
    @include section-container-base(accordion);
    & > section, & > .section, & > [data-section-region] {
      @include section-base(accordion);
    }
  }

  .section-container.auto,
  .section-container.vertical-tabs,
  .section-container.vertical-nav,
  .section-container.horizontal-nav,
  .section-container.accordion {
    @include section-container-style(accordion);
    & > section, & > .section {
      @include section-style(accordion);
    }
  }

  [data-section='tabs'], .section-container.tabs {
    @include section-container-base(tabs);
    & > section, & > .section, & > [data-section-region] {
      @include section-base(tabs);
    }
  }

  .section-container.tabs {
    @include section-container-style(tabs);
    & > section, & > .section {
      @include section-style(tabs, $title-bg-active: $section-title-bg-active-tabs);
    }
  }

  @media #{$small} {

    [data-section=''], [data-section='auto'], .section-container.auto {
      @include section-container-base(tabs);
      & > section, & > .section, & > [data-section-region] {
        @include section-base(tabs);
      }
    }

    .section-container.auto {
      @include section-container-style(tabs);
      & > section, & > .section {
        @include section-style(tabs, $title-bg-active: $section-title-bg-active-tabs);
      }
    }

    [data-section='vertical-tabs'], .section-container.vertical-tabs {
      @include section-container-base(vertical-tabs);
      & > section, & > .section, & > [data-section-region] {
        @include section-base(vertical-tabs);
      }
    }

    .section-container.vertical-tabs {
      @include section-container-style(vertical-tabs);
      & > section, & > .section {
        @include section-style(vertical-tabs);
      }
    }

    [data-section='vertical-nav'], .section-container.vertical-nav {
      @include section-container-base(vertical-nav);
      & > section, & > .section, & > [data-section-region] {
        @include section-base(vertical-nav);
      }
    }

    .section-container.vertical-nav {
      @include section-container-style(vertical-nav);
      & > section, & > .section {
        @include section-style(vertical-nav);
      }
    }

    [data-section='horizontal-nav'], .section-container.horizontal-nav {
      @include section-container-base(horizontal-nav);
      & > section, & > .section, & > [data-section-region] {
        @include section-base(horizontal-nav);
      }
    }

    .section-container.horizontal-nav {
      @include section-container-style(horizontal-nav);
      & > section, & > .section {
        @include section-style(horizontal-nav);
      }
    }
  }

  // Change all variations to expanded accordions when no JS is detected.
  .no-js {
    [data-section], .section-container {
      @include section-container-base(accordion);
      & > section, & > .section, & > [data-section-region] {
        @include section-base(accordion);
      }
    }
    .section-container {
      @include section-container-style(accordion);
      & > section, & > .section {
        @include section-style(accordion);
      }
    }
  }
}
