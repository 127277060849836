rc-riot-result-list-item,
[riot-tag="rc-riot-result-list-item"],
[data-is="rc-riot-result-list-item"] {
  body & {
    > .tag-content {
      background: $result-list-item-bg;
      text-align: center;

      &:hover,
      &:active {
        background: $result-list-item-active-bg;
      }

      a.image-wrap {
        padding-top: $result-list-item-image-padding-ratio;
        background: $result-list-item-noimg-bg url("../images/icon-missing-image.png") center no-repeat;
        background-size: auto 30%;
      }

      .banner-wrapper {
        background-color: $primary-color;
        margin-top: -30px;
        z-index: 1;
        position: relative;

        h4 {
          color: $white;
          font-weight: normal;
          text-transform: capitalize;
        }
      }

      h4 {
        @include teaser-titles($alt-color: true);
      }

      .image-wrapper {
        position: relative;
      }
    }
  }

  //Main Search
  riot-solr-result-list &,
  [riot-tag="riot-solr-result-list"] &,
  [data-is="riot-solr-result-list"] & {

    riot-solr-search &,
    [riot-tag="riot-solr-search"] &,
    [data-is="riot-solr-search"] & {

      > .tag-content {
        padding-bottom: 0;
        box-shadow: none !important;

        &:hover,
        &:active {
          background: $result-list-item-active-bg;

          rc-riot-avail-pricing,
          [riot-tag="rc-riot-avail-pricing"],
          [data-is="rc-riot-avail-pricing"] {
            background: $result-list-item-pricing-active-bg;
          }
        }
      }

      .rc-details {
        padding-bottom: 8px;
      }

      @media only screen and (min-width: emCalc(450)) {
        riot-solr-flag,
        [riot-tag="riot-solr-flag"],
        [data-is="riot-solr-flag"] {
          right: 13px;
          top: 14px;
          .flag-inactive,
          .flag-active {
            i {
	          	//background-color: $black;
	          	//background-size: auto 23px;
              //width: 35px;
              //height: 35px;
	          }
		      }
          .flag-active {
            i {
	          	//background-color: $white;
			      }
		      }
        }
      }
      @media only screen and (min-width: emCalc(450)) and (max-width: emCalc(1249)) {

        &,
        &:nth-of-type(2n),
        &:nth-of-type(2n+1) {
          @include grid-column(6);
          padding-left: 8px;
          padding-right: 8px;

          &:nth-of-type(2n+1) {
            clear: both;
          }
        }
      }
      @media only screen and (min-width: emCalc(1250)) {
        &:nth-of-type(2n+1),
        &:nth-of-type(2n) {
          clear: none;
          padding-left: 8px;
          padding-right: 8px;
        }
      }
      @media only screen and (min-width: emCalc(1250)) and (max-width: emCalc(1999)) {
        @include grid-column(4);
        padding-left: 8px;
        padding-right: 8px;
        clear: none;

        &:nth-of-type(3n+1) {
          clear: both;
        }
      }

      @media only screen and (min-width: emCalc(2000)) {
        @include grid-column(3);
        padding-left: 8px;
        padding-right: 8px;

        &:nth-of-type(4n+1) {
          clear: both;
        }
      }
    }

    //List Active Styles
    riot-solr-search.list-active &,
    [riot-tag="riot-solr-search"].list-active &,
    [data-is="riot-solr-search"].list-active & {
      @media #{$medium} and (max-width: emCalc(1549)) {
        @include grid-column(4);
        padding-left: 8px;
        padding-right: 8px;
        clear: none;

        &:nth-of-type(2n+1) {
          clear: none;
        }
        &:nth-of-type(3n+1) {
          clear: both;
        }
      }

      @media only screen and (min-width: emCalc(1550)) and (max-width: emCalc(1999)) {
        @include grid-column(3);
        padding-left: 8px;
        padding-right: 8px;

        &:nth-of-type(3n+1) {
          clear: none;
        }

        &:nth-of-type(4n+1) {
          clear: both;
        }
      }

      @media only screen and (min-width: emCalc(2000)) {
        width: 20%;
        float: left;
        position: relative;
        padding-left: 8px;
        padding-right: 8px;

        &:nth-of-type(3n+1),
        &:nth-of-type(4n+1) {
          clear: none;
        }

        &:nth-of-type(5n+1) {
          clear: both;
        }
      }
    }
  }

  //Map
  riot-solr-result-map &,
  [riot-tag="riot-solr-result-map"] &,
  [data-is="riot-solr-result-map"] & {
    min-width: 215px;
    padding: 5px 0 0 5px;

    @media #{$tiny} {
      min-width: 250px;
    }

    riot-solr-flag,
    [riot-tag="riot-solr-flag"],
    [data-is="riot-solr-flag"] {
      right: 13px;
      top: 15px;
      @media #{$small} {
        right: 5px;
        top: 25px;
      }
      .flag-inactive,
      .flag-active {
        i {
          background: $black url(/sites/all/themes/custom/vrweb_foundation/images/icon-heart-empty.svg) center no-repeat;
          background-size: auto 23px;
          width: 35px;
          height: 35px;
	      }
		  }
      .flag-active {
        i {
          background: $white url(/sites/all/themes/custom/vrweb_foundation/images/icon-heart-full.svg) center no-repeat;
          background-size: auto 23px;

			  }
		  }
    }

    > .tag-content {
      background: none;

      h4 {
        padding-left: 0;
        padding-right: 0;
        //margin-bottom:8px;
      }

      .rc-details {
        padding-left: 0;

        .rc-detail {
          font-size: 13px;
          font-family: $body-gf;
          font-weight: 400;
          display: inline-block;
          width: auto;
          line-height: 1.0;
          margin-bottom: 7px;

          + .rc-detail {
            border-left: solid 1px;
            padding-left: 5px;
            margin-left: 3px;
          }
        }
      }
    }

    riot-solr-search.both-active &,
    [riot-tag="riot-solr-search"].both-active &,
    [data-is="riot-solr-search"].both-active & {
      @media #{$small} and (max-width: emCalc(885)) {
        padding-left: 0;
        padding-top: 0;
        min-width: 205px;

        > .tag-content {
          .rc-details .rc-detail {
            font-size: 12px;
          }
        }
      }
    }
  }
}

