//=================================================================
//    SECTION TABS 
//    Style and Variables
//    See _vrweb-settings for all section variables.
//=================================================================

//Set Backgrounds as necessary for view wrapper//
.search-tabs, .group-vr-full-tabs {
  &.search-tabs, &.group-vr-full-tabs {
    background: $transparent;
  }

  &.search-tabs {
    padding-top: 30px;
  }

  /*These should stay consistent unless there
  is a strong case for custom styling*/
  .section-container,
  .section-container.auto,
  .section-container.tabs {
    background: $sectiontab-desktop-container-bg;

    .front & {
      margin-bottom: 0px;
    }

    > section {
      background: $sectiontab-desktop-bg;

      .front & {
        padding-top: 0 !important;
      }
      //-----CSTM: remove if active popular searches

      .content {
        background: $transparent;
        border: none !important;
        //.front &{}//-----CSTM: can update for homepage slideshow if needed
      }
      .title {
        background: $transparent;
        padding-right: 5px;
        border: none;

        @media #{$small-down}{
          padding-left: 7px;
          padding-right: 7px;
          margin-bottom: 5px;
        }

        a {
          transition-property: color, background;
          transition-duration: .35s;
          transition-timing-function: ease-in-out;
          border-radius: $global-radius;
          background: $sectiontab-title-bg;
          color: $sectiontab-title-color;
          font-size: 16px;
          padding: 10px;

          @media #{$small} {
            background: $sectiontab-desktop-title-bg;
            color: $sectiontab-desktop-title-color;
            font-size: 15px;
            text-decoration: none;
            text-transform: uppercase;
            font-family: $head-gf;
            font-weight: 500;
            letter-spacing: 1px;
            padding: 12px 5px;
          }
        }
        &:hover, &:active {
          background: $transparent;

          a {
            text-decoration: none;
            color: $sectiontab-title-color;
            background: $sectiontab-title-bg-over;

            @media #{$small} {
              color: $sectiontab-desktop-title-color;
              background: $sectiontab-desktop-title-bg-over;
            }
          }
        }
      }
      //default title styles
      &.active {
        .title {
          background: $transparent;
          border: none;

          @media #{$small-down}{
            margin-bottom: 0;
          }

          @media #{$small} {
            &:after {
              content: '';
              display: block;
              width: 0px;
              height: 0px;
              @include absolute-center($top: auto, $bottom: -24px);
              border-style: solid;
              border-width: 12px;
              border-color: $sectiontab-desktop-active-title-bg $transparent $transparent $transparent; //drop in color if the variable is an image or gradient
            }
          }

          //	.front &{background:$transparent;}//-----custom style

          a {
            background: $sectiontab-active-title-bg;
            color: $sectiontab-active-title-color;

            @media #{$small} {
              background: $sectiontab-desktop-active-title-bg;
              color: $sectiontab-desktop-active-title-color;
            }
          }
          &:hover, &:active {
            //.front &{background:$transparent;}//-----custom style

            a {
              color: $sectiontab-active-title-color;
              cursor: default;
              background: $sectiontab-active-title-bg-over;

              @media #{$small} {
                color: $sectiontab-desktop-active-title-color;
                background: $sectiontab-desktop-active-title-bg-over;
              }
            }
          }
        }
      }
      //section.active
    }
    // default section styles
  }
  &.group-vr-full-tabs {
    .section-container > section {
      .content {
        @include min(both);
      }
    }
    @media #{$small-down}{
      .section-container > section {
        .title {
          padding-left: 0px;
          padding-right: 0px;
          text-align: left;

          a {
            position: relative;
            text-align: left;

            &:before {
              content: '';
              display: block;
              width: 16px;
              height: 2px;
              background: $sectiontab-title-color;
              position: absolute;
              margin: auto;
              top: 0px;
              bottom: 0px;
              right: 15px;
              transition: all .15s linear;
            }
            &:after {
              content: '';
              display: block;
              width: 16px;
              height: 2px;
              background: $sectiontab-title-color;
              position: absolute;
              margin: auto;
              top: 0px;
              bottom: 0px;
              right: 15px;
              transition: all .15s linear;
              transform: rotate(90deg);
              -webkit-transform: rotate(90deg);
              -moz-transform: rotate(90deg);
              -ms-transform: rotate(90deg);
              -o-transform: rotate(90deg);
            }
          }
        }

        &.active {
          .title {
            background: $transparent;
            text-align: center;
            margin-top: 15px;

            a {
              color: $sectiontab-active-title-color;
              background: $sectiontab-active-title-bg;
              padding-right: 8px;
              border: none;
              border-bottom: dashed 1px $sectiontab-active-title-color;

              &:hover, &:active {
                background: $sectiontab-active-title-bg-over;
                color: $sectiontab-active-title-color;
              }
              &:after, &:before {
                background: $sectiontab-active-title-color;
              }
              &:before {
                transform: translateX(-29%) rotate(45deg);
                -webkit-transform: translateX(-29%) rotate(45deg);
                -moz-transform: translateX(-29%) rotate(45deg);
                -ms-transform: translateX(-29%) rotate(45deg);
                -o-transform: translateX(-29%) rotate(45deg);
              }

              &:after {
                transform: translateX(37%) rotate(-45deg);
                -webkit-transform: translateX(37%) rotate(-45deg);
                -moz-transform: translateX(37%) rotate(-45deg);
                -o-transform: translateX(37%) rotate(-45deg);
                -ms-transform: translateX(37%) rotate(-45deg);
              }
            }
          }

          &:first-child {
            .title {
              border-top: solid 1px $sectiontab-active-title-color;
            }
          }
        }
      }
    }
  }
}
