//=============================================
//    SIDEBAR
//    Style and Variables
//    See _vrweb-settings for global sidebar variables.
//=============================================
aside.sidebar-second,
aside.sidebar-first {

  @media #{$small-down} {
    @include min(both);
  }

  *:not(option):not(select) {
    color: $sidebar-text-color;
    @include placeholder{
      color:$white !important;
    }
    @media #{$small} {
      color: $sidebar-text-color-desktop;
      @include placeholder{
        color:$white !important;
      }
    }
  }

  input, select {
    color: $white;
    background-color:transparent;
    border: solid 1px rgba($white, .5);
    font-size:14px;
    background-image:url('../images/form-icon-sidebar-select.svg');
  }

  a:hover,
  a:active {
    color: $sidebar-link-color-hover;
  }

  h4.block-title {
    background: $sidebar-block-title-bg;
    color: $sidebar-block-title-color;
    margin-bottom: 0;
    padding: 24px 0px;
    margin-top: 0px;
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
    font-family: $head-gf;
    font-weight: 700;
    letter-spacing: .08em;

    @media #{$small}{
      @if $page-header-image == false {
        border-top-right-radius: $global-radius;
        border-top-left-radius: $global-radius;
      }
      background: $sidebar-block-title-bg-desktop;
      color:$sidebar-block-title-color-desktop;
    }
  }

  section {
    background: $sidebar-bg;
    border-radius: $global-radius;

    @media #{$small} {
      background: $sidebar-bg-desktop;
      @include box-shadow();
    }
  }

  ul.menu, .item-list ul {
    width: 90%;
    list-style: none;
    margin-left: 8px;
    padding-bottom: 10px;

    @media #{$small-down}{
      margin-bottom: 0px;
      padding-bottom: 10px;
    }

    li {
      margin-left: 0px;
      line-height: 1.2;
      padding-bottom: 8px;
      padding-left: 12px;
      border-bottom: none !important;
      &:first-child {
        margin-top:7px;
      }

      a {
        display: block;
        text-transform: uppercase;
        font-weight:300;
        letter-spacing:1px;

        @media #{$small-down}{
          display: inline-block;
          padding-right: 8px;
        }

        &:hover, &:active {
          text-decoration: underline;
        }

        @media #{$small} {
          border-bottom: solid 1px rgba($white, .5);
          padding-bottom: 5px;
          transition: all .15s ease-out;

          &:hover,
          &:active {
            text-decoration: none;
            border-bottom-color: $sidebar-link-color-hover;
          }
        }
      }
      &:last-child a {
        border-bottom: none !important;
      }
      .views-field-title {
        font-weight: 600;
        display: block;
        line-height: 1.4;
      }
      .views-field-field-event-date {
        > span {
          padding-top: 2px;
          font-weight: 300;
        }
      }
    }
  }

  .block-views-events-block-recent {
    .item-list ul li a {
      @media #{$small} {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
}

body:not(.map-results-page) {
  aside.sidebar-first {
    @media #{$small} {
      @if $page-header-image == false {
        margin-top: -50px;
      }
      &.active {
        position: fixed;
        //top:0px;
        margin-top: 0px !important;
        right: auto;
        max-width: 305px;
      }
    }
  }
}
