// This init file is a base partial that you can use to bootstrap the theme.
// The theme will have compilation errors if not imported.
//
// This file is forked version of the foundation.scss file:
// https://github.com/zurb/foundation/blob/master/scss/foundation.scss
// Global Zurb Foundation variables.
@import "variables";
// rezFusion Foundation overrides.
@import "../vrweb_settings";
// Comment out this import if you don't want to use normalize.
@import "normalize";
// rezFusion Foundation overrides.
@import "../riottags";
// This includes *all* Foundation styles and we don't necessarily want them.
//@import "../foundation";
// Include only the Foundation styles we actually want.
@import "../foundation/components/global";
@import "../foundation/components/grid";
@import "../foundation/components/visibility";
@import "../foundation/components/block-grid";
@import "../foundation/components/type";
@import "../foundation/components/buttons";
@import "../foundation/components/forms";
@import "../foundation/components/custom-forms";
@import "../foundation/components/button-groups";
@import "../foundation/components/dropdown-buttons";
@import "../foundation/components/split-buttons";
@import "../foundation/components/flex-video";
@import "../foundation/components/section";
@import "../foundation/components/top-bar";
//@import "../foundation/components/orbit";
@import "../foundation/components/reveal";
//@import "../foundation/components/joyride";
@import "../foundation/components/clearing";
@import "../foundation/components/alert-boxes";
@import "../foundation/components/breadcrumbs";
@import "../foundation/components/keystrokes";
@import "../foundation/components/labels";
@import "../foundation/components/inline-lists";
@import "../foundation/components/pagination";
@import "../foundation/components/panels";
//@import "../foundation/components/pricing-tables";
//@import "../foundation/components/progress-bars";
@import "../foundation/components/side-nav";
//@import "../foundation/components/sub-nav";
@import "../foundation/components/switch";
//@import "../foundation/components/magellan";
@import "../foundation/components/tables";
@import "../foundation/components/thumbs";
@import "../foundation/components/tooltips";
//@import "../foundation/components/dropdown";


