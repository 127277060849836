.tiktok {
  a {
      font: 0/0 sans-serif;

      &::before {
          content: " ";
          font-family: "social-icons";
          font-style: normal;
          font-weight: 400;
          font-variant: normal;
          text-transform: none;
          display: inline-block;
          font-size: 35px;
          color: #ffffff;
          text-decoration: none;
          background-image: url(../images/icon-tiktok.svg);
          background-repeat: no-repeat;
          width: 41px;
          height: 41px;
          background-size: 30px;
          background-position: center 4px;
          margin-bottom: -5px;
          border-radius: 100px;
          background-color: transparent;

          @media (max-width: emCalc(767)) {
              width: 38px;
              height: 38px;
              background-size: 36px;
              margin-bottom: -11px;
              margin-left: 1px;
          }
      }

      &:hover::before {
          background-color: #fd3e3e;
          transition: background-color 200ms ease-in-out;
          border-radius: 100px;
      }
  }
}
