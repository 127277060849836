.block-bt-testimonials {
  background-color: $muted-color;
  padding: 60px 20px 40px;

  #wallop-slideshow {
    max-width: 700px;
    margin: 0 auto;
    background-color: #fff;
    text-align: center;
    padding: 50px 40px 100px;
    box-shadow: 0px 3px 20px #0000000D;
  }

  .Wallop:hover .Wallop-control {
    opacity: .75;
    border: 0;
  }

  .Wallop-buttonPrevious {
    left: 55px;
  }

  .Wallop-buttonNext {
    right: 55px;
  }

  h4.block-title {
    font-family: $header-font-family;
    color: $h2-font-color;
    font-weight: $h2-font-weight;
    font-size: $h2-font-size;
    line-height: $h2-line-height;
    text-rendering: $h2-text-rendering;
    letter-spacing: .09em;
    font-style: italic;
    text-transform: none;
  }

  .field-name-field-byline {
    @include teaser-titles;
  }
}
