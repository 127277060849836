$app-pages: (page-riottags-vrw-search);

%styles {
  max-width: none;
  width: 100%;
}

@each $page in $app-pages {
  .#{$page} .l-main {
    @extend %styles;
  }
}
